import { clearUserForTracking, setUserForTracking } from 'tracking';
import { base64UrlToUnicode } from '@libs/encoding';
import { Subscription } from '@libs/subscription';

/*
    ____,-------------------------------,____
    \   |            Version            |   /
    /___|-------------------------------|___\
*/

const CURRENT_VERSION = 4 as const;

/*
    ____,-------------------------------,____
    \   |             Types             |   /
    /___|-------------------------------|___\
*/

interface Tokens {
  access: string;
  expAccess: number;
  user: AuthServiceUser;

  ver: typeof CURRENT_VERSION;
}

interface JWTToken {
  id: string; // userId
  exp: number;
}

interface User {
  userId: string;
  firstName: string;
  lastName: string;
  clubs: Array<{ clubId: string; role: 'Manager' | 'Member' }>;
}

export interface ClubRoles {
  isManager: boolean;
  isMember: boolean;
}

/*
    ____,-------------------------------,____
    \   |             Service           |   /
    /___|-------------------------------|___\
*/

// see: https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
const MAX_POSSIBLE_TIMEOUT = 2147483647;

export class AuthService {
  #tokens?: Tokens;
  #subscription = new Subscription();
  #initialized = false;
  #timeout: null | ReturnType<typeof setTimeout> = null;

  private constructor() {
    const data = localStorage.getItem('auth');
    if (data) {
      try {
        const obj = JSON.parse(data) as Tokens;
        if (obj.ver === CURRENT_VERSION) {
          this.#tokens = obj;
          this.#startAutoLogout();
          // tracking
          this.#trackUser();
        }
      } catch (e) {
        console.error(e);
      }

      if (!this.#tokens) {
        this.deleteTokens();
      }
    }

    this.#initialized = true;
  }

  get accessToken(): string | undefined {
    return this.#tokens?.access;
  }

  get user(): AuthServiceUser | undefined {
    return this.#tokens?.user;
  }

  get isAuthenticated(): boolean {
    return Boolean(this.#tokens?.access && this.#tokens.expAccess > Date.now());
  }

  saveTokens(access: string, user: User): void {
    const expAccess = this.#parseJWT(access).exp * 1000;
    this.#tokens = {
      access,
      expAccess,
      user: new AuthServiceUser(user),
      ver: CURRENT_VERSION,
    };
    localStorage.setItem('auth', JSON.stringify(this.#tokens));

    this.#clearAutoLogout();
    this.#startAutoLogout();
    this.#publish();
    // tracking
    this.#trackUser();
  }

  deleteTokens(): void {
    this.#tokens = undefined;
    localStorage.removeItem('auth');

    this.#clearAutoLogout();
    this.#publish();
    // tracking
    this.#untrackUser();
  }

  subscribe(cb: () => void): () => void {
    return this.#subscription.subscribe(cb);
  }

  #parseJWT(token: string): JWTToken {
    const payload = token.split('.')[1]!;
    const parsed = JSON.parse(base64UrlToUnicode(payload));
    return parsed;
  }

  #startAutoLogout() {
    if (this.#tokens && this.isAuthenticated) {
      this.#timeout = setTimeout(
        () => {
          if (!this.#tokens) return;

          // Обрабатываем ситуацию, когда время жизни токена больше ~25 дней
          // (максимальное значение для setTimeout).
          const remaining = this.#tokens.expAccess - Date.now();
          //
          if (remaining > 1000 /* 1 sec */) {
            // время ещё не пришло — перезапускаем механизм авто-разлогина
            this.#clearAutoLogout();
            this.#startAutoLogout();
            return;
          }

          this.#timeout = null;
          this.deleteTokens();
        },
        Math.min(this.#tokens.expAccess - Date.now(), MAX_POSSIBLE_TIMEOUT),
      );
    }
  }

  #clearAutoLogout() {
    if (this.#timeout) {
      clearTimeout(this.#timeout);
      this.#timeout = null;
    }
  }

  #publish() {
    if (this.#initialized) this.#subscription.publish(null);
  }

  // Tracking

  #trackUser() {
    const user = this.user;
    if (user) {
      setUserForTracking(user.userId, `${user.lastName} ${user.firstName}`);
    }
  }

  #untrackUser() {
    clearUserForTracking();
  }

  // Statics

  static #instance: AuthService;

  static get instance(): AuthService {
    if (!this.#instance) {
      this.#instance = new AuthService();
    }

    return this.#instance;
  }
}

/*
    ____,-------------------------------,____
    \   |            Helpers            |   /
    /___|-------------------------------|___\
*/

class AuthServiceUser {
  userId: string;
  firstName: string;
  lastName: string;
  clubs: Record<string, ClubRoles> = {};

  constructor({ userId, firstName, lastName, clubs }: User) {
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;

    clubs.reduce((sink, c) => {
      if (!sink[c.clubId]) {
        sink[c.clubId] = { isManager: false, isMember: false };
      }
      const r = sink[c.clubId]!;
      switch (c.role) {
        case 'Member':
          r.isMember = true;
          break;
        case 'Manager':
          r.isManager = true;
          break;
      }

      return sink;
    }, this.clubs);
  }
}
