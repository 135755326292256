const BASE_URL = __RUNTIME.streamApi;

/*
    ____,-------------------------------,____
    \   |         Socket Clients        |   /
    /___|-------------------------------|___\
*/

// ------------------------------------------
// Game public stream

// Совместим с GameAPI
export const GAME_STREAM_SOCKET_URL = `${BASE_URL}/hubs/stream/game`;

// ------------------------------------------
// Tournament public stream

// Совместим с GameAPI
export const TOURNAMENT_STREAM_SOCKET_URL = `${BASE_URL}/hubs/stream/tournament`;
