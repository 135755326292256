export const RU = {
  //

  'features/game/ui/GameButtons/Are you sure': 'Вы уверены?',
  'features/game/ui/GameButtons/Confirm': 'Подтвердить',
  'features/game/ui/GameButtons/Cancel match': 'Отменить партию',
  'features/game/ui/GameButtons/Draw': 'Ничья',
  'features/game/ui/GameButtons/Resign': 'Сдаться',
  'features/game/ui/GameButtons/Leave (to match)': 'Вернуться в матч',
  'features/game/ui/GameButtons/Leave (to tournament)': 'Вернуться в турнир',
  'features/game/ui/GameButtons/Leave (default)': 'Вернуться',
  'features/game/ui/GameButtons/Notation': 'Нотация',

  //

  'features/game/pages/GamePage/QuickGame': 'Быстрая партия',
  'features/game/pages/GamePage/Round No': 'Тур',
  'features/game/pages/GamePage/Time Control Blitz': 'Блиц',
  'features/game/pages/GamePage/Time Control Rapid': 'Рапид',
  'features/game/pages/GamePage/Time Control Classic': 'Классические',
  'features/game/pages/GamePage/Error Header': 'Ошибка',
  'features/game/pages/GamePage/Error Text':
    'При выполнении запроса что-то пошло не так. Повторите попытку позже',

  //

  'features/game/ui/MatchCountdown/Start in': 'До начала партии',

  //

  'features/game/ui/NetworkProblems/Header': 'Потеряна связь с сервером',
  'features/game/ui/NetworkProblems/Text': 'Подключаемся снова',

  //

  'features/game/pages/GamePage/DrawOffer/Text': 'Соперник предлагает ничью',
  'features/game/pages/GamePage/DrawOffer/Decline': 'Отклонить',
  'features/game/pages/GamePage/DrawOffer/Accept': 'Принять',

  //

  'features/game/pages/GamePage/DrawOffer/PlayerPanelAlt/Win': 'Победа',

  //

  'features/game/hooks/useScoresheet/Draw': 'Ничья',
  'features/game/hooks/useScoresheet/White Win': 'Победа белых',
  'features/game/hooks/useScoresheet/Black Win': 'Победа черных',
  'features/game/hooks/useScoresheet/White offer draw':
    'Белые предлагают ничью',
  'features/game/hooks/useScoresheet/Black offer draw':
    'Черные предлагают ничью',

  //

  'features/user/ui/UserSettingsMenu/Auto-promotion ON': 'Включить автоферзя',
  'features/user/ui/UserSettingsMenu/Auto-promotion OFF': 'Выключить автоферзя',
  'features/user/ui/UserSettingsMenu/Dark theme ON': 'Включить темную тему',
  'features/user/ui/UserSettingsMenu/Dark theme OFF': 'Выключить темную тему',

  //

  'features/user/ui/User/Log in': 'Войти',
  'features/user/ui/User/Log out': 'Выйти',
};
