import { useState } from 'react';
import { useAuth } from '@features/auth/hooks/useAuth';
import { useClubMembersQuery } from '@features/club/api/hooks';
import { ClubCardParticipants } from './ClubCardParticipants';

type Sort = 'RatingClassic' | 'Alphabet';

type Props = { clubId: string; className?: string };

export function ClubCardParticipantsWidget({ className, clubId }: Props) {
  const { user } = useAuth();
  const [tab, setTab] = useState<Sort>('RatingClassic');

  const { data, isPending } = useClubMembersQuery(clubId, {
    sortBy: tab,
    status: 'Active',
  });

  return (
    <ClubCardParticipants
      className={className}
      tab={tab}
      setTab={setTab}
      members={data}
      isPending={isPending}
      highlightedUserId={user?.userId}
    />
  );
}
