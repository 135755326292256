import { TimeSyncService } from '@features/shared/services/TimeSyncService';
import { useCountdownUntill } from '@libs/hooks/useCountdownUntill';
import { components } from '../../api';
import { MatchCountdown } from './MatchCountdown';

export function MatchCountdownWidget({
  game,
}: {
  game: components['schemas']['GameDto'];
}) {
  const { seconds } = useCountdownUntill(
    TimeSyncService.instance.toClientTimestamp(game.startTimestampMs),
  );

  if (!seconds) return null;

  return <MatchCountdown seconds={seconds} />;
}
