import { ReactNode } from 'react';
import { components } from '@features/game/api/generated';
import {
  ButtonDraw,
  ButtonLeave,
  ButtonNotation,
  ButtonResign,
} from '@features/game/ui/GameButtons/GameButtons';
import { TableTimer } from '@features/game/ui/TableTimer';
import { cx } from '@libs/classnames';
import { AnimatedNode } from '@ui/components/Animation';
import { Toast } from '@ui/components/Toast';
import styles from './TableFooter.module.css';

type IPlayer = {
  id: string;
  drawRequestsLeft: number;
};

type IGame = {
  id: string;
  white: IPlayer;
  black: IPlayer;
  boardNumber: number | null;
  clock: {
    timestampMs: number;
    blackLeftMs: number;
    whiteLeftMs: number;
  };
  nextMoveBy: 'Black' | 'White';
  status: components['schemas']['GameStatus'];
  moves: unknown[];
  activeDrawRequest?: unknown;
};

export type Props = {
  className?: string;
  game: IGame;
  seancerUserId: string;
  timePressureSec: number;
  fractionsFromSec: number;
  notationIsOpen?: boolean;
  submittingDraw?: boolean;
  submittingResign?: boolean;
  customPlayersTimeMs?: { white?: number; black?: number };
  error?: ReactNode;
  onDraw?: () => void;
  onResign?: () => void;
  onLeave?: () => void;
  onNotation?: () => void;
  onClearError?: () => void;
};

export function TableFooter({
  className,
  game,
  seancerUserId,
  timePressureSec,
  fractionsFromSec,
  notationIsOpen,
  submittingDraw,
  submittingResign,
  customPlayersTimeMs,
  error,
  onDraw,
  onResign,
  onLeave,
  onNotation,
  onClearError,
}: Props) {
  const player = [game.white, game.black].find((p) => p.id === seancerUserId);
  const isNotStarted = game.status === 'NotStarted';
  const isInProgress = game.status === 'InProgress';
  const isFinished = game.status === 'Finished';
  const hasFullTurnHappened = game.moves.length >= 2;
  const hasActiveOffer = !!game.activeDrawRequest;
  const hasOffersLeft = !!player?.drawRequestsLeft;

  const isWhite = game.white.id === seancerUserId;
  const isWhiteMove = game.nextMoveBy === 'White';
  const { timestampMs, blackLeftMs, whiteLeftMs } = game.clock;

  const customTimeMs = customPlayersTimeMs?.[isWhite ? 'white' : 'black'];

  return (
    <div className={cx(styles.table_footer, className)}>
      <div className={styles.buttons}>
        {player && isInProgress && (
          <ButtonDraw
            disabled={
              isNotStarted ||
              !hasFullTurnHappened ||
              hasActiveOffer ||
              !hasOffersLeft ||
              submittingDraw
            }
            onClick={onDraw}
          />
        )}
        {player && isInProgress && (
          <ButtonResign
            disabled={isNotStarted || !hasFullTurnHappened || submittingResign}
            onClick={onResign}
          />
        )}
        {(!player || isNotStarted || isFinished) && (
          <ButtonLeave system="Seance" onClick={onLeave} />
        )}
        <ButtonNotation active={notationIsOpen} onClick={onNotation} />
      </div>
      <div className={styles.timer}>
        <TableTimer
          leftMs={customTimeMs || (isWhite ? whiteLeftMs : blackLeftMs)}
          timestampMs={timestampMs}
          paused={
            customTimeMs === undefined && isInProgress
              ? isWhite
                ? !isWhiteMove
                : isWhiteMove
              : true
          }
          highlight="normal"
          timePressureSec={timePressureSec}
          fractionsFromSec={fractionsFromSec}
          preset="seancer-big"
          timeSync
        />
      </div>
      <AnimatedNode className={styles.error} animation="bounce-up">
        {error && (
          <Toast
            className={styles.error__toast}
            type="error"
            onClose={onClearError}
          >
            {error}
          </Toast>
        )}
      </AnimatedNode>
    </div>
  );
}

export function TableFooterSkeleton({ className }: { className?: string }) {
  return (
    <div className={cx(styles.table_footer, className)}>
      <div className={styles.buttons}>
        <div className={styles.skeleton_button}></div>
        <div className={styles.skeleton_button}></div>
      </div>
      <div className={styles.timer}>
        <div className={styles.skeleton_timer}></div>
      </div>
    </div>
  );
}
