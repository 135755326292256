import {
  ClockControlSettings,
  ClockControlType,
  OneOnOneSettings,
  TournamentMode,
  TournamentSystem,
} from '@features/shared/api/typings';
import { cx } from '@libs/classnames';
import { pluralize, PluralWordForms } from '@libs/i18n';
import styles from './TournamentSystemView.module.css';

const systems: { [k in TournamentSystem]: string } = {
  RoundRobin: 'Круговая\u00A0система',
  Swiss: 'Швейцарская\u00A0система',
  Knockout: 'Нокаут',
  OneOnOne: 'Матч',
  Seance: 'Одновременная игра',
};

const timeControls: { [k in ClockControlType]: string } = {
  Blitz: 'Блиц',
  Rapid: 'Рапид',
  Classic: 'Классические\u00A0шахматы',
};

const gamesPlural: PluralWordForms = {
  one: '{number} партия',
  few: '{number} партии',
  many: '{number} партий',
};

export type Props = {
  className?: string;
  tournament?: {
    system: TournamentSystem;
    clockControl?: ClockControlType;
    mode?: TournamentMode;
    clockControlSettingsPlayer1?: ClockControlSettings;
    clockControlSettingsPlayer2?: ClockControlSettings;
    oneOnOneSettings?: OneOnOneSettings;
  };
};

export function TournamentSystemView({ className, tournament }: Props) {
  if (!tournament) return null;

  const system = systems[tournament.system];

  const isMatch =
    tournament.system === 'OneOnOne' || tournament.system === 'Seance';

  let time;
  const player1 = tournament.clockControlSettingsPlayer1;
  const player2 = tournament.clockControlSettingsPlayer2;

  if (tournament.clockControl) {
    time = ' · ' + timeControls[tournament.clockControl];
    if (
      player1?.initialSeconds !== undefined &&
      player1?.addSecondsPerMove !== undefined
    ) {
      time += `,\u00A0${Math.floor(player1.initialSeconds / 60)}\u00A0+\u00A0${player1.addSecondsPerMove}`;
    }

    if (
      isMatch &&
      tournament.mode === 'Private' &&
      player2 &&
      (player1?.initialSeconds !== player2.initialSeconds ||
        player1?.addSecondsPerMove !== player2?.addSecondsPerMove)
    ) {
      time += `,\u00A0${Math.floor(player2.initialSeconds / 60)}\u00A0+\u00A0${player2.addSecondsPerMove}`;
    }
  }

  let gamesCount;
  if (isMatch && tournament.oneOnOneSettings) {
    gamesCount =
      ' · ' + pluralize(gamesPlural, tournament.oneOnOneSettings.gamesCount);
  }

  return (
    <span className={cx(className, styles.root)}>
      {system}
      {gamesCount}
      {time}
    </span>
  );
}
