import { components } from '@features/shared/api/club/generated';

export type DateString = Date | string;

export type ParticipantStatus =
  | 'Invited'
  | 'Participating'
  | 'Exited'
  | 'Banned'
  | 'Deleted';

export type TournamentStatus =
  | 'Raw'
  | 'Draft'
  | 'NotStarted'
  | 'Paused'
  | 'InProgress'
  | 'Finished'
  | 'Deleted';

export type TournamentEntrance = 'Open' | 'Rank' | 'Invite';
export type TournamentMode = 'Private' | 'Team' | 'PrivateTeam';
export type ClockControlType = 'Blitz' | 'Rapid' | 'Classic';
export const ClockControlTypes: ClockControlType[] = [
  'Blitz',
  'Rapid',
  'Classic',
];
export type ClockControlSettings =
  components['schemas']['ClockControlSettings'];
export type TournamentAdditionalScore =
  | 'Berger'
  | 'PartBerger'
  | 'Match'
  | 'Black'
  | 'ScoreSum';

export type TournamentSystem =
  | 'RoundRobin'
  | 'Swiss'
  | 'Knockout'
  | 'OneOnOne'
  | 'Seance';

export type GameSide = 'Black' | 'White' | 'Both' | 'Unknown';

export type GameParticipantsResult =
  | 'Win'
  | 'Loss'
  | 'Draw'
  | 'InProgress'
  | 'UnPlayed'
  | 'Skipped'
  | 'Mixed';

export type ResultsTableAdditionalScore = {
  scoreType: TournamentAdditionalScore;
  score: number | null;
};

export type ResultsGame = {
  gameId?: string | null;
  score?: number | null;
  resultType?: GameParticipantsResult;
  side?: GameSide;
};
export type ResultsTableParticipant = {
  no: number;
  title: string;
  games: ResultsGame[];
  additionalScores?: ResultsTableAdditionalScore[];
  totalScore?: number | null;
  place?: string;
  subRows?: ResultsTableParticipant[] | null;
};
export type ResultsTableRound = {
  num: number;
  members: ResultsTableParticipant[];
  gamesCount: number;
  additionalScoreTypes?: TournamentAdditionalScore[];
};
export type ResultsTableData = {
  rounds: ResultsTableRound[];
};

export type GameMovementRow = {
  boardNo?: number | null;
  player1: GameMovementPlayer;
  player2: GameMovementPlayer;
};
export type GameMovementPlayer = {
  userId: string;
  lastName: string;
  firstName: string;
  title?: string | null;
  game: ResultsGame;
};

export type ParticipantDto = components['schemas']['ParticipantDto'];

export type MemberTournamentParticipantDto =
  components['schemas']['MemberTournamentParticipantDto'];

export type TournamentTeam = {
  teamId: string;
  name: string;
  tournamentId: string;
  clubId: string;
};

export type TournamentRound = {
  roundId: string;
  roundNumber: number;
  cycleNumber: number | null;
  status: 'New' | 'Drafted' | 'InProgress' | 'Finished' | 'Deleted';
  startTimestampMs?: number | null;
};

export type Contact = {
  name: string;
  label: string;
  uri: string;
};
export type ClubModeratorDto = {
  moderatorId: string;
  email: string;
};
export type ClubAdminDto = {
  fullName: string;
  phoneNumber: string;
  email: string;
};

export type TournamentShort = {
  tournamentId: string;
  status: TournamentStatus;
  title: string;
  startTimestampMs: number;
  promoted: boolean;
  entrance: TournamentEntrance;
  minEntranceElo?: number | null;
  clockControl: ClockControlType;
  clockControlSettings: ClockControlSettings;
  mode: TournamentMode;
  system: TournamentSystem;
  participationStatus?: ParticipantStatus;
};

export type Tournament = {
  clubId: string;
  tournamentId: string;
  status: TournamentStatus;
  title: string;
  logoUrl?: string | null;
  startTimestampMs?: number | null;
  promoted: boolean;
  entrance: TournamentEntrance;
  minEntranceElo?: number | null;
  contacts: Contact[];
  pausedTillTimestampMs?: number | null;
  rulesUrl?: string | null;
  rcfRankCalculated: boolean;
  mode: TournamentMode;
  clockControl: ClockControlType;
  clockControlSettingsPlayer1: ClockControlSettings;
  clockControlSettingsPlayer2: ClockControlSettings;
  additionalScoreOrder: TournamentAdditionalScore[];
  armageddonEnabled: boolean;
  armageddonBlackLimitMinutes?: number | null;
  armageddonWhiteLimitMinutes?: number | null;
  system: TournamentSystem;
  teams?: TournamentTeam[];
  teamModeSettings?: {
    size: number;
  };
  rounds: TournamentRound[];
  participantsCount: number;
  delayBetweenRoundsSeconds: number;
  roundRobinSettings?: RoundRobinSettings;
  oneOnOneSettings?: OneOnOneSettings;
  knockOutSettings?: KnockOutSettings;
  swissSettings?: SwissSettings;
  seanceSettings?: SeanceSettingsDto;
};

export type RoundRobinSettings = {
  roundsCount: number;
};
export type SwissSettings = {
  roundsCount: number;
};
export type KnockOutSettings = {
  gamesPerRound: number;
  gamesPerFinalRound: number;
  tieSettings: {
    additionalGamesCount: number;
    additionalGamesFinalCount: number;
    clockControl: ClockControlType;
    clockControlSettingsPlayer1: ClockControlSettings;
    clockControlSettingsPlayer2: ClockControlSettings;
  };
};
export type OneOnOneSettings = components['schemas']['OneOnOneSettings'];
export type SeanceSettingsDto = components['schemas']['SeanceSettingsDto'];

export type ClubDto = {
  clubId: string;
  title: string;
  description: string;
  membersCount: number;
  activeTournamentsCount: number;
  finishedTournamentsCount: number;
  administrator: ClubAdminDto;
  telegramChannel: string; // always starts with @ or always without it (last - preferable)
  logoUrl: string; // full absolute url to club logo
  themeColor: string; // hex-color from picker
  createdTimestampMs: Date; // datestring or timestamp
  moderators: ClubModeratorDto[];
};
export type ClubGroup = {
  id: number;
  title: string;
  rcfId?: string;
  participantIds: number[];
};

export type MemberDto = components['schemas']['MemberDto'];

export type MemberStatus = components['schemas']['MemberStatus'];

export type UserEloDto = {
  blitz: EloDto;
  rapid: EloDto;
  classic: EloDto;
};

export type EloDto = {
  clockControl: ClockControlType;
  gamesCount: number;
  rating: number;
  lastChange: number;
};

export type UpdateTournamentRequest = {
  title: string;
  startTimestampMs: number;
  promoted: boolean;
  entrance: TournamentEntrance;
  minEntranceElo?: number | null;
  contacts: Contact[];
  rcfRankCalculated: boolean;
  mode: TournamentMode;
  clockControl: ClockControlType;
  clockControlSettingsPlayer1: ClockControlSettings;
  clockControlSettingsPlayer2: ClockControlSettings;
  additionalScoreOrder: TournamentAdditionalScore[];
  armageddonEnabled: boolean;
  armageddonBlackLimitMinutes: number;
  armageddonWhiteLimitMinutes: number;
  system: TournamentSystem;
  knockOutSettings?: KnockOutSettings;
  swissSettings?: SwissSettings;
  roundRobinSettings?: RoundRobinSettings;
  seanceSettings?: SeanceSettingsDto;
  teamModeSettings?: {
    size: number;
  };
  oneOnOneSettings?: OneOnOneSettings;
};

// KnockoutResults

export type KnockoutResultsPlayer = {
  userId: string;
  lastName: string;
  firstName: string;
  games: ResultsGame[];
  total: {
    score: string;
    resultType: GameParticipantsResult;
  };
};

export type KnockoutResultsMatch = {
  player1?: KnockoutResultsPlayer;
  player2?: KnockoutResultsPlayer;
};

export type KnockoutResultsRound = {
  roundId: string;
  roundName: string;
  roundType: 'WinnerBranch' | 'LooserBranch' | 'Final';
  matches: KnockoutResultsMatch[];
};

export type KnockoutResults = {
  rounds: KnockoutResultsRound[];
};
