import { ChessBoardSkeleton } from 'exports/ChessBoard';
import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import { Loader } from '@ui/components/Loader';
import styles from './SeancePage.module.css';
import { SeanceQueueSkeleton } from './components/SeanceQueue/SeanceQueue';
import { SeanceResults } from './components/SeanceResults/SeanceResults';
import { TableFooterSkeleton } from './components/TableFooter/TableFooter';
import { TableHeaderSkeleton } from './components/TableHeader/TableHeader';

export type Props = {
  avatar?: ReactNode;
  header: ReactNode;
  board: ReactNode;
  footer: ReactNode;
  showScoresheet?: boolean;
  scoresheet: ReactNode;
  queue: ReactNode;
  tables: ReactNode;
  results?: { seancer: number; opponents: number };
};

/* FIXME:

Важное:
- [ ] Модалка конфликта игр
*/

export function SeancePage({
  avatar,
  header,
  board,
  footer,
  showScoresheet = false,
  scoresheet,
  queue,
  tables,
  results,
}: Props) {
  return (
    <div className={styles.layout}>
      {avatar && <div className={styles.avatar}>{avatar}</div>}
      <section
        className={cx(styles.top_container, {
          [styles.with_scoresheet]: showScoresheet,
        })}
      >
        <div className={styles.table}>
          <div className={styles.table__header}>{header}</div>
          <div className={styles.table__board}>{board}</div>
          <div className={styles.table__footer}>{footer}</div>
        </div>
        {showScoresheet && (
          <div className={styles.scoresheet}>{scoresheet}</div>
        )}
        <div className={styles.queue}>
          {!results && <h2 className={styles.queue__title}>Ваш ход</h2>}
          {queue ?? (
            <EmptySeanceQueue
              className={styles.queue__empty}
              text={
                results ? (
                  <SeanceResults {...results} />
                ) : (
                  'Ожидаем готовности соперников'
                )
              }
            />
          )}
        </div>
      </section>
      <div className={styles.all_tables}>
        <h2 className={styles.all_tables__title}>Все партии</h2>
        {tables}
      </div>
    </div>
  );
}

export function SeancePageSkeleton({ avatar }: { avatar?: ReactNode }) {
  return (
    <div className={styles.layout}>
      {avatar && <div className={styles.avatar}>{avatar}</div>}
      <section className={styles.top_container}>
        <div className={styles.table}>
          <div className={styles.table__header}>
            <TableHeaderSkeleton />
          </div>
          <div className={styles.table__board}>
            <ChessBoardSkeleton>
              <Loader preset="desktop" ignoreClubColors />
            </ChessBoardSkeleton>
          </div>
          <div className={styles.table__footer}>
            <TableFooterSkeleton />
          </div>
        </div>
        <div className={styles.queue}>
          <h2 className={styles.queue__title}>Ваш ход</h2>
          <div className={styles.preview_board__skeleton}>
            <SeanceQueueSkeleton count={3} />
          </div>
        </div>
      </section>
    </div>
  );
}

function EmptySeanceQueue({
  className,
  text,
}: {
  text: ReactNode;
  className?: string;
}) {
  return (
    <div className={cx(styles.empty_queue, className)}>
      <div>{text}</div>
    </div>
  );
}
