import { useCallback, useState } from 'react';
import { cx } from '@libs/classnames';
import { FUNC_PREVENT_DEFAULT } from '@libs/constants';
import { Card } from '@ui/components/Card';
import { Popover } from '@ui/components/Popover';
import { BurgerIcon } from '@ui/icons/BurgerIcon';
import styles from './MoveParticipantButton.module.css';

type ITeam = {
  teamId: string;
  name: string;
  disabled: boolean;
};

export type Props = {
  className?: string;
  teams?: ITeam[];
  currentTeamId?: string;
  disableMenu?: boolean;
  onSelectTeam: (teamId?: string) => void;
};

export function MoveParticipantButton({
  className,
  teams,
  currentTeamId,
  disableMenu,
  onSelectTeam,
}: Props) {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const showPopover = useCallback(() => {
    if (disableMenu) return;
    setPopoverVisible(true);
  }, [disableMenu]);
  const hidePopover = useCallback(() => {
    setPopoverVisible(false);
  }, []);
  const handleSelectTeam = (teamId: string) => {
    setPopoverVisible(false);
    onSelectTeam(teamId);
  };

  return (
    <div className={styles.moveButtonComponent}>
      <div
        className={cx(styles.burgerButton, className)}
        onPointerUp={showPopover}
        onContextMenu={FUNC_PREVENT_DEFAULT}
      >
        <BurgerIcon />
      </div>

      <Popover
        // FIXME: отвязать попап от кнопки (через портал), чтобы иметь плавные анимации скрытия
        // даже при перемещении кнопки в новое место
        key={currentTeamId}
        className={styles.movePopover}
        wrapperClassName={styles.movePopoverWrapper}
        onHide={hidePopover}
        active={popoverVisible}
      >
        <Card className={styles.content}>
          <div className={styles.title}>Переместить в команду</div>

          <div className={styles.teams}>
            <TeamRow
              key="no-team"
              id=""
              name="Без команды"
              onClick={handleSelectTeam}
              disabled={!currentTeamId}
            />

            {teams?.map((team) => (
              <TeamRow
                key={team.teamId}
                id={team.teamId}
                name={team.name}
                onClick={handleSelectTeam}
                disabled={team.teamId === currentTeamId || team.disabled}
              />
            ))}

            {!teams || (!teams.length && 'Нет команд')}
          </div>

          <hr className={styles.hr} />

          <div className={styles.cancel} onClick={hidePopover}>
            Отмена
          </div>
        </Card>
      </Popover>
    </div>
  );
}

type TeamRowProps = {
  id: string;
  name: string;
  onClick: (teamId: string) => void;
  disabled?: boolean;
};
function TeamRow({ id, name, onClick, disabled }: TeamRowProps) {
  const handleClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  return (
    <div
      className={cx(styles.teamRow, { [styles.disabled]: disabled })}
      onClick={handleClick}
    >
      {name}
    </div>
  );
}
