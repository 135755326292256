import { ClubInfo } from 'features/club/ui/ClubInfo';
import { TournamentSubHeader } from 'features/shared/ui/TournamentSubHeader';
import { ReactNode } from 'react';
import { components } from '@features/shared/api/club/generated';
import { BookmarkButton } from '@ui/components/BookmarkButton';
import { BookmarkButtonBar } from '@ui/components/BookmarkButtonBar';
import { HollowButton } from '@ui/components/HollowButton';
import { Loader } from '@ui/components/Loader';
import { CreateIcon } from '@ui/icons/CreateIcon';
import { EditIcon } from '@ui/icons/EditIcon';
import styles from './ClubCardPage.module.css';

export type Props = {
  club?: {
    clubId: string;
    title: string;
    description: string;
    logoUrl?: string;
    memberStatus: components['schemas']['MemberStatus'];
    membersCount?: number;
    finishedTournamentsCount?: number;
    createdTimestampMs: Date | number | string;
    administrator?: {
      fullName: string;
      phoneNumber: string;
      email: string;
    };
    telegramChannel?: string;
    themeColor?: string;
  };
  isPending: boolean;
  onClubJoin: () => void;
  isJoinPending: boolean;
  isManager: boolean;
  members?: (clasName: string) => ReactNode;
  events?: (clasName: string) => ReactNode;
  onSettings: () => void;
  onTournamentCreate: () => void;
  onMatchCreate: () => void;
};
export function ClubCardPage({
  club,
  isPending,
  onClubJoin,
  isJoinPending,
  isManager,
  members,
  events,
  onTournamentCreate,
  onMatchCreate,
  onSettings,
}: Props) {
  let clubParticipationButton;
  if (club && !club.memberStatus) {
    clubParticipationButton = (
      <HollowButton onClick={onClubJoin} disabled={isJoinPending}>
        Присоединиться к клубу
      </HollowButton>
    );
  } else if (club?.memberStatus === 'JoinRequest') {
    clubParticipationButton = (
      <HollowButton empty disabled>
        Заявка в обработке
      </HollowButton>
    );
  } else if (club?.memberStatus === 'JoinRequestRejected') {
    clubParticipationButton = (
      <HollowButton empty disabled>
        Заявка отклонена
      </HollowButton>
    );
  } else if (club?.memberStatus === 'Deleted') {
    clubParticipationButton = (
      <HollowButton empty disabled>
        Доступ отозван
      </HollowButton>
    );
  }

  return (
    <>
      {isPending && <Loader centered />}

      {!isPending && (
        <>
          <TournamentSubHeader
            title={club?.title}
            description={club?.description}
            logo={<img src={club?.logoUrl || undefined} />}
            info={
              <ClubInfo
                club={club}
                clubParticipationButton={clubParticipationButton}
              />
            }
          />

          {events?.(styles.events)}

          {members?.(styles.participants)}
        </>
      )}

      {isManager && (
        <BookmarkButtonBar>
          <BookmarkButton
            icon={<EditIcon />}
            label="Настройки"
            onClick={onSettings}
          />
          <BookmarkButton
            icon={<CreateIcon />}
            label="Создать"
            clickedStateTop={
              <div
                className={styles.bookmarkSubButton}
                onClick={onTournamentCreate}
              >
                Турнир
              </div>
            }
            clickedStateBottom={
              <div className={styles.bookmarkSubButton} onClick={onMatchCreate}>
                Матч
              </div>
            }
          />
        </BookmarkButtonBar>
      )}
    </>
  );
}
