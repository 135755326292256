import { format } from 'date-fns';
import { isToday } from 'date-fns/isToday';
import { components } from '@features/shared/api/club/generated';
import { TimeSyncService } from '@features/shared/services/TimeSyncService';
import { useCountdownUntill } from '@libs/hooks/useCountdownUntill';
import { Badge } from '@ui/components/Badge';
import { DateView } from '@ui/components/DateView';
import { useMediaQuery } from '@ui/components/MediaQueryProvider';
import { Timer } from '@ui/components/Timer';
import styles from './TournamentStatusView.module.css';

/*
    ____,-------------------------------,____
    \   |          Компонент            |   /
    /___|-------------------------------|___\
*/

type Round = {
  roundNumber: number;
  cycleNumber: number | null;
  status: components['schemas']['RoundStatus'];
  startTimestampMs?: number | null;
};

export type Props = {
  className?: string;
  tournament?: {
    startTimestampMs?: number | null;
    status: components['schemas']['TournamentStatus'];
    pausedTillTimestampMs?: number | null;
    rounds: Round[];
  };
};

export function TournamentStatusView({ className, tournament }: Props) {
  const time = useTournamentCountdown(tournament);
  const { roundActive, roundNext, roundTime } = useRounds(tournament);
  const isMobile = useMediaQuery() === 'mobile';

  if (!tournament) return null;

  const { status, pausedTillTimestampMs: pausedUntil } = tournament;

  const startTime = tournament?.startTimestampMs || 0;
  const date = startTime ? (
    <>
      <DateView date={startTime} format="dd MMMM" />,{' '}
      <DateView date={startTime} format="HH:mm" />
    </>
  ) : null;
  const labelNow = 'Идет сейчас';

  return (
    <Badge size={isMobile ? 'h25' : 'l'} className={className}>
      {(() => {
        switch (status) {
          case 'Draft':
            return (
              <>
                Черновик{date ? ' · ' : ''}
                {date}
              </>
            );
          case 'NotStarted':
            if (isToday(startTime)) {
              return time ? (
                <Timer className={styles.timer} showHours seconds={time} />
              ) : (
                'Скоро начнется'
              );
            }
            return date;
          case 'InProgress':
            if (roundActive) {
              return (
                <>
                  {labelNow} · {roundActive.roundNumber} тур
                </>
              );
            }

            if (roundNext) {
              if (roundTime) {
                return (
                  <>
                    {labelNow} · {roundNext.roundNumber} тур через{' '}
                    <Timer className={styles.timer} seconds={roundTime} />
                  </>
                );
              }

              return (
                <>
                  {labelNow} · Ожидание {roundNext.roundNumber} тура{' '}
                </>
              );
            }

            return labelNow;
          case 'Paused':
            return (
              <>
                Приостановлен
                {pausedUntil && ` до ${format(pausedUntil, 'HH:mm')}`}
              </>
            );
          case 'Finished':
            return (
              <>
                Завершен · <DateView format="dd MMMM yyyy" date={startTime} />
              </>
            );
          case 'Deleted':
            return <>Удален · {date}</>;
          default:
            return date;
        }
      })()}
    </Badge>
  );
}

/*
    ____,-------------------------------,____
    \   |           Запчасти            |   /
    /___|-------------------------------|___\
*/

function useTournamentCountdown(t: Props['tournament']) {
  const stop = t?.status !== 'Draft' && t?.status !== 'NotStarted';
  const timestamp =
    TimeSyncService.instance.toClientTimestamp(t?.startTimestampMs) ?? 0;
  const { seconds: time } = useCountdownUntill(timestamp, stop);
  return time;
}

function useRounds(t: Props['tournament']) {
  const rounds = t?.rounds || [];
  const roundActive = rounds.find((r: Round) => r.status === 'InProgress');
  const roundNext = rounds.find(
    (r: Round) => r.status === 'New' || r.status === 'Drafted',
  );

  const timestamp =
    TimeSyncService.instance.toClientTimestamp(roundNext?.startTimestampMs) ??
    0;

  const { seconds: roundTime } = useCountdownUntill(
    timestamp,
    Boolean(roundActive || !roundNext?.startTimestampMs),
  );

  return {
    roundActive,
    roundNext,
    roundTime,
  };
}
