import { useUserSocket } from '@features/shared/api';
import { useTournamentGamesSocket } from '@features/shared/api/streams';
import {
  useTournamentCardQuery,
  useTournamentResultsQuery,
} from '@features/tournament/api/hooks';
import { useGoToGame } from '@features/tournament/hooks/useGoToGame';
import { getPresentRounds } from '@features/tournament/utils/tournamentHelpers';
import { ResultsTable } from './ResultsTable';

type Props = { clubId: string; tournamentId: string };

export function ResultsTableWidget({ clubId, tournamentId }: Props) {
  const tournamentQUery = useTournamentCardQuery(clubId, tournamentId, {
    staleTime: Infinity,
  });
  const tournament = tournamentQUery.data;

  const resultsQuery = useTournamentResultsQuery(
    clubId,
    tournamentId,
    tournament &&
      ['InProgress', 'Paused', 'Finished'].includes(tournament.status),
  );

  let results;
  const system = tournament?.system;
  const data = resultsQuery.data;
  if (system === 'RoundRobin') results = data?.roundRobin?.results;
  if (system === 'OneOnOne') results = data?.oneOnOne?.results;

  // Отслеживание изменений игр
  const presentRounds = getPresentRounds(tournament?.rounds);
  const lastRound = presentRounds?.[presentRounds.length - 1];
  useTournamentGamesSocket({
    tournamentId,
    roundNumber: lastRound?.roundNumber ?? NaN,
    enabled: lastRound?.status === 'InProgress',
    cb: (e) => {
      if (e.eventType === 'GameChanged') {
        const result = e.currentState.result;
        if (
          result === 'Draw' ||
          result === 'BlackWin' ||
          result === 'WhiteWin'
        ) {
          resultsQuery.refetch();
        }
      }
    },
  });

  // Отслеживание изменений турнира
  // Места становятся известны только после завершения матча, а не после
  // завершения последнего раунда
  // Также догружаем доп. раунды при драфте и финише.
  useUserSocket((e) => {
    switch (e.eventType) {
      case 'TournamentFinished':
      case 'RoundFinished':
      case 'RoundStarted':
      case 'RoundDrafted':
        if (e.tournamentId === tournamentId) {
          resultsQuery.refetch();
        }
        break;
    }
  });

  const goToGame = useGoToGame(clubId, tournamentId);

  if (!results || !tournament) return null;

  return (
    <ResultsTable
      rows={results}
      rounds={tournament.rounds}
      system={tournament.system}
      eventFinished={tournament.status === 'Finished'}
      additionalScoresOrder={tournament.additionalScoreOrder}
      onGameClick={goToGame}
    />
  );
}
