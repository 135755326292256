import { cx } from '@libs/classnames';
import styles from './SeanceResults.module.css';

type Props = {
  className?: string;
  seancer: number;
  opponents: number;
};

export function SeanceResults({ className, seancer, opponents }: Props) {
  return (
    <div className={cx(styles.results, className)}>
      <div className={styles.title}>Результат</div>
      <div className={styles.data}>
        <span className={styles.data__seancer}>{seancer}</span>
        <span>×</span>
        <span>{opponents}</span>
      </div>
    </div>
  );
}
