import { useCallback } from 'react';
import { z } from 'zod';
import { ClubCardPage } from '@features/club/pages/ClubCardPage';
import { useClubCard } from '@features/club/pages/ClubCardPage/useClubCard';
import { ClubCardParticipantsWidget } from '@features/club/ui/ClubCardParticipants';
import { ClubEventListWidget } from '@features/club/ui/ClubEventList';
import { TournamentLayoutWidget } from '@features/shared/ui/TournamentLayout/TournamentLayoutWidget';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

const querySchema = z.object({
  eventsTab: z.enum(['upcoming', 'finished']).catch('upcoming').optional(),
});
type Query = z.infer<typeof querySchema>;

export const Route = createFileRoute('/_authenticated/club/$clubId')({
  component: Page,
  validateSearch: querySchema,
});

function Page() {
  const { clubId } = Route.useParams();
  const { eventsTab = 'upcoming' } = Route.useSearch();
  const navigate = useNavigate({ from: Route.fullPath });
  const onEventsTabChange = useCallback(
    (eventsTab: Query['eventsTab']) => navigate({ search: { eventsTab } }),
    [navigate],
  );
  const props = useClubCard({ clubId });
  return (
    <TournamentLayoutWidget clubId={clubId}>
      <ClubCardPage
        {...props}
        events={(className) => (
          <ClubEventListWidget
            tab={eventsTab}
            onTabChange={onEventsTabChange}
            className={className}
            clubId={clubId}
          />
        )}
        members={(className) => (
          <ClubCardParticipantsWidget className={className} clubId={clubId} />
        )}
      />
    </TournamentLayoutWidget>
  );
}
