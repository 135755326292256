import { useCallback, useRef, useState } from 'react';
import {
  useAcceptDrawMutation,
  useDeclineDrawMutation,
  useOfferDrawMutation,
} from '@features/game/api';
import { components } from '@features/game/api/generated';

export function useDrawOffer({
  playerId,
  game: _game,
  onError: _onError,
}: {
  playerId?: string;
  game?: components['schemas']['GameDto'];
  onError?: (err: Error) => void;
}) {
  const offerDrawMutation = useOfferDrawMutation();
  const acceptDrawMutation = useAcceptDrawMutation();
  const declineDrawMutation = useDeclineDrawMutation();
  //
  const [_registry, setRegistry] = useState<Record<string, unknown>>({});
  //
  const side =
    _game && playerId && _game?.black.id === playerId ? 'Black' : 'White';
  const showDrawOffer = Boolean(
    side &&
      _game &&
      _game.activeDrawRequest &&
      _game.activeDrawRequest.triggeredBySide !== side &&
      _registry[_game.id] !== _game.activeDrawRequest,
  );

  const _store = {
    game: _game,
    registry: _registry,
    offer: offerDrawMutation,
    accept: acceptDrawMutation,
    decline: declineDrawMutation,
    onError: _onError,
  };
  const refStore = useRef(_store);
  refStore.current = _store;

  // ---

  const onDrawOffer = useCallback(() => {
    const { game, offer, onError } = refStore.current;
    offer.mutate(game!.id, { onError });
  }, []);
  const onDrawAccept = useCallback(() => {
    const { registry, game, accept, onError } = refStore.current;
    setRegistry({ [game!.id]: game!.activeDrawRequest, ...registry });
    accept.mutate(game!.id, { onError });
  }, []);
  const onDrawDecline = useCallback(() => {
    const { registry, game, decline, onError } = refStore.current;
    setRegistry({ [game!.id]: game!.activeDrawRequest, ...registry });
    decline.mutate(game!.id, { onError });
  }, []);

  // --

  return {
    showDrawOffer,
    onDrawOffer,
    onDrawAccept,
    onDrawDecline,
    submittingDrawOffer: offerDrawMutation.isPending,
    submittingDrawAccept: acceptDrawMutation.isPending,
    submittingDrawDecline: declineDrawMutation.isPending,
  };
}
