import { RU } from './ru';

export const FR: typeof RU = {
  //

  'features/game/ui/GameButtons/Are you sure': 'Êtes-vous sûr?',
  'features/game/ui/GameButtons/Confirm': 'Confirmer',
  'features/game/ui/GameButtons/Cancel match': 'Annuler la partie',
  'features/game/ui/GameButtons/Draw': 'Nulle',
  'features/game/ui/GameButtons/Resign': 'Abandonner',
  'features/game/ui/GameButtons/Leave (to match)': 'Fermer partie',
  'features/game/ui/GameButtons/Leave (to tournament)': 'Fermer partie',
  'features/game/ui/GameButtons/Leave (default)': 'Fermer partie',
  'features/game/ui/GameButtons/Notation': 'Coups',

  //

  'features/game/pages/GamePage/QuickGame': 'Partie rapide',
  'features/game/pages/GamePage/Round No': 'Tour',
  'features/game/pages/GamePage/Time Control Blitz': 'Blitz',
  'features/game/pages/GamePage/Time Control Rapid': 'Rapide',
  'features/game/pages/GamePage/Time Control Classic': 'Classique',
  'features/game/pages/GamePage/Error Header': 'Erreur',
  'features/game/pages/GamePage/Error Text':
    "Quelque chose s'est produit d'erreur lors de la traitement de votre demande. Veuillez réessayer plus tard",

  //

  'features/game/ui/MatchCountdown/Start in': 'Avant le début du jeu',

  //

  'features/game/ui/NetworkProblems/Header': 'Connexion perdue avec le serveur',
  'features/game/ui/NetworkProblems/Text': 'Nous allons nous reconnecter',

  //

  'features/game/pages/GamePage/DrawOffer/Text':
    'Votre adversaire a refusé la nulle',
  'features/game/pages/GamePage/DrawOffer/Decline': 'Refuser',
  'features/game/pages/GamePage/DrawOffer/Accept': 'Accepter',

  //

  'features/game/pages/GamePage/DrawOffer/PlayerPanelAlt/Win': 'Victoire',

  //

  'features/game/hooks/useScoresheet/Draw': 'Nulle',
  'features/game/hooks/useScoresheet/White Win': 'Les Blancs gagnent',
  'features/game/hooks/useScoresheet/Black Win': 'Les Noirs gagnent',
  'features/game/hooks/useScoresheet/White offer draw':
    'Les Blancs propose la nulle',
  'features/game/hooks/useScoresheet/Black offer draw':
    'Les Noirs propose la nulle',

  //

  'features/user/ui/UserSettingsMenu/Auto-promotion ON':
    'Activer la promotion automatique de la dame',
  'features/user/ui/UserSettingsMenu/Auto-promotion OFF':
    'Désactiver la promotion automatique de la dame',
  'features/user/ui/UserSettingsMenu/Dark theme ON': 'Thème sombre',
  'features/user/ui/UserSettingsMenu/Dark theme OFF': 'Thème lumineux',

  //

  'features/user/ui/User/Log in': 'Connexion',
  'features/user/ui/User/Log out': 'Déconnexion',
};
