import { forwardRef } from 'react';
import { useTournamentCardQuery } from '@features/tournament/api/hooks';
import { TournamentParticipantsWidget } from '@features/tournament/ui/components/TournamentParticipants';
import { TournamentResultsWidget } from '@features/tournament/ui/components/TournamentResultsWidget/TournamentResultsWidget';
import { isTournamentStarted } from '@features/tournament/utils/tournamentHelpers';

type Props = {
  clubId: string;
  tournamentId: string;
  resultsTab: 'table' | 'rounds';
  onResultsTabChange: (resultsTab: 'table' | 'rounds') => void;
};

type WidgetRef = { refetch: () => void };

/**
 * Над-виджет.
 * Определяет, надо ли показывать результаты турнира или просто табличку участников.
 */
export const TournamentParticipantsMegaWidget = forwardRef<WidgetRef, Props>(
  function TournamentParticipantsMegaWidget(
    { clubId, tournamentId, resultsTab, onResultsTabChange },
    ref,
  ) {
    const { data: tournament, isPending: isTournamentPending } =
      useTournamentCardQuery(clubId, tournamentId, {
        staleTime: Infinity,
      });

    const tournamentStarted =
      tournament && isTournamentStarted(tournament.status);
    const isTournamentStartTimeReached =
      tournament?.startTimestampMs &&
      new Date() >= new Date(tournament.startTimestampMs);

    const showResults =
      (tournamentStarted && isTournamentStartTimeReached) || false;

    if (isTournamentPending) return null;

    if (showResults) {
      return (
        <TournamentResultsWidget
          clubId={clubId}
          tournamentId={tournamentId}
          resultsTab={resultsTab}
          onResultsTabChange={onResultsTabChange}
        />
      );
    }

    return (
      <TournamentParticipantsWidget
        ref={ref}
        clubId={clubId}
        tournamentId={tournamentId}
      />
    );
  },
);
