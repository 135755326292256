import React from 'react';
import { RU } from './ru';

export type Lang = 'ru' | 'en' | 'fr';
export type LangResources = typeof RU;

export interface ITranslateContext {
  lang: Lang;
  t: (k: keyof LangResources) => string;
  change: (lang: Lang) => void;
}

const DEFAULT: ITranslateContext = {
  lang: 'ru',
  t: () => '',
  change: () => {},
};

export const TranslateContext = React.createContext<ITranslateContext>(DEFAULT);
