import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import { Button } from '@ui/components/Button';
import { GoldenModal } from '@ui/components/GoldenModal/GoldenModal';
import styles from './UnsavedChangesModal.module.css';

type Props = {
  className?: string;
  showSave?: boolean;
  hideProceed?: boolean;
  pending?: boolean;
  children?: ReactNode;
  labelProceed?: string;
  labelSay?: string;
  onSave?: () => void;
  onProceed?: () => void;
  onStay?: () => void;
};

export function UnsavedChangesModal({
  className,
  showSave,
  hideProceed,
  pending,
  children,
  labelProceed = 'Покинуть',
  labelSay = 'Остаться',
  onSave,
  onProceed,
  onStay,
}: Props) {
  return (
    <GoldenModal className={cx(styles.modal, className)}>
      {children ?? (
        <>
          <h1>Несохраненные изменения</h1>
          <p>
            Изменения будут потеряны. Вы уверены, что хотите покинуть страницу?
          </p>
        </>
      )}
      <div className={styles.buttons}>
        {showSave && (
          <Button onClick={onSave} disabled={pending}>
            Сохранить
          </Button>
        )}
        {!hideProceed && <Button onClick={onProceed}>{labelProceed}</Button>}
        <Button onClick={onStay}>{labelSay}</Button>
      </div>
    </GoldenModal>
  );
}
