import { Scoresheet } from 'exports/Scoresheet';
import { MappedProps } from '@features/game/hooks/useScoresheet';
import { cx } from '@libs/classnames';
import styles from './TableScoresheet.module.css';

export type Props = {
  className?: string;
  props: MappedProps;
};

export function TableScoresheet({ className, props }: Props) {
  return <Scoresheet className={cx(styles.sheet, className)} {...props} />;
}
