import { useRef } from 'react';
import { TimeSyncService } from '@features/shared/services/TimeSyncService';
import { cx } from '@libs/classnames';
import { useCountdownUntill } from '@libs/hooks/useCountdownUntill';
import { formatSeconds } from '@libs/time';
import { AnimatedNode } from '@ui/components/Animation';
import styles from './TableTimer.module.css';

export function TableTimer({
  className,
  leftMs,
  timestampMs,
  paused,
  timePressureSec,
  fractionsFromSec,
  highlight = 'none',
  preset,
  timeSync,
}: {
  className?: string;
  leftMs: number;
  timestampMs: number;
  paused?: boolean;
  timePressureSec: number;
  fractionsFromSec?: number;
  highlight?: 'none' | 'normal' | 'inverted';
  preset: 'big' | 'small' | 'seancer-big' | 'seancer-small';
  timeSync: boolean;
}) {
  const ts = timeSync
    ? TimeSyncService.instance.toClientTimestamp(timestampMs)
    : timestampMs;
  const until = paused ? Date.now() + leftMs : ts + leftMs;
  const { seconds, remainingMs } = useCountdownUntill(until, paused, (ms) => {
    if (!showFractions || !refFraction.current) return;
    refFraction.current.innerText = formatFraction(ms);
  });

  const refFraction = useRef<HTMLSpanElement>(null);
  const showFractions = fractionsFromSec && seconds <= fractionsFromSec;

  return (
    <div
      translate="no"
      className={cx(styles.timer, className, {
        [styles.is_small]: preset === 'small' || preset === 'seancer-small',
        [styles.is_seancer]:
          preset === 'seancer-big' || preset === 'seancer-small',
        [styles.is_time_pressure]: seconds <= timePressureSec,
        [styles.is_active]:
          (highlight === 'normal' || highlight === 'inverted') && !paused,
        [styles.is_inverted]: highlight === 'inverted',
      })}
    >
      {formatSeconds({ time: seconds })}
      <AnimatedNode className={styles.fraction} tag="span">
        {showFractions && (
          <>
            ,<i ref={refFraction}>{formatFraction(remainingMs)}</i>
          </>
        )}
      </AnimatedNode>
    </div>
  );
}

function formatFraction(frac: number) {
  return Math.floor((frac % 1000) / 100).toString();
}
