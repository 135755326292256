import { Layout } from '@ui/components/Layout';

export function BrowserNotSupportedPage() {
  return (
    <Layout>
      <h1 style={{ margin: '20px', textAlign: 'center' }}>
        Извините, но Ваш браузер не поддерживается.
      </h1>
    </Layout>
  );
}
