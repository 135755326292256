import { SQUARE_WIDTH } from '@libs/chess/constants';
import { SquareName } from '@libs/chess/types';

const MARGIN_LEFT = 2;
const MARGIN_RIGHT = 100 - MARGIN_LEFT - 3 * SQUARE_WIDTH;

export function getPromotionPosition(
  orientation: 'white' | 'black',
  at: SquareName,
) {
  const file = at[0]!;
  const rank = at[1]!;
  const fileLayout = orientation === 'white' ? 'abcdefgh' : 'hgfedcba';
  const width = Math.min(
    Math.max(SQUARE_WIDTH * (fileLayout.indexOf(file) - 1), MARGIN_LEFT),
    MARGIN_RIGHT,
  );
  const isTop =
    (orientation === 'white' && rank === '8') ||
    (orientation === 'black' && rank === '1');

  return {
    [isTop ? 'top' : 'bottom']: `${SQUARE_WIDTH}%`,
    left: at ? `${width}%` : 0,
    width: `${3 * SQUARE_WIDTH}%`,
    height: `${2 * SQUARE_WIDTH}%`,
  };
}
