import { RoundSocketEvents } from '@features/game/api/clients';
import { useRoundSocket } from '@features/game/api/hooks';
import { SocketInternalEvents } from '@libs/socket';
import { TOURNAMENT_STREAM_SOCKET_URL } from './clients';

/*
    ____,-------------------------------,____
    \   |            Sockets            |   /
    /___|-------------------------------|___\
*/

// ------------------------------------------
// Tournament Round Games

export function useTournamentGamesSocket({
  tournamentId,
  roundNumber,
  cb,
  enabled = true,
}: {
  tournamentId: string;
  roundNumber: number;
  cb: (e: RoundSocketEvents | SocketInternalEvents) => void;
  enabled?: boolean;
}) {
  return useRoundSocket({
    tournamentId,
    roundNumber,
    cb,
    customSocketUrl: TOURNAMENT_STREAM_SOCKET_URL,
    enabled,
  });
}
