import { ChessBoard, ChessBoardSkeleton } from 'exports/ChessBoard';
import { memo } from 'react';
import { components } from '@features/game/api/generated';
import { TableTimer } from '@features/game/ui/TableTimer';
import { SquareName } from '@libs/chess/types';
import { cx } from '@libs/classnames';
import { GameScoreCirce } from '@ui/components/GameScoreCircle/GameScoreCircle';
import styles from './TablePreview.module.css';

type IPlayer = {
  id: string;
  firstName: string;
  lastName: string;
};
type IGame = {
  id: string;
  white: IPlayer;
  black: IPlayer;
  currentFen: string;
  boardNumber: number | null;
  clock: {
    timestampMs: number;
    blackLeftMs: number;
    whiteLeftMs: number;
  };
  nextMoveBy: 'Black' | 'White';
  moves: Array<{ from: string; to: string }>;
  result: components['schemas']['GameResult'];
};

export type Props = {
  className?: string;
  selected?: boolean;
  orientation: 'black' | 'white';
  game: IGame;
  timePressureOpponentSec: number;
  timePressureSeancerSec: number;
  onClick?: (gameId: string) => void;
};

export const TablePreview = memo(function TablePreview({
  className,
  selected,
  orientation,
  timePressureOpponentSec,
  timePressureSeancerSec,
  game,
  onClick,
}: Props) {
  const fen = game.currentFen;
  const opponentColor = orientation === 'black' ? 'white' : 'black';
  const opponent = game[opponentColor];
  const opponentName = `${opponent.firstName} ${opponent.lastName}`;
  const opponentAbbrevName = formatName(opponent.firstName, opponent.lastName);
  const isOpponentMove = game.nextMoveBy.toLocaleLowerCase() !== orientation;

  const isInProgress = game.result === 'InProgress';
  const isDraw = game.result === 'Draw';
  const isWhiteWin = game.result === 'WhiteWin';
  const isBlackWIn = game.result === 'BlackWin';

  const winner = isDraw
    ? 'draw'
    : isWhiteWin
      ? 'white'
      : isBlackWIn
        ? 'black'
        : undefined;
  const opponentResult = !winner
    ? undefined
    : isDraw
      ? 'draw'
      : winner === opponentColor
        ? 'win'
        : 'defeat';
  const seancerResult = !winner
    ? undefined
    : isDraw
      ? 'draw'
      : winner === orientation
        ? 'win'
        : 'defeat';

  const boardNumber = game.boardNumber;
  const { timestampMs, blackLeftMs, whiteLeftMs } = game.clock;

  const lastMove = game.moves[game.moves.length - 1];
  const from = lastMove?.from as SquareName | undefined;
  const to = lastMove?.to as SquareName | undefined;

  return (
    <article
      className={cx(styles.preview, className, {
        [styles.is_selected]: selected,
        [styles.is_pointer]: onClick,
      })}
      onClick={() => onClick?.(game.id)}
    >
      <div className={styles.header}>
        <div className={styles.header__name} title={opponentName}>
          {opponentAbbrevName}
        </div>
        <div className={styles.header__time}>
          {opponentResult ? (
            <GameScoreCirce
              className={styles.result_circle}
              result={opponentResult}
            />
          ) : (
            <TableTimer
              timestampMs={timestampMs}
              leftMs={orientation === 'black' ? whiteLeftMs : blackLeftMs}
              paused={!isInProgress || !isOpponentMove}
              highlight={selected ? 'inverted' : 'normal'}
              timePressureSec={timePressureOpponentSec}
              preset="small"
              timeSync
            />
          )}
        </div>
      </div>
      <div className={styles.board}>
        <ChessBoard
          orientation={orientation}
          position={fen}
          noLightSpeck
          notation="none"
          winner={winner}
          selectedFrom={from}
          selectedTo={to}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.footer__title}>Стол {boardNumber}</div>
        <div className={styles.footer__time}>
          {seancerResult ? (
            <GameScoreCirce
              className={styles.result_circle}
              result={seancerResult}
            />
          ) : (
            <TableTimer
              timestampMs={timestampMs}
              leftMs={orientation === 'white' ? whiteLeftMs : blackLeftMs}
              paused={!isInProgress || isOpponentMove}
              highlight="normal"
              timePressureSec={timePressureSeancerSec}
              preset="seancer-small"
              timeSync
            />
          )}
        </div>
      </div>
    </article>
  );
});

export function TablePreviewSkeleton() {
  return (
    <div className={styles.skeleton}>
      <p>
        <span className={styles.skeleton__text}>Имя и фамилия...</span>
        <span className={styles.skeleton__text}>... ... ...</span>
      </p>
      <div className={styles.skeleton__board}>
        <ChessBoardSkeleton />
      </div>
      <p>
        <span className={styles.skeleton__text}>Имя и фамилия...</span>
        <span className={styles.skeleton__text}>... ... ...</span>
      </p>
    </div>
  );
}

function formatName(firstName: string, lastName: string) {
  const abbrev =
    firstName && firstName.length ? `${firstName[0]?.toUpperCase()}.` : '';
  return `${abbrev} ${lastName}`;
}
