import { ReactNode, useRef } from 'react';
import { TableTimer } from '@features/game/ui/TableTimer';
import { cx } from '@libs/classnames';
import { AnimatedNode } from '@ui/components/Animation';
import { BoardColorCircle } from '@ui/components/BoardColorCircle';
import { GameScoreCirce } from '@ui/components/GameScoreCircle/GameScoreCircle';
import styles from './PlayerPanel.module.css';

/*
    ____,-------------------------------,____
    \   |          Компонент            |   /
    /___|-------------------------------|___\
*/

export type Props = {
  orientation?: 'top' | 'bottom';
  mobile?: boolean;
  //-
  name: string;
  color: 'black' | 'white';
  //-
  leftMs: number;
  timestampMs: number;
  paused?: boolean;
  timePressureSec: number;
  fractionsFromSec?: number;
  //-
  networkProblem?: boolean;
  //-
  rating?: number;
  ratingGain?: number;
  ratingUpdate?: number | null;
  //-
  result?: 'win' | 'defeat' | 'draw';
};

export function PlayerPanel({
  orientation = 'top',
  mobile,
  name,
  color,
  leftMs,
  timestampMs,
  paused,
  timePressureSec,
  fractionsFromSec,
  networkProblem,
  rating,
  ratingGain,
  ratingUpdate,
  result,
}: Props) {
  const isWin = result === 'win';

  return (
    <section
      className={cx({
        [styles.player]: true,
        [styles.mobile]: mobile,
        [styles.isMuted]: networkProblem,
      })}
    >
      {!mobile && orientation === 'top' && (
        <TopOrBottomHint isWin={isWin} gain={ratingGain} />
      )}
      <div className={styles.mainRow}>
        <div className={styles.timeCol}>
          <div className={styles.timeWrapper}>
            {/* FIXME: */}
            <TableTimer
              className={cx(styles.time, {
                [styles.isTransparent]: networkProblem,
                [styles.isGolden]: isWin,
              })}
              preset="big"
              leftMs={leftMs}
              timePressureSec={timePressureSec}
              timestampMs={timestampMs}
              fractionsFromSec={fractionsFromSec}
              paused={paused}
              timeSync
            />
            {mobile && (
              <AnimatedNode className={styles.gainMobile}>
                {ratingGain && !networkProblem && formatDelta(ratingGain)}
              </AnimatedNode>
            )}
          </div>
          <div
            aria-hidden={!networkProblem}
            className={cx({
              [styles.network]: true,
              [styles.isHidden]: !networkProblem,
            })}
          >
            Проблемы с сетью
          </div>
        </div>
        <div className={styles.nameCol}>
          <p
            className={cx({ [styles.name]: true, [styles.isGolden]: isWin })}
            title={name}
          >
            <BoardColorCircle color={color} />
            {name}
          </p>
          {rating && (
            <div
              className={cx({
                [styles.rating]: true,
                [styles.isGolden]: isWin,
              })}
            >
              {rating}{' '}
              <span
                className={cx({
                  [styles.update]: true,
                  [styles.isGolden]: isWin,
                  [styles.isTransparent]: !ratingUpdate,
                })}
              >
                <Memory>
                  {ratingUpdate !== undefined && formatDelta(ratingUpdate)}
                </Memory>
              </span>
            </div>
          )}
        </div>
        <div
          className={cx({
            [styles.scoreCol]: true,
            [styles.isHidden]: !result,
          })}
        >
          <Memory>
            {result && <GameScoreCirce result={result} ignoreClubColors />}
          </Memory>
        </div>
      </div>
      {!mobile && orientation === 'bottom' && (
        <TopOrBottomHint isWin={isWin} gain={ratingGain} />
      )}
    </section>
  );
}

/*
    ____,-------------------------------,____
    \   |           Запчасти            |   /
    /___|-------------------------------|___\
*/

function TopOrBottomHint({ isWin, gain }: { isWin: boolean; gain?: number }) {
  const data = isWin ? 'Победа' : gain ? formatDelta(gain) : undefined;
  return (
    <div
      aria-hidden={!data}
      className={cx({
        [styles.statusRow]: true,
        [styles.isGolden]: isWin,
        [styles.isTransparent]: !data,
      })}
    >
      <Memory>{data}</Memory>&nbsp;
    </div>
  );
}

type MemoryProps = {
  children: ReactNode;
};

function Memory({ children }: MemoryProps) {
  const hasChildren = children || children === 0;
  const prevValue = useRef(children);
  if (hasChildren) {
    prevValue.current = children;
  }
  return hasChildren ? children : prevValue.current;
}

/*
    ____,-------------------------------,____
    \   |            Утилиты            |   /
    /___|-------------------------------|___\
*/

function formatDelta(d: number | null): string | null {
  if (!d) return null;
  return d < 0 ? d.toString() : `+${d}`;
}
