import { Controller, useFormContext } from 'react-hook-form';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Tabs } from '@ui/components/Tabs';

const rcfRankTabs = [
  { value: true, label: 'С обсчетом' },
  { value: false, label: 'Без обсчета' },
];

export function RcfRankInput() {
  const { control } = useFormContext<TournamentEditFormValues>();
  return (
    <FormRow>
      <FormLabel>Рейтинг ФШР</FormLabel>
      <FormInput>
        <Controller
          name="rcfRankCalculated"
          control={control}
          render={({ field: { value, onChange, disabled } }) => (
            <Tabs
              tabs={rcfRankTabs}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />
      </FormInput>
    </FormRow>
  );
}
