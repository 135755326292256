import { Controller, useFormContext } from 'react-hook-form';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Input } from '@ui/components/Input';

export function RivalCountInput() {
  const { control } = useFormContext<TournamentEditFormValues>();
  return (
    <FormRow>
      <FormLabel>Количество соперников</FormLabel>
      <FormInput>
        <Controller
          name="seanceSettings.maxPlayers"
          control={control}
          render={({ field: { value, onChange, disabled } }) => (
            <Input
              invisible
              type="number"
              value={value.toString()}
              onChange={onChange}
              min="2"
              max="99"
              disabled={disabled}
            />
          )}
        />
      </FormInput>
    </FormRow>
  );
}
