import { cx } from '@libs/classnames';
import { ArrowSlimIcon } from '@ui/icons/ArrowSlimIcon';
import styles from './SlimArrowButton.module.css';

export type Props = {
  className?: string;
  onClick?: () => void;
  direction: 'top' | 'right' | 'bottom' | 'left';
  disabled?: boolean;
};
export function SlimArrowButton({
  className,
  onClick,
  direction,
  disabled,
}: Props) {
  return (
    <span
      className={cx(className, styles.slimArrowButton, styles[direction], {
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <ArrowSlimIcon />
    </span>
  );
}
