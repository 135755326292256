import { useRef, useState, useEffect } from 'react';

export function useCountdownUntill(
  _dateMs: number,
  pauseUpdates = false,
  _onTick?: (ms: number) => void,
) {
  const remainingMs = Math.max(0, _dateMs - Date.now());
  const seconds = Math.floor(remainingMs / 1000);

  const state = { dateMs: _dateMs, seconds, cb: _onTick };
  const refState = useRef(state);
  refState.current = state;

  const [, update] = useState([]);

  useEffect(() => {
    const calc: FrameRequestCallback = () => {
      if (pauseUpdates) return;

      const { seconds, dateMs, cb } = refState.current;

      const nextRemaining = Math.max(0, dateMs - Date.now());
      const nextSec = Math.floor(nextRemaining / 1000);

      if (nextRemaining) {
        raf = requestAnimationFrame(calc);
      }

      cb?.(nextRemaining);

      if (nextSec !== seconds) {
        update([]);
      }
    };

    let raf = requestAnimationFrame(calc);

    return () => cancelAnimationFrame(raf);
  }, [pauseUpdates]);

  return { seconds, remainingMs };
}
