import { cx } from '@libs/classnames';
import styles from './BoardColorCircle.module.css';

export function BoardColorCircle({ color }: { color: 'white' | 'black' }) {
  return (
    <span
      className={cx(
        styles.color,
        color === 'white' ? styles.is_white : styles.is_black,
      )}
    />
  );
}
