import { InfoItem } from 'features/shared/ui/InfoItem';
import { ReactNode } from 'react';
import { components } from '@features/shared/api/club/generated';
import { TournamentSystem } from '@features/shared/api/typings';
import { cx } from '@libs/classnames';
import { ThemeLink } from '@ui/components/ThemeLink';
import styles from './TournamentInfo.module.css';

export type Props = {
  className?: string;
  tournament?: {
    status: components['schemas']['TournamentStatus'];
    system: TournamentSystem;
    participantsCount: number;
    rulesUrl?: string | null;
    contacts?: {
      name: string;
      label: string;
      uri: string;
    }[];
  };
  participationButton?: ReactNode;
  onDownloadXlsResults?: () => void;
};

export function TournamentInfo({
  className,
  tournament,
  participationButton,
  onDownloadXlsResults,
}: Props) {
  const isMatch =
    tournament?.system === 'OneOnOne' || tournament?.system === 'Seance';
  return (
    <div className={cx(className, styles.tournamentInfo)}>
      {!isMatch && (
        <InfoItem label="Участники" value={tournament?.participantsCount} />
      )}
      {tournament?.contacts?.map((c) => (
        <InfoItem
          key={c.name}
          label={c.name}
          value={
            <ThemeLink inNewTab href={c.uri}>
              {c.label || c.uri}
            </ThemeLink>
          }
        />
      ))}

      {tournament?.rulesUrl && (
        <InfoItem
          label="Документы"
          value={
            <ThemeLink inNewTab href={tournament?.rulesUrl || undefined}>
              Положение о {isMatch ? 'матче' : 'турнире'}
            </ThemeLink>
          }
        />
      )}

      {tournament?.status === 'Finished' &&
        !isMatch &&
        onDownloadXlsResults && (
          <InfoItem
            value={
              <ThemeLink onClick={onDownloadXlsResults}>
                Скачать результаты в XLS
              </ThemeLink>
            }
          />
        )}

      <div className={styles.buttons}>{participationButton}</div>
    </div>
  );
}
