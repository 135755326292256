import createClient from 'openapi-fetch';
import { TimeSyncService } from '@features/shared/services/TimeSyncService';
import { SignalRSocket } from '@libs/socket';
import type { paths, components } from './generated';

const BASE_URL = __RUNTIME.gameApi;
export const SESSION_GAME_ACCESS_KEY = 'game_access_key';

/*
____,-------------------------------,____
\   |          Http Client          |   /
/___|-------------------------------|___\
*/

export { components, paths };

function getAccessKey() {
  return sessionStorage.getItem(SESSION_GAME_ACCESS_KEY) ?? '';
}

const api = createClient<paths>({
  baseUrl: BASE_URL,
});

api.use({
  async onRequest({ request }) {
    // SEE: https://openapi-ts.dev/openapi-fetch/middleware-auth
    const accessKey = getAccessKey();
    request.headers.set('accessKey', accessKey);
    request.headers.set('Authorization', `Bearer ${accessKey}`);
    return request;
  },

  async onResponse({ response }) {
    if (response.status >= 400) {
      const body = response.headers.get('content-type')?.includes('json')
        ? await response.clone().json()
        : await response.clone().text();
      throw new Error(body);
    }
    return undefined;
  },
});

const { GET, POST } = api;
export { GET, POST };

/*
    ____,-------------------------------,____
    \   |         Socket Client         |   /
    /___|-------------------------------|___\
*/

// ------------------------------------------
// Game

const BASE_GAME_SOCKET_URL = `${BASE_URL}/hubs/game`;

export type GameSocketEvents =
  | components['schemas']['GameChangedEvent']
  | components['schemas']['DrawRequestEvent']
  | components['schemas']['DrawAcceptEvent']
  | components['schemas']['DrawDeclineEvent']
  | components['schemas']['SurrenderEvent'];

export class GameSocket extends SignalRSocket<GameSocketEvents> {
  constructor(
    public gameId: string,
    baseUrl: string = BASE_GAME_SOCKET_URL,
  ) {
    const EVENT_TYPES: Record<GameSocketEvents['eventType'], null> = {
      GameChanged: null,
      DrawRequest: null,
      DrawAccept: null,
      DrawDecline: null,
      Surrender: null,
    };

    super(`${baseUrl}?gameId=${gameId}`, Object.keys(EVENT_TYPES), (e) => {
      TimeSyncService.instance.reportSocketTs(e.timestampMs);
    });
  }
}

// ------------------------------------------
// Round

const BASE_ROUND_SOCKET_URL = `${BASE_URL}/hubs/tournament`;

export type RoundSocketEvents =
  | components['schemas']['RoundGamesState']
  | components['schemas']['GameChangedEvent']
  | components['schemas']['DrawRequestEvent']
  | components['schemas']['DrawAcceptEvent']
  | components['schemas']['DrawDeclineEvent']
  | components['schemas']['SurrenderEvent'];

export class RoundSocket extends SignalRSocket<RoundSocketEvents> {
  constructor(
    public tournamentId: string,
    public roundNumber: number,
    baseUrl: string = BASE_ROUND_SOCKET_URL,
  ) {
    const EVENT_TYPES: Record<RoundSocketEvents['eventType'], null> = {
      RoundGamesState: null,
      GameChanged: null,
      DrawRequest: null,
      DrawAccept: null,
      DrawDecline: null,
      Surrender: null,
    };

    super(
      `${baseUrl}?tournamentId=${tournamentId}&roundNumber=${roundNumber}`,
      Object.keys(EVENT_TYPES),
      (e) => {
        TimeSyncService.instance.reportSocketTs(e.timestampMs);
      },
    );
  }
}
