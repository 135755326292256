import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  KeyboardEvent,
} from 'react';
import { cx } from '@libs/classnames';
import styles from './Scoresheet.module.css';

export type Props<T extends Move = Move> = {
  className?: string;
  moves: T[];
  selected?: T;
  onNavigate: (nextSelected: T, prevSelected: T) => void;
  result?: string;
};

type Move = { san: string };

/*
    ____,-------------------------------,____
    \   |          Компонент            |   /
    /___|-------------------------------|___\
*/

const NO_MOVES = ['—'];
const typedMemo: <T>(c: T) => T = memo;

export const Scoresheet = typedMemo(function Scoresheet<T extends Move>({
  className,
  moves,
  selected,
  onNavigate,
  result,
}: Props<T>) {
  const fullmoves = useMemo(
    () => (moves.length ? toFullMoves(moves) : NO_MOVES),
    [moves],
  );

  // --- Cкролл к последнему полному ходу

  const { refScrollable } = useScrollToLatest(moves, result, selected);

  // --- Навигация

  const {
    disableBackward,
    disableForward,
    handleFastBackward,
    handleBackward,
    handleForward,
    handleFastForward,
    handleMove,
  } = useNavigate(selected, moves, onNavigate);

  // --- Навигация с клавиатуры

  const ref = useRef<HTMLElement>(null);
  const handleKeyboard = useCallback(
    (e: KeyboardEvent) => {
      const chores = () => {
        ref.current?.focus();
        e.preventDefault();
      };

      if (e.code === 'ArrowRight') {
        handleForward();
        chores();
      } else if (e.code === 'ArrowLeft') {
        handleBackward();
        chores();
      }
    },
    [handleBackward, handleForward],
  );

  // --- Подскролл к выделенному

  const { refSelected } = useFocusSelected(selected);

  // --- Отрисовка

  const last = moves[moves.length - 1];

  return (
    <section
      className={cx(styles.scoresheet, className)}
      onKeyDown={handleKeyboard}
      tabIndex={0}
      ref={ref}
    >
      <ul className={styles.controls}>
        <li>
          <button disabled={disableBackward} onClick={handleFastBackward}>
            <IconFastBack />
          </button>
        </li>
        <li>
          <button
            className={styles.red}
            disabled={disableBackward}
            onClick={handleBackward}
          >
            <IconBack />
          </button>
        </li>
        <li>
          <button disabled={disableForward} onClick={handleForward}>
            <IconBack className={styles.reverse} />
          </button>
        </li>
        <li>
          <button disabled={disableForward} onClick={handleFastForward}>
            <IconFastBack className={styles.reverse} />
          </button>
        </li>
      </ul>
      <div className={styles.scrollable} ref={refScrollable}>
        <table className={styles.sheet} cellPadding={0} cellSpacing={0}>
          <colgroup>
            <col width="14%" />
            <col width="43%" />
            <col width="43%" />
          </colgroup>
          <tbody>
            {fullmoves.map((m, i) => {
              const white = moves[findMoveIndex(i, 0)];
              const black = moves[findMoveIndex(i, 1)];
              return (
                <tr
                  translate="no"
                  key={i}
                  ref={
                    selected &&
                    (selected === white || selected === black) &&
                    (selected !== last || !result)
                      ? refSelected
                      : undefined
                  }
                >
                  <td>{i + 1}</td>
                  <td className={styles.white}>
                    {white && (
                      <button
                        className={
                          selected === white ? styles.selected : undefined
                        }
                        onClick={() => handleMove(white)}
                      >
                        {m[0]}
                      </button>
                    )}
                    {!white && <button disabled>{m[0]}</button>}
                  </td>
                  <td>
                    {black && (
                      <button
                        className={
                          selected === black ? styles.selected : undefined
                        }
                        onClick={() => handleMove(black)}
                      >
                        {m[1]}
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
            {result && (
              <tr ref={selected && selected === last ? refSelected : undefined}>
                <td></td>
                <td className={styles.result} colSpan={2}>
                  {result}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
});

/*
    ____,-------------------------------,____
    \   |              Хуки             |   /
    /___|-------------------------------|___\
*/

function useScrollToLatest(moves: Move[], result?: string, selected?: Move) {
  const refScrollable = useRef<HTMLDivElement>(null);
  const refFirstTime = useRef(false);

  useLayoutEffect(
    () => {
      if (!refScrollable.current || selected) {
        refFirstTime.current = true;
        return;
      }

      refScrollable.current.scrollTo({
        top: refScrollable.current.scrollHeight,
        behavior: refFirstTime.current ? 'smooth' : 'instant',
      });
      refFirstTime.current = true;
    },
    /* Скроллим при изменении кол-ва ходов или изменении результата */
    [moves.length, result, selected],
  );

  return { refScrollable };
}

function useNavigate<T extends Move>(
  selected: T | undefined,
  moves: T[],
  onNavigate: (nextSelected: T, prevSelected: T) => void,
) {
  const disable = moves.length === 0;
  const disableBackward =
    disable ||
    moves.length === 1 /* с одним ходом тоже некуда переключаться */ ||
    selected === moves[0];
  const disableForward =
    disable || !selected || selected === moves[moves.length - 1];

  const handleBackward = () => {
    if (disableBackward) return;
    const prevInx = findSelectedIndex(moves, selected);
    onNavigate(moves[prevInx - 1]!, moves[prevInx]!);
  };
  const handleFastBackward = () => {
    if (disableBackward) return;
    const prevIx = findSelectedIndex(moves, selected);
    onNavigate(moves[0]!, moves[prevIx]!);
  };
  //-
  const handleForward = () => {
    if (disableForward) return;
    const prevIx = findSelectedIndex(moves, selected);
    onNavigate(moves[prevIx + 1]!, moves[prevIx]!);
  };
  const handleFastForward = () => {
    if (disableForward) return;
    const next = moves[moves.length - 1];
    const prevIx = findSelectedIndex(moves, selected);
    onNavigate(next!, moves[prevIx]!);
  };
  //-
  const handleMove = (move: T) => {
    if (disable) return;
    const prevIx = findSelectedIndex(moves, selected);
    const prev = moves[prevIx]!;
    if (selected && prev === move) return;
    onNavigate(move, prev);
  };

  return {
    disableBackward,
    disableForward,
    handleFastBackward,
    handleBackward,
    handleForward,
    handleFastForward,
    handleMove,
  };
}

function useFocusSelected(selected: Move | undefined) {
  const refMounted = useRef(false);

  const refSelected = useRef<HTMLTableRowElement>(null);
  useEffect(() => {
    if (!refSelected.current) return;

    refSelected.current.scrollIntoView({
      block: refMounted.current ? 'nearest' : 'center',
      behavior: refMounted.current ? 'smooth' : 'instant',
    });
  }, [selected]);

  useEffect(() => {
    refMounted.current = true;
  }, []);

  return {
    refSelected,
  };
}

/*
    ____,-------------------------------,____
    \   |            Утилиты            |   /
    /___|-------------------------------|___\
*/

function toFullMoves(moves: Move[]): string[][] {
  return moves.reduce((sink, m, i) => {
    const isBlack = i % 2 === 1;

    if (isBlack) {
      sink[sink.length - 1]?.push(m.san);
    } else {
      sink.push([m.san]);
    }

    return sink;
  }, [] as string[][]);
}

function findSelectedIndex(moves: Move[], selected?: Move): number {
  if (!moves.length) return -1;
  if (!selected) return moves.length - 1;
  return moves.findIndex((m) => m === selected);
}

function findMoveIndex(fullmoveIx: number, pairIx: number): number {
  return fullmoveIx * 2 + pairIx;
}

/*
    ____,-------------------------------,____
    \   |             Иконки            |   /
    /___|-------------------------------|___\
*/

type IconProps = { className?: string };

function IconFastBack({ className }: IconProps) {
  return (
    <svg
      className={className}
      width="24"
      height="14"
      viewBox="0 0 24 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.36657 9.68328C3.02492 8.51246 1.8541 7.92705 1.8541 7C1.8541 6.07295 3.02492 5.48754 5.36656 4.31672L9.65836 2.17082C11.6042 1.19788 12.5772 0.711403 13.2886 1.15107C14 1.59075 14 2.67853 14 4.8541V9.1459C14 11.3215 14 12.4093 13.2886 12.8489C12.5772 13.2886 11.6042 12.8021 9.65836 11.8292L5.36657 9.68328Z"
        fill="currentColor"
      />
      <path
        d="M15.3666 9.68328C13.0249 8.51246 11.8541 7.92705 11.8541 7C11.8541 6.07295 13.0249 5.48754 15.3666 4.31672L19.6584 2.17082C21.6042 1.19788 22.5772 0.711403 23.2886 1.15107C24 1.59075 24 2.67853 24 4.8541V9.1459C24 11.3215 24 12.4093 23.2886 12.8489C22.5772 13.2886 21.6042 12.8021 19.6584 11.8292L15.3666 9.68328Z"
        fill="currentColor"
      />
    </svg>
  );
}

function IconBack({ className }: IconProps) {
  return (
    <svg
      className={className}
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.11657 9.68328C1.77492 8.51246 0.604102 7.92705 0.604102 7C0.604102 6.07295 1.77492 5.48754 4.11656 4.31672L8.40836 2.17082C10.3542 1.19788 11.3272 0.711403 12.0386 1.15107C12.75 1.59075 12.75 2.67853 12.75 4.8541V9.1459C12.75 11.3215 12.75 12.4093 12.0386 12.8489C11.3272 13.2886 10.3542 12.8021 8.40836 11.8292L4.11657 9.68328Z"
        fill="currentColor"
      />
    </svg>
  );
}
