import { cx } from '@libs/classnames';
import { useTranslate } from '@ui/components/TranslateProvider';
import styles from './NetworkProblems.module.css';

export type Props = {
  className?: string;
};

export function NetworkProblems({ className }: Props) {
  const { t } = useTranslate();
  return (
    <section className={cx(styles.networkProblems, className)}>
      <h2 className={styles.header}>
        {t('features/game/ui/NetworkProblems/Header')}
      </h2>
      <p className={styles.text}>
        {t('features/game/ui/NetworkProblems/Text')}
      </p>
    </section>
  );
}
