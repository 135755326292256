import { components } from '@features/game/api/generated';
import { cx } from '@libs/classnames';
import { GoldenModal } from '@ui/components/GoldenModal/GoldenModal';
import styles from './ResultModal.module.css';

export type Props = {
  className?: string;
  result: components['schemas']['GameResult'];
};

export function ResultModal({ className, result }: Props) {
  return (
    <GoldenModal className={cx(styles.result_modal, className)}>
      <div>
        <p className={styles.title}>Партия окончена</p>
        {(() => {
          switch (result) {
            case 'Draw':
              return 'Ничья';
            case 'BlackWin':
              return 'Победа черных';
            case 'WhiteWin':
              return 'Победа белых';
          }
        })()}
      </div>
    </GoldenModal>
  );
}
