import { cx } from '@libs/classnames';
import { Button } from '@ui/components/Button';
import { useTranslate } from '@ui/components/TranslateProvider';
import styles from './DrawOffer.module.css';

export type Props = {
  className?: string;
  onAccept?: () => void;
  onDecline?: () => void;
};

export function DrawOffer({ className, onDecline, onAccept }: Props) {
  const { t } = useTranslate();
  return (
    <div className={cx(styles.root, className)}>
      <p className={styles.title}>
        {t('features/game/pages/GamePage/DrawOffer/Text')}
      </p>
      <div className={styles.buttons}>
        <Button onClick={onDecline}>
          {t('features/game/pages/GamePage/DrawOffer/Decline')}
        </Button>
        <Button onClick={onAccept}>
          {t('features/game/pages/GamePage/DrawOffer/Accept')}
        </Button>
      </div>
    </div>
  );
}
