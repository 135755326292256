import { LoginForm } from 'features/auth/ui/LoginForm';
import { TournamentSubHeader } from 'features/shared/ui/TournamentSubHeader';
import { useCallback, useEffect } from 'react';
import { useCrossLoginMutation, useLoginMutation } from '@features/shared/api';
import { TournamentLayout } from '@features/shared/ui/TournamentLayout/TournamentLayout';
import { useNavigate } from '@tanstack/react-router';
import { useMediaQuery } from '@ui/components/MediaQueryProvider';
import { TextView } from '@ui/components/TextView';
import { PhoenixBigIcon } from '@ui/icons/PhoenixBigIcon';
import styles from './LoginPage.module.css';

const RULES_URL = 'https://chessopen.ru/politic/';
const PHONE = undefined;
const EMAIL = 'info@chessopen.ru';

export function LoginPage({
  redirect,
  chessopenToken,
}: {
  redirect?: string;
  chessopenToken?: string;
}) {
  const isDesktop = useMediaQuery() === 'desktop';
  const navigate = useNavigate();
  const login = useLoginMutation();
  const crossLogin = useCrossLoginMutation();

  const isSuccess = login.isSuccess || crossLogin.isSuccess;

  // отправить на кросс-логин
  const handleChessOpenClick = useCallback(() => {
    const url = redirect
      ? `${__RUNTIME.chessopenAuthUrl}?back=${redirect}`
      : __RUNTIME.chessopenAuthUrl;
    window.location.href = url;
  }, [redirect]);

  // обработать пользователя, вернувшегося с кросс-логина
  useEffect(() => {
    const mutate = crossLogin.mutate;
    if (chessopenToken) {
      mutate({ token: chessopenToken });
    }
  }, [chessopenToken, crossLogin.mutate]);

  // редирект после логина
  useEffect(() => {
    if (isSuccess) {
      navigate({ to: redirect ?? '/club', replace: Boolean(chessopenToken) });
    }
  }, [chessopenToken, isSuccess, navigate, redirect]);

  return (
    <TournamentLayout>
      <TournamentSubHeader title="Турнирная платформа Русской Шахматной Школы" />

      {isDesktop && (
        <div className={styles.phoenix}>
          <PhoenixBigIcon />
        </div>
      )}

      <div className={styles.content}>
        <div className={styles.description}>
          <TextView className={styles.description}>
            Наша платформа позволяет создавать шахматные клубы и проводить как
            открытые турниры, так и состязания по приглашению. Удобные настройки
            и приятный игровой интрефейс позволят провести онлайн-турнир на
            самом высоком уровне.
            <br />
            <br />
            Если вы хотите организовать турнир, свяжитесь с нашим менеджером,
            который проведет демонстрацию платформы и поможет зарегистрировать
            шахматный клуб.
          </TextView>
          <div className={styles.contacts}>
            {PHONE && (
              <a href={`tel:${PHONE}`} className={styles.phone}>
                {PHONE}
              </a>
            )}
            {EMAIL && <a href={`mailto:${EMAIL}`}>{EMAIL}</a>}
          </div>
        </div>
        <div>
          <LoginForm
            loading={login.isPending || crossLogin.isPending}
            onSubmit={login.mutate}
            loginError={Boolean(login.error || crossLogin.error)}
            rulesUrl={RULES_URL}
            onChessOpenClick={handleChessOpenClick}
          />
        </div>
      </div>
    </TournamentLayout>
  );
}
