import { ThemeIconButton } from 'features/shared/ui/ThemeIconButton';
import {
  ReactNode,
  RefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ClockControlType,
  ParticipantDto,
  TournamentTeam,
} from '@features/shared/api/typings';
import { MoveParticipantButton } from '@features/tournament/ui/components/MoveParticipantButton/MoveParticipantButton';
import { TeamAddForm } from '@features/tournament/ui/components/TeamAddForm';
import { groupBy, sortBy, toRecord } from '@libs/array';
import { cx } from '@libs/classnames';
import { FUNC_NOOP } from '@libs/constants';
import { useDrag, useDrop } from '@libs/hooks/useDragAndDrop';
import { Loader } from '@ui/components/Loader';
import { Popover } from '@ui/components/Popover';
import { Table, TableCell, TableRow } from '@ui/components/Table';
import { Tabs } from '@ui/components/Tabs';
import { ThemeButton } from '@ui/components/ThemeButton';
import { RemoveIcon } from '@ui/icons/RemoveIcon';
import { MoveParticipantButtonProps } from '../MoveParticipantButton';
import styles from './TournamentParticipantsEdit.module.css';

// FIXME: сделать плавные смещения
// FIXME: автоскролл при drag

/*
    ____,-------------------------------,____
    \   |          Компонент            |   /
    /___|-------------------------------|___\
*/

const TABS = [
  { value: 'Participating', label: 'Подтвердившие' } as const,
  { value: 'Invited', label: 'Приглашенные' } as const,
  { value: 'Exited', label: 'Удаленные' } as const,
  { value: 'Banned', label: 'Забаненные' } as const,
];

type Tab = (typeof TABS)[number]['value'];

type IParticipantRow = {
  type: 'participant';
  participant: ParticipantDto;
  num: number;
  teamId: string | null;
};
type ITeamRow = {
  type: 'team';
  team: TournamentTeam;
  members: number;
  num: number;
  teamId: string;
};
type IRowData = IParticipantRow | ITeamRow;
type IDragInfo = {
  offsetY: number;
  userId: string;
};
export type IRearrangedItem = {
  userId: string;
  teamId: string | null;
  numberInTournament: number | null;
  numberInTeam: number | null;
};

export type Props = {
  className?: string;
  isPending: boolean;
  participants?: ParticipantDto[];
  tab: Tab;
  isTeam?: boolean;
  teams?: TournamentTeam[];
  clockControl: ClockControlType;
  maxPerTeam: number;
  modal: ReactNode;
  modalVisible: boolean;
  disabled?: boolean;
  onRearrange?: (items: IRearrangedItem[]) => void;
  onTabChange: (newTab: Tab) => void;
  onCreateTeam: (teamName: string) => void;
  onRemoveTeam: (teamId: string) => void;
  onRemoveParticipant: (userId: string) => void;
  setModalVisible: (visible: boolean) => void;
};

export function TournamentParticipantsEdit({
  className,
  isPending,
  participants,
  tab,
  onTabChange,
  isTeam,
  teams,
  clockControl,
  maxPerTeam,
  onRearrange,
  onCreateTeam,
  onRemoveTeam,
  onRemoveParticipant,
  modal,
  modalVisible,
  setModalVisible,
  disabled,
}: Props) {
  const showTeams = isTeam && tab === 'Participating';
  //
  const { rows } = useRows(participants, showTeams ? teams : null);
  //
  const {
    displayRows,
    refDragPreview,
    dragRow,
    dragInfo,
    onDragChange,
    onDragOverUser,
    onDragOverTeam,
    onAddUserToTeam,
  } = useTableDnD(rows, maxPerTeam);
  //
  const handleDragChange = (info: IDragInfo | null) => {
    onDragChange(info);
    if (info === null) {
      onRearrange?.(rowsToRearrangedItems(displayRows));
    }
  };
  const handleAddUserToTeam = (userId: string, teamId?: string) => {
    const nextRows = onAddUserToTeam(userId, teamId);
    onRearrange?.(rowsToRearrangedItems(nextRows));
  };
  //
  const teamsMenuItems = useMemo(
    () =>
      displayRows
        .filter((r) => r.type === 'team')
        .map((r) => ({
          teamId: r.teamId,
          name: r.team.name,
          members: r.members,
          disabled: r.members >= maxPerTeam,
        })),
    [displayRows, maxPerTeam],
  );
  const teamsDict = useMemo(
    () => toRecord(teamsMenuItems, (r) => r.teamId),
    [teamsMenuItems],
  );

  return (
    <div className={cx(className, styles.root)}>
      <Tabs
        className={styles.tabs}
        tabs={TABS}
        onChange={onTabChange}
        value={tab}
        disabled={disabled}
      />

      {isPending && <Loader className={styles.loader} centered />}

      {!isPending && (
        <>
          <Table
            className={cx(styles.table, { [styles.draggingTable]: dragInfo })}
          >
            {/* Шапка */}
            {!!participants?.length && (
              <TableRow header>
                <TableCell colSpan={5}>Имя</TableCell>
                <TableCell right>Рейтинг</TableCell>
                <TableCell right className={styles.buttonColumn}></TableCell>
              </TableRow>
            )}

            {/* Строки */}
            {displayRows.map((r) => {
              const isDisabledForDrop =
                dragRow &&
                teamsDict[r.teamId ?? '']?.disabled &&
                r.teamId !== dragRow.teamId;

              return r.type === 'participant' ? (
                <ParticipantRow
                  key={r.participant.userId}
                  className={cx({
                    [styles.disabledDropRow]: isDisabledForDrop,
                  })}
                  teams={teamsMenuItems}
                  num={r.num}
                  participant={r.participant}
                  clockControl={clockControl}
                  disabled={disabled}
                  dragInfo={dragInfo}
                  currentTeamId={r.teamId ?? undefined}
                  refDragPreview={refDragPreview}
                  onRemove={onRemoveParticipant}
                  onSelectTeam={
                    tab === 'Participating' ? handleAddUserToTeam : undefined
                  }
                  onDragChange={handleDragChange}
                  onDragOverUser={onDragOverUser}
                />
              ) : (
                <TeamRow
                  key={r.team.teamId}
                  className={cx({
                    [styles.disabledDropRow]: isDisabledForDrop,
                  })}
                  num={r.num}
                  teamId={r.team.teamId}
                  name={r.team.name}
                  disabled={disabled}
                  dragInfo={dragInfo}
                  onRemoveTeam={onRemoveTeam}
                  onDragOverTeam={onDragOverTeam}
                />
              );
            })}

            {/* Drag Preview */}
            {dragInfo && dragRow && (
              <ParticipantRow
                num={dragRow.num}
                participant={dragRow.participant}
                clockControl={clockControl}
                isDragPreview
                refDragPreview={refDragPreview}
                dragInfo={dragInfo}
                onSelectTeam={FUNC_NOOP}
              />
            )}
          </Table>

          {/* Подвал */}
          <Footer
            tab={tab}
            onTeamCreate={
              (isTeam && tab === 'Participating' && onCreateTeam) || undefined
            }
            totalTeamsCount={teams?.length || 0}
            modal={modal}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            disabled={disabled}
          />
        </>
      )}
    </div>
  );
}

/*
    ____,-------------------------------,____
    \   |            Участники          |   /
    /___|-------------------------------|___\
*/

function ParticipantRow({
  className,
  participant,
  num,
  clockControl,
  teams,
  currentTeamId,
  disabled,
  dragInfo,
  isDragPreview,
  refDragPreview,
  onRemove,
  onSelectTeam,
  onDragChange,
  onDragOverUser,
}: {
  className?: string;
  participant: ParticipantDto;
  num: number;
  clockControl?: ClockControlType;
  teams?: MoveParticipantButtonProps['teams'];
  currentTeamId?: string;
  disabled?: boolean;
  dragInfo?: null | IDragInfo;
  isDragPreview?: boolean;
  refDragPreview: RefObject<HTMLTableRowElement>;
  onRemove?: (id: string) => void;
  onSelectTeam?: (userId: string, teamId?: string) => void;
  onDragChange?: (dragInfo: IDragInfo | null) => void;
  onDragOverUser?: (userId: string, overUserId: string) => void;
}) {
  const handleRemove = useCallback(() => {
    onRemove?.(participant.userId);
  }, [participant, onRemove]);

  const refRow = useRef<HTMLTableRowElement>(null);
  const refHandle = useRef<HTMLTableCellElement>(null);

  const dragging = !isDragPreview && dragInfo?.userId === participant.userId;
  const [dropping, setDropping] = useState(false);

  useDrag(refHandle, refDragPreview, {
    canDrag: () => !disabled,
    onStart: () => {
      const { y } = refHandle.current!.getBoundingClientRect();
      onDragChange?.({ userId: participant.userId, offsetY: y });
      document.body.classList.add(styles.bodyGrabbing);
      return { participant };
    },
    onStop() {
      onDragChange?.(null);
      document.body.classList.remove(styles.bodyGrabbing);

      if (refRow.current) {
        refRow.current.style.translate = '';
      }
    },
  });

  useDrop(refRow, {
    onDrop() {
      setTimeout(() => setDropping(false), 0);
    },
    onEnter(_ctx) {
      setDropping(true);
      if (!dragInfo) return;
      onDragOverUser?.(dragInfo.userId, participant.userId);
    },
    onLeave() {
      setDropping(false);
    },
  });

  const rank = ((elo) => {
    switch (clockControl) {
      case 'Blitz':
        return elo.blitz.rating.toString();
      case 'Rapid':
        return elo.rapid.rating.toString();
      case 'Classic':
        return elo.classic.rating.toString();
    }
  })(participant.elo);

  useLayoutEffect(() => {
    if (!isDragPreview || !dragInfo || !refDragPreview.current) return;

    refDragPreview.current.style.top = `${dragInfo.offsetY - refHandle.current!.getBoundingClientRect().y}px`;
  }, [dragInfo, isDragPreview, refDragPreview]);

  return (
    <TableRow
      className={cx(styles.participantRow, className, {
        [styles.dragRow]: dragging,
        [styles.dragPreview]: isDragPreview,
      })}
      hoverable={!dragInfo && !isDragPreview}
      selected={isDragPreview}
      ref={isDragPreview ? refDragPreview : refRow}
    >
      {onSelectTeam && (
        <TableCell ref={refHandle} className={styles.burgerButtonCell}>
          <MoveParticipantButton
            teams={teams}
            currentTeamId={currentTeamId}
            disableMenu={dragging || dropping}
            onSelectTeam={(teamId?: string) =>
              onSelectTeam?.(participant.userId, teamId)
            }
          />
          {dragInfo && !isDragPreview && (
            <div className={styles.rowExtendedHitbox}></div>
          )}
        </TableCell>
      )}
      <TableCell className={styles.numbers}>{num}</TableCell>
      <TableCell>
        {participant.lastName} {participant.firstName}
      </TableCell>
      <TableCell>{participant.title}</TableCell>
      <TableCell>{participant.rcfId}</TableCell>
      <TableCell slim right>
        {rank}
      </TableCell>
      <TableCell slim right>
        <ThemeIconButton onClick={handleRemove} disabled={disabled}>
          <RemoveIcon />
        </ThemeIconButton>
      </TableCell>
    </TableRow>
  );
}

/*
    ____,-------------------------------,____
    \   |            Комманды           |   /
    /___|-------------------------------|___\
*/

function TeamRow({
  className,
  num,
  name,
  teamId,
  disabled,
  dragInfo,
  onRemoveTeam,
  onDragOverTeam,
}: {
  className?: string;
  num: number;
  name: string;
  teamId: string;
  disabled?: boolean;
  dragInfo: null | IDragInfo;
  onRemoveTeam: (teamId: string) => void;
  onDragOverTeam: (userId: string, teamId: string) => void;
}) {
  const handleRemove = useCallback(() => {
    onRemoveTeam(teamId);
  }, [teamId, onRemoveTeam]);

  const refRow = useRef<HTMLTableRowElement>(null);

  useDrop(refRow, {
    onEnter() {
      if (!dragInfo) return;

      onDragOverTeam(dragInfo.userId, teamId);
    },
  });

  return (
    <>
      <TableRow
        className={cx(styles.teamRow, className)}
        ref={refRow}
        hoverable={!dragInfo}
      >
        <TableCell className={styles.numbers}>
          {num}
          {dragInfo && <div className={styles.rowExtendedHitbox}></div>}
        </TableCell>
        <TableCell colSpan={4}>{name}</TableCell>
        <TableCell right></TableCell>
        <TableCell right>
          <ThemeIconButton onClick={handleRemove} disabled={disabled}>
            <RemoveIcon />
          </ThemeIconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

/*
    ____,-------------------------------,____
    \   |            Футер              |   /
    /___|-------------------------------|___\
*/

function Footer({
  tab,
  onTeamCreate,
  totalTeamsCount,
  modal,
  modalVisible,
  setModalVisible,
  disabled,
}: {
  tab: Tab;
  onTeamCreate?: (newTeamName: string) => void;
  modal: ReactNode;
  modalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
  totalTeamsCount: number;
  disabled?: boolean;
}) {
  const [teamFormVisible, setTeamFormVisible] = useState(false);
  const showParticipantsModal = useCallback(
    () => setModalVisible(true),
    [setModalVisible],
  );
  const hideParticipantsModal = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const handleCreateTeam = useCallback(
    async (newTeamName: string) => {
      await onTeamCreate?.(newTeamName);
      setTeamFormVisible(false);
    },
    [onTeamCreate, setTeamFormVisible],
  );

  return (
    <>
      <div className={styles.addButtons}>
        {teamFormVisible && (
          <TeamAddForm
            totalTeamsCount={totalTeamsCount}
            onSubmit={handleCreateTeam}
            onCancel={() => setTeamFormVisible(false)}
            disabled={disabled}
          />
        )}
        {!teamFormVisible && (
          <>
            {tab === 'Participating' && onTeamCreate && (
              <ThemeButton
                className={styles.button}
                onClick={() => setTeamFormVisible(true)}
                disabled={disabled}
              >
                Добавить команду
              </ThemeButton>
            )}
            {tab === 'Participating' && (
              <ThemeButton
                className={styles.button}
                onClick={showParticipantsModal}
                disabled={disabled}
              >
                Добавить участника
              </ThemeButton>
            )}
            {tab === 'Invited' && (
              <ThemeButton
                className={styles.button}
                onClick={showParticipantsModal}
                disabled={disabled}
              >
                Пригласить участника
              </ThemeButton>
            )}
          </>
        )}
      </div>

      <div
        className={cx(styles.popoverWrapper, {
          [styles.active]: modalVisible,
        })}
      >
        <Popover
          className={styles.participantsPopover}
          active={modalVisible}
          onHide={hideParticipantsModal}
        >
          {modalVisible && modal}
        </Popover>
      </div>
    </>
  );
}

/*
    ____,-------------------------------,____
    \   |             Хуки              |   /
    /___|-------------------------------|___\
*/

function useRows(
  participants?: ParticipantDto[],
  teams?: TournamentTeam[] | null,
) {
  const hasTeams = teams && teams.length > 0;

  const groupedByTeam = useMemo(() => {
    const mapped: IParticipantRow[] =
      participants?.map((p) => ({
        type: 'participant',
        participant: p,
        num: (p.teamId ? p.numberInTeam : p.numberInTournament) ?? 1,
        teamId: hasTeams ? p.teamId : null,
      })) ?? [];

    const groups = groupBy(mapped, (p) => {
      const { teamId } = p.participant;
      return hasTeams && teamId ? teamId : 'free';
    });

    Object.keys(groups).forEach((k) => {
      if (k === 'free') {
        sortBy(groups[k]!, (p) => p.participant.numberInTournament || Infinity);
      } else {
        sortBy(groups[k]!, (p) => p.participant.numberInTeam || Infinity);
      }
    });

    return groups;
  }, [participants, hasTeams]);

  const rows = useMemo(() => {
    const r: IRowData[] = [];

    if (groupedByTeam.free) r.push(...groupedByTeam.free);

    teams?.forEach((t, i) => {
      const members = groupedByTeam[t.teamId];
      r.push({
        type: 'team',
        team: t,
        members: members?.length ?? 0,
        num: i + 1,
        teamId: t.teamId,
      });
      if (members) {
        r.push(...members);
      }
    });

    updateRowsMetadata(r);

    return r;
  }, [groupedByTeam, teams]);

  return {
    rows,
  };
}

function useTableDnD(rows: IRowData[], maxPerTeam?: number) {
  // показываем спекулятивные изменения только если данные не менялись
  const [{ speculativeRows, baseRows }, setSorted] = useState({
    speculativeRows: rows,
    baseRows: rows,
  });
  const displayRows = rows === baseRows ? speculativeRows : rows;

  const [dragInfo, setDragInfo] = useState<IDragInfo | null>(null);
  const refDragPreview = useRef<HTMLTableRowElement>(null);
  const dragRow = useMemo(() => {
    if (!dragInfo) return null;
    return displayRows.find(
      (r) =>
        r.type === 'participant' && r.participant.userId === dragInfo.userId,
    ) as IParticipantRow;
  }, [dragInfo, displayRows]);

  return {
    displayRows,
    dragInfo,
    refDragPreview,
    dragRow,
    onDragChange: useCallback((info: null | IDragInfo) => {
      setDragInfo(info);
    }, []),
    onDragOverUser(userId: string, overUserId: string) {
      const next = structuredClone(displayRows);

      const userIndex = next.findIndex(
        (r) => r.type === 'participant' && r.participant.userId === userId,
      );
      const overUserIndex = next.findIndex(
        (r) => r.type === 'participant' && r.participant.userId === overUserId,
      );

      const user = next[userIndex] as IParticipantRow;
      const overUser = next[overUserIndex] as IParticipantRow;

      next.splice(userIndex, 1)[0]!;
      next.splice(overUserIndex, 0, user);

      user.teamId = overUser.teamId;

      updateRowsMetadata(next);

      setSorted({
        speculativeRows: next,
        baseRows: rows,
      });
    },
    onDragOverTeam(userId: string, teamId: string) {
      const userIndex = displayRows.findIndex(
        (r) => r.type === 'participant' && r.participant.userId === userId,
      );
      const teamIndex = displayRows.findIndex(
        (r) => r.type === 'team' && r.team.teamId === teamId,
      );
      const user = displayRows[userIndex] as IParticipantRow;

      // CORNER CASE: перетаскивание в команду выше (наведением на строку
      // текущей команды)
      if (maxPerTeam && teamIndex < userIndex) {
        const prev = displayRows[teamIndex - 1];
        const prevTeamId = prev?.teamId;
        if (
          prevTeamId &&
          displayRows.find(
            (r) =>
              r.type === 'team' &&
              r.teamId === prevTeamId &&
              r.members >= maxPerTeam,
          )
        ) {
          return;
        }
      }

      const next = structuredClone(displayRows);
      next.splice(userIndex, 1)[0]!;
      next.splice(teamIndex, 0, user);

      updateRowsMetadata(next);

      setSorted({
        speculativeRows: next,
        baseRows: rows,
      });
    },
    onAddUserToTeam(userId: string, teamId?: string) {
      const userIndex = displayRows.findIndex(
        (r) => r.type === 'participant' && r.participant.userId === userId,
      );
      const user = displayRows[userIndex] as IParticipantRow;

      const next = structuredClone(displayRows);
      next.splice(userIndex, 1)[0]!;
      const teamIndex =
        next.length -
        [...next]
          .reverse()
          .findIndex((r) => (teamId ? r.teamId === teamId : !r.teamId));
      next.splice(teamIndex, 0, user);

      updateRowsMetadata(next);

      setSorted({
        speculativeRows: next,
        baseRows: rows,
      });

      return next;
    },
  };
}

/*
    ____,-------------------------------,____
    \   |             Утилиты           |   /
    /___|-------------------------------|___\
*/

function updateRowsMetadata(rows: IRowData[]): void {
  let teamId: string | null = null;
  let tNum = 0;
  let pNum = 0;

  const teams: ITeamRow[] = [];

  rows.forEach((r) => {
    if (r.type === 'participant') {
      pNum++;
      r.num = pNum;
      r.teamId = teamId;
    } else if (r.type === 'team') {
      if (teams.length) {
        teams[teams.length - 1]!.members = pNum;
      }
      teams.push(r);
      pNum = 0;
      tNum++;
      r.num = tNum;
      teamId = r.teamId;
    }
  });

  if (teams.length) {
    teams[teams.length - 1]!.members = pNum;
  }
}

function rowsToRearrangedItems(rows: IRowData[]): IRearrangedItem[] {
  const ps = rows.filter((r) => r.type === 'participant') as IParticipantRow[];
  const items: IRearrangedItem[] = ps.map((p) => ({
    userId: p.participant.userId,
    teamId: p.teamId,
    numberInTeam: p.teamId ? p.num : null,
    numberInTournament: p.teamId ? null : p.num,
  }));

  return items;
}
