import { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  ClockControlType,
  ClockControlTypes,
} from '@features/shared/api/typings';
import { gameTimeControlConfig } from '@features/tournament/constants';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Input } from '@ui/components/Input';
import { Tabs } from '@ui/components/Tabs';

const timeControlTabs: { value: ClockControlType; label: string }[] = [
  { value: 'Blitz', label: 'Блиц' },
  { value: 'Rapid', label: 'Рапид' },
  { value: 'Classic', label: 'Классические' },
];

type ClockControlSetting = {
  initialSeconds: number;
  addSecondsPerMove: number;
};

type PerPlayerValue = {
  clockControl: ClockControlType;
  clockControlSettingsPlayer1: ClockControlSetting;
  clockControlSettingsPlayer2: ClockControlSetting;
};
type PerPlayerProps = {
  perPlayer: true;
  player1Label?: ReactNode;
  player2Label?: ReactNode;
  value: PerPlayerValue;
  onChange: (newValue: PerPlayerValue) => void;
};

type EqualsValue = {
  clockControl: ClockControlType;
  clockControlSettings: ClockControlSetting;
};
type EqualProps = {
  perPlayer: false;
  value: EqualsValue;
  onChange: (newValue: EqualsValue) => void;
};

type Props = {
  label: ReactNode;
  labelIndented?: boolean;
  disabled?: boolean;
  disableAutoCorrection?: boolean;
} & (PerPlayerProps | EqualProps);
export function ClockControlInput(props: Props) {
  const {
    label,
    labelIndented,
    value,
    perPlayer,
    onChange,
    disabled,
    disableAutoCorrection,
  } = props;
  const conf = gameTimeControlConfig;
  const [localValue1, setLocalValue1] = useState(
    perPlayer ? value.clockControlSettingsPlayer1 : value.clockControlSettings,
  );
  const [localValue2, setLocalValue2] = useState(
    perPlayer ? value.clockControlSettingsPlayer2 : value.clockControlSettings,
  );

  useEffect(() => {
    setLocalValue1(
      perPlayer
        ? value.clockControlSettingsPlayer1
        : value.clockControlSettings,
    );
    setLocalValue2(
      perPlayer
        ? value.clockControlSettingsPlayer2
        : value.clockControlSettings,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleClockControlTypeChange = useCallback(
    (newClockControl: ClockControlType) => {
      if (value.clockControl === newClockControl) return;
      const currConf = conf[newClockControl];

      if (perPlayer) {
        onChange({
          clockControl: newClockControl,
          clockControlSettingsPlayer1: {
            initialSeconds: currConf.defaultInitialSeconds,
            addSecondsPerMove: currConf.defaultSecondsAdded,
          },
          clockControlSettingsPlayer2: {
            initialSeconds: currConf.defaultInitialSeconds,
            addSecondsPerMove: currConf.defaultSecondsAdded,
          },
        });
      } else {
        onChange({
          clockControl: newClockControl,
          clockControlSettings: {
            initialSeconds: currConf.defaultInitialSeconds,
            addSecondsPerMove: currConf.defaultSecondsAdded,
          },
        });
      }
    },
    [value, onChange, conf, perPlayer],
  );

  const handleSettingsLocalChange = useCallback(
    (newValue: ClockControlSetting, playerNumber?: 1 | 2) => {
      if (perPlayer) {
        if (!playerNumber) throw new Error('playerNumber is required');

        if (playerNumber === 1) setLocalValue1(newValue);
        if (playerNumber === 2) setLocalValue2(newValue);
      } else {
        setLocalValue1(newValue);
      }
    },
    [perPlayer, setLocalValue1, setLocalValue2],
  );

  const handleSettingsChange = useCallback(
    (newSettings: ClockControlSetting, playerNumber?: 1 | 2) => {
      if (disableAutoCorrection) {
        if (perPlayer) {
          if (!playerNumber) throw new Error('playerNumber is required');

          const newValue = {
            ...value,
          };

          if (playerNumber === 1)
            newValue.clockControlSettingsPlayer1 = newSettings;
          if (playerNumber === 2)
            newValue.clockControlSettingsPlayer2 = newSettings;
          onChange(newValue);
        } else {
          onChange({
            ...value,
            clockControlSettings: newSettings,
          });
        }
        return;
      }

      let newTimeControl = value.clockControl;
      ClockControlTypes.forEach((t: ClockControlType) => {
        if (
          newSettings.initialSeconds >= conf[t].minSeconds &&
          newSettings.initialSeconds < conf[t].maxSeconds
        ) {
          newTimeControl = t;
        }
      });

      if (perPlayer) {
        if (!playerNumber) throw new Error('playerNumber is required');

        const newValue = {
          ...value,
          clockControl: newTimeControl,
        };

        if (playerNumber === 1 || newTimeControl !== value.clockControl)
          newValue.clockControlSettingsPlayer1 = newSettings;
        if (playerNumber === 2 || newTimeControl !== value.clockControl)
          newValue.clockControlSettingsPlayer2 = newSettings;
        onChange(newValue);
      } else {
        onChange({
          ...value,
          clockControl: newTimeControl,
          clockControlSettings: newSettings,
        });
      }
    },
    [onChange, value, perPlayer, conf, disableAutoCorrection],
  );

  return (
    <>
      <FormRow>
        <FormLabel indented={labelIndented}>{label}</FormLabel>
        <FormInput>
          <Tabs
            tabs={timeControlTabs}
            value={value.clockControl}
            onChange={handleClockControlTypeChange}
            disabled={disabled}
          />
        </FormInput>
      </FormRow>

      {perPlayer ? (
        <>
          <ClockControlSettingInput
            label={props.player1Label}
            playerNumber={1}
            value={localValue1}
            onBlur={handleSettingsChange}
            onChange={handleSettingsLocalChange}
            disabled={disabled}
          />
          <ClockControlSettingInput
            label={props.player2Label}
            playerNumber={2}
            value={localValue2}
            onBlur={handleSettingsChange}
            onChange={handleSettingsLocalChange}
            disabled={disabled}
          />
        </>
      ) : (
        <ClockControlSettingInput
          value={localValue1}
          onBlur={handleSettingsChange}
          onChange={handleSettingsLocalChange}
          disabled={disabled}
        />
      )}
    </>
  );
}

type ClockControlSettingInputProps = {
  playerNumber?: 1 | 2;
  label?: ReactNode;
  value: ClockControlSetting;
  onChange: (newValue: ClockControlSetting, playerNumber?: 1 | 2) => void;
  onBlur: (newValue: ClockControlSetting, playerNumber?: 1 | 2) => void;
  disabled?: boolean;
};

function ClockControlSettingInput({
  label,
  playerNumber,
  value,
  onChange,
  onBlur,
  disabled,
}: ClockControlSettingInputProps) {
  const handleBlur = useCallback(() => {
    onBlur(value, playerNumber);
  }, [onBlur, value, playerNumber]);
  const handleInitialSecondsChange = useCallback(
    (newValue: string) => {
      onChange(
        {
          ...value,
          initialSeconds: Number(newValue) * 60,
        },
        playerNumber,
      );
    },
    [onChange, value, playerNumber],
  );
  const handleAddSecondsPerMoveChange = useCallback(
    (newValue: string) => {
      onChange(
        {
          ...value,
          addSecondsPerMove: Number(newValue),
        },
        playerNumber,
      );
    },
    [onChange, value, playerNumber],
  );

  const defaultLabel = playerNumber ? `Игрок ${playerNumber}` : '';

  return (
    <FormRow>
      <FormLabel indented>{label ? label : defaultLabel}</FormLabel>
      <FormInput>
        <Input
          invisible
          type="number"
          rightLabel="мин"
          value={Math.floor(value.initialSeconds / 60).toString()}
          onChange={handleInitialSecondsChange}
          onBlur={handleBlur}
          disabled={disabled}
        />
        <Input
          invisible
          leftLabel="+"
          type="number"
          rightLabel="сек"
          value={value.addSecondsPerMove.toString()}
          onChange={handleAddSecondsPerMoveChange}
          onBlur={handleBlur}
          disabled={disabled}
        />
      </FormInput>
    </FormRow>
  );
}
