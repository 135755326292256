import { cx } from '@libs/classnames';
import {
  Lang,
  useSetLanguage,
  useTranslate,
} from '@ui/components/TranslateProvider';
import styles from './UserSettingsMenu.module.css';

export type Props = {
  className?: string;
  autoQueen?: boolean;
  darkTheme?: boolean;
  onAutoQueenToggle?: (enabled: boolean) => void;
  onDarkThemeToggle?: (enabled: boolean) => void;
};

export function UserSettingsMenu({
  className,
  autoQueen,
  darkTheme,
  onAutoQueenToggle,
  onDarkThemeToggle,
}: Props) {
  const { t } = useTranslate();
  return (
    <ul className={cx(styles.settingsMenu, className)}>
      <LangOption code="ru" label="Русский" />
      <LangOption code="en" label="English" />
      <LangOption code="fr" label="Français" />
      <li onClick={() => onAutoQueenToggle?.(!autoQueen)}>
        {autoQueen
          ? t('features/user/ui/UserSettingsMenu/Auto-promotion OFF')
          : t('features/user/ui/UserSettingsMenu/Auto-promotion ON')}
      </li>
      <li onClick={() => onDarkThemeToggle?.(!darkTheme)}>
        {darkTheme
          ? t('features/user/ui/UserSettingsMenu/Dark theme OFF')
          : t('features/user/ui/UserSettingsMenu/Dark theme ON')}
      </li>
    </ul>
  );
}

function LangOption({ code, label }: { code: Lang; label: string }) {
  const { lang, changeLang } = useSetLanguage();
  return (
    <li
      onClick={() => changeLang(code)}
      className={cx(styles.lang, { [styles.selectedLang]: lang === code })}
    >
      {label} {lang === code && '✶'}
    </li>
  );
}
