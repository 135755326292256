import { ChessBoard, ChessBoardSkeleton } from 'exports/ChessBoard';
import { memo, useCallback } from 'react';
import { TimeSyncService } from '@features/shared/services/TimeSyncService';
import { SquareName } from '@libs/chess/types';
import { cx } from '@libs/classnames';
import { useCountdownUntill } from '@libs/hooks/useCountdownUntill';
import { formatSeconds } from '@libs/time';
import { GameScoreCirce } from '@ui/components/GameScoreCircle/GameScoreCircle';
import { Loader } from '@ui/components/Loader';
import { useMediaQuery } from '@ui/components/MediaQueryProvider';
import { PageSubTitle } from '@ui/components/PageSubTitle/PageSubTitle';
import { Pagination } from '@ui/components/Pagination/Pagination';
import { PaginationDropdown } from '@ui/components/PaginationDropdown/PaginationDropdown';
import { Toggle } from '@ui/components/Toggle/Toggle';
import styles from './TournamentGames.module.css';

export type Game = {
  id: string;
  fen?: string;
  white: { name: string; time: number };
  black: { name: string; time: number };
  lastMove?: { from: SquareName; to: SquareName };
  winner?: 'white' | 'black' | 'draw';
  ticking?: Ticking;
};

type Ticking = {
  color: 'white' | 'black';
  timeMs: number;
  timeFromMs: number;
};

export type Props = {
  className?: string;
  roundNumber?: number;
  roundsCount?: number;
  games?: Game[];
  showToggle?: boolean;
  gamesHidden?: boolean;
  loading?: boolean;
  onGameClick?: (gameId: string) => void;
  onRoundClick?: (roundNumber: number) => void;
  onGamesToggle?: (newValue: boolean) => void;
};

export function TournamentGames({
  className,
  roundNumber,
  roundsCount,
  games,
  showToggle = false,
  gamesHidden,
  loading,
  onGameClick,
  onRoundClick,
  onGamesToggle,
}: Props) {
  const handleGamesToggle = useCallback(
    (show: boolean) => {
      onGamesToggle?.(!show);
    },
    [onGamesToggle],
  );

  const device = useMediaQuery();

  return (
    <section className={cx(styles.gamesSection, className)}>
      <PageSubTitle className={styles.title}>
        <div className={styles.titleLeft}>
          Партии тура
          {!gamesHidden &&
            roundNumber &&
            roundsCount &&
            (device === 'mobile' ? (
              <PaginationDropdown
                label="Тур"
                labelInner="Туры"
                pageNum={roundNumber}
                pageTotal={roundsCount}
                maxItems={5}
                onPage={onRoundClick}
              />
            ) : (
              <Pagination
                pageNum={roundNumber}
                pageTotal={roundsCount}
                maxItems={10}
                arrows="scrolling"
                onPage={onRoundClick}
              />
            ))}
        </div>
        {showToggle && (
          <Toggle value={!gamesHidden} onToggle={handleGamesToggle} />
        )}
      </PageSubTitle>
      {!games && loading && <Loader className={styles.loader} />}
      {games && (
        <Games
          loading={loading}
          games={games}
          onGameClick={onGameClick}
          hide={!!gamesHidden}
        />
      )}
    </section>
  );
}

const Games = memo(function Games({
  hide,
  games,
  loading,
  onGameClick,
}: {
  hide: boolean;
  games: Props['games'];
  loading?: boolean;
  onGameClick: Props['onGameClick'];
}) {
  return (
    <div
      className={cx(styles.games, {
        [styles.hide]: hide,
        [styles.opaque]: loading,
      })}
    >
      {games?.map((game) => (
        <article className={styles.game} key={game.id}>
          <Player
            {...game.black}
            result={
              game.winner === 'draw' || !game.winner
                ? game.winner
                : game.winner === 'black'
                  ? 'win'
                  : 'defeat'
            }
            ticking={game.ticking?.color === 'black' && game.ticking}
          />
          <div className={styles.board} onClick={() => onGameClick?.(game.id)}>
            {game.fen ? (
              <ChessBoard
                orientation="white"
                notation="none"
                position={game.fen}
                winner={game.winner}
                selectedFrom={game.lastMove?.from}
                selectedTo={game.lastMove?.to}
                noLightSpeck
              />
            ) : (
              <ChessBoardSkeleton />
            )}
          </div>
          <Player
            {...game.white}
            result={
              game.winner === 'draw' || !game.winner
                ? game.winner
                : game.winner === 'white'
                  ? 'win'
                  : 'defeat'
            }
            ticking={game.ticking?.color === 'white' && game.ticking}
          />
        </article>
      ))}
    </div>
  );
});

function Player({
  name,
  time,
  result,
  ticking,
}: {
  name: string;
  time: number;
  result?: 'win' | 'draw' | 'defeat';
  ticking: { timeMs: number; timeFromMs: number } | false;
}) {
  const { seconds } = useCountdownUntill(
    ticking
      ? TimeSyncService.instance.toClientTimestamp(
          ticking.timeFromMs + ticking.timeMs,
        )
      : 0,
    !ticking,
  );
  return (
    <div className={styles.player}>
      <span className={styles.name}>{name}</span>
      {result ? (
        <GameScoreCirce className={styles.score} result={result} />
      ) : (
        <span translate="no" className={styles.time}>
          {formatSeconds({ time: ticking ? seconds : time })}
        </span>
      )}
    </div>
  );
}
