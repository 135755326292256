import { useFormContext, useWatch } from 'react-hook-form';
import { ClockControlInput } from '@features/tournament/ui/components/ClockControlInput/ClockControlInput';
import { OneOnOneGamesCount } from '@features/tournament/ui/components/TournamentSettings/inputs/OneOnOneGamesCount';
import { OneOnOneTieGamesCount } from '@features/tournament/ui/components/TournamentSettings/inputs/OneOnOneTieGamesCount';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';

export function OneOnOneSettings() {
  const {
    control,
    setValue,
    formState: { disabled },
  } = useFormContext<TournamentEditFormValues>();

  const [
    system,
    mode,
    clockControl,
    clockControlSettingsPlayer1,
    clockControlSettingsPlayer2,
    oneOnOneSettings,
  ] = useWatch({
    name: [
      'system',
      'mode',
      'clockControl',
      'clockControlSettingsPlayer1',
      'clockControlSettingsPlayer2',
      'oneOnOneSettings',
    ],
    control,
  });

  if (system !== 'OneOnOne') return null;

  return (
    <>
      <OneOnOneGamesCount />

      {mode === 'Private' ? (
        <ClockControlInput
          label="Контроль времени"
          perPlayer
          value={{
            clockControl,
            clockControlSettingsPlayer1: clockControlSettingsPlayer1,
            clockControlSettingsPlayer2: clockControlSettingsPlayer2,
          }}
          onChange={({
            clockControl,
            clockControlSettingsPlayer1,
            clockControlSettingsPlayer2,
          }) => {
            setValue('clockControl', clockControl);
            setValue(
              'clockControlSettingsPlayer1',
              clockControlSettingsPlayer1,
            );
            setValue(
              'clockControlSettingsPlayer2',
              clockControlSettingsPlayer2,
            );
          }}
          disabled={disabled}
        />
      ) : (
        <ClockControlInput
          label="Контроль времени"
          perPlayer={false}
          value={{
            clockControl,
            clockControlSettings: clockControlSettingsPlayer1,
          }}
          onChange={({ clockControl, clockControlSettings }) => {
            setValue('clockControl', clockControl);
            setValue('clockControlSettingsPlayer1', clockControlSettings);
            setValue('clockControlSettingsPlayer2', clockControlSettings);
          }}
          disabled={disabled}
        />
      )}

      <OneOnOneTieGamesCount />

      {mode === 'Private' ? (
        <ClockControlInput
          label="Время"
          labelIndented
          perPlayer
          value={{
            clockControl: oneOnOneSettings.tieSettings.clockControl,
            clockControlSettingsPlayer1:
              oneOnOneSettings.tieSettings.clockControlSettingsPlayer1,
            clockControlSettingsPlayer2:
              oneOnOneSettings.tieSettings.clockControlSettingsPlayer2,
          }}
          onChange={({
            clockControl,
            clockControlSettingsPlayer1,
            clockControlSettingsPlayer2,
          }) => {
            setValue('oneOnOneSettings.tieSettings.clockControl', clockControl);
            setValue(
              'oneOnOneSettings.tieSettings.clockControlSettingsPlayer1',
              clockControlSettingsPlayer1,
            );
            setValue(
              'oneOnOneSettings.tieSettings.clockControlSettingsPlayer2',
              clockControlSettingsPlayer2,
            );
          }}
          disabled={
            oneOnOneSettings.tieSettings.additionalGamesCount < 1 || disabled
          }
        />
      ) : (
        <ClockControlInput
          label="Время"
          labelIndented
          perPlayer={false}
          value={{
            clockControl: oneOnOneSettings.tieSettings.clockControl,
            clockControlSettings:
              oneOnOneSettings.tieSettings.clockControlSettingsPlayer1,
          }}
          onChange={({ clockControl, clockControlSettings }) => {
            setValue('oneOnOneSettings.tieSettings.clockControl', clockControl);
            setValue(
              'oneOnOneSettings.tieSettings.clockControlSettingsPlayer1',
              clockControlSettings,
            );
            setValue(
              'oneOnOneSettings.tieSettings.clockControlSettingsPlayer2',
              clockControlSettings,
            );
          }}
          disabled={
            oneOnOneSettings.tieSettings.additionalGamesCount < 1 || disabled
          }
        />
      )}
    </>
  );
}
