import { useFormContext, useWatch } from 'react-hook-form';
import { ClockControlInput } from '@features/tournament/ui/components/ClockControlInput/ClockControlInput';
import { RivalCountInput } from '@features/tournament/ui/components/TournamentSettings/inputs/RivalCountInput';
import { SeancerSideInput } from '@features/tournament/ui/components/TournamentSettings/inputs/SeancerSideInput';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';

export function SeanceSettings() {
  const {
    control,
    setValue,
    formState: { disabled },
  } = useFormContext<TournamentEditFormValues>();

  const [
    system,
    clockControl,
    clockControlSettingsPlayer1,
    clockControlSettingsPlayer2,
  ] = useWatch({
    name: [
      'system',
      'clockControl',
      'clockControlSettingsPlayer1',
      'clockControlSettingsPlayer2',
    ],
    control,
  });

  if (system !== 'Seance') return null;

  return (
    <>
      <RivalCountInput />

      <ClockControlInput
        label="Контроль времени"
        perPlayer
        player1Label="Сеансер"
        player2Label="Соперники"
        disableAutoCorrection
        value={{
          clockControl,
          clockControlSettingsPlayer1: clockControlSettingsPlayer1,
          clockControlSettingsPlayer2: clockControlSettingsPlayer2,
        }}
        onChange={({
          clockControl,
          clockControlSettingsPlayer1,
          clockControlSettingsPlayer2,
        }) => {
          setValue('clockControl', clockControl);
          setValue('clockControlSettingsPlayer1', clockControlSettingsPlayer1);
          setValue('clockControlSettingsPlayer2', clockControlSettingsPlayer2);
        }}
        disabled={disabled}
      />

      <SeancerSideInput />
    </>
  );
}
