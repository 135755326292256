import { CSSProperties, ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './Button.module.css';

export type Props = {
  className?: string;
  style?: CSSProperties;
  type?: 'submit' | 'reset' | 'button';
  icon?: ReactNode;
  children: ReactNode;
  onClick?: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  disabled?: boolean;
  variant?: 'default' | 'condensed';
};
export function Button({
  className,
  style,
  disabled,
  type,
  icon,
  children,
  onClick,
  onKeyDown,
  variant = 'default',
}: Props) {
  return (
    <button
      className={cx(styles.button, styles[variant], className)}
      style={style}
      type={type}
      onClick={onClick}
      onKeyDown={onKeyDown}
      disabled={disabled}
    >
      {icon && <div className={styles.icon}>{icon}</div>}{' '}
      <span>{children}</span>
    </button>
  );
}
