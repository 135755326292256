import { components } from '@features/game/api/generated';
import { TableTimer } from '@features/game/ui/TableTimer';
import { cx } from '@libs/classnames';
import { AnimatedNode } from '@ui/components/Animation';
import { BoardColorCircle } from '@ui/components/BoardColorCircle';
import { Button } from '@ui/components/Button';
import { GoldenModal } from '@ui/components/GoldenModal/GoldenModal';
import styles from './TableHeader.module.css';

type IPlayer = {
  id: string;
  firstName: string;
  lastName: string;
  rating?: number | null;
};
type IGame = {
  id: string;
  white: IPlayer;
  black: IPlayer;
  boardNumber: number | null;
  clock: {
    timestampMs: number;
    blackLeftMs: number;
    whiteLeftMs: number;
  };
  nextMoveBy: 'Black' | 'White';
  status: components['schemas']['GameStatus'];
};

export type Props = {
  className?: string;
  seancerUserId: string;
  game: IGame;
  timePressureSec: number;
  fractionsFromSec: number;
  showDrawOffer?: boolean;
  customPlayersTimeMs?: { white?: number; black?: number };
  onDrawAccept?: () => void;
  onDrawDecline?: () => void;
};

export function TableHeader({
  className,
  seancerUserId,
  game,
  timePressureSec,
  fractionsFromSec,
  showDrawOffer,
  customPlayersTimeMs,
  onDrawAccept,
  onDrawDecline,
}: Props) {
  // sic! соперник чёрный, если сеансёр белый
  const isWhite = game.black.id === seancerUserId;
  const isWhiteMove = game.nextMoveBy === 'White';
  const isInProgress = game.status === 'InProgress';

  const player = isWhite ? game.white : game.black;
  const { timestampMs, blackLeftMs, whiteLeftMs } = game.clock;

  const customTimeMs = customPlayersTimeMs?.[isWhite ? 'white' : 'black'];

  const name = `${player.lastName ?? ''} ${player.firstName ?? ''}`;
  const rating = player.rating;

  return (
    <div className={cx(styles.table_header, className)}>
      <h1 className={styles.table_header__title}>Стол {game.boardNumber}</h1>
      <div className={styles.player}>
        <p className={styles.player__name} title={name}>
          <BoardColorCircle color={isWhite ? 'white' : 'black'} />
          {name}
        </p>
        {rating !== undefined && (
          <div className={styles.player__rating}>{player.rating}</div>
        )}
      </div>
      <TableTimer
        className={styles.timer}
        leftMs={customTimeMs || (isWhite ? whiteLeftMs : blackLeftMs)}
        timestampMs={timestampMs}
        paused={
          customTimeMs === undefined && isInProgress
            ? isWhite
              ? !isWhiteMove
              : isWhiteMove
            : true
        }
        highlight="normal"
        timePressureSec={timePressureSec}
        fractionsFromSec={fractionsFromSec}
        preset="big"
        timeSync
      />
      <AnimatedNode className={styles.animated_node}>
        {showDrawOffer && (
          <DrawModal
            className={styles.modal}
            onDrawAccept={onDrawAccept}
            onDrawDecline={onDrawDecline}
          />
        )}
      </AnimatedNode>
    </div>
  );
}

export function TableHeaderSkeleton({ className }: { className?: string }) {
  return (
    <div className={cx(styles.table_header, className)}>
      <h1 className={styles.table_header__title}>Стол &nbsp; </h1>
      <div className={styles.player}>
        <p className={styles.player__name}>
          <span className={styles.skeleton_text}>Имя и фамилия...</span>
        </p>
        <div className={styles.player__rating}>
          <span className={styles.skeleton_text}>0000</span>
        </div>
      </div>
    </div>
  );
}

function DrawModal({
  className,
  onDrawAccept,
  onDrawDecline,
}: {
  className?: string;
  onDrawAccept?: () => void;
  onDrawDecline?: () => void;
}) {
  return (
    <GoldenModal horizontal slim className={cx(styles.modal, className)}>
      <p className={styles.modal__text}>Соперник предлагает ничью</p>
      <Button className={styles.modal__button} onClick={onDrawAccept}>
        Принять
      </Button>
      <Button className={styles.modal__button} onClick={onDrawDecline}>
        Отклонить
      </Button>
    </GoldenModal>
  );
}
