import { TournamentSubHeader } from 'features/shared/ui/TournamentSubHeader';
import { ReactNode, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ClockControlType,
  Tournament,
  UpdateTournamentRequest,
} from '@features/shared/api/typings';
import { UnsavedChangesModal } from '@features/shared/ui/UnsavedChangesModal/UnsavedChangesModal';
import { TournamentSettings } from '@features/tournament/ui/components/TournamentSettings';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings';
import {
  getDefaultValuesFromTournament,
  getSubmitValuesFromForm,
} from '@features/tournament/ui/components/TournamentSettings';
import { cx } from '@libs/classnames';
import { useBlocker } from '@tanstack/react-router';
import { AnimatedNode } from '@ui/components/Animation';
import { AnimationCrossfade } from '@ui/components/AnimationCrossfade';
import { BookmarkButton } from '@ui/components/BookmarkButton';
import { BookmarkButtonBar } from '@ui/components/BookmarkButtonBar';
import { useErrorNotification } from '@ui/components/ErrorsNotificationProvider';
import { Loader } from '@ui/components/Loader';
import { PageTabs } from '@ui/components/PageTabs';
import { DeleteIcon } from '@ui/icons/DeleteIcon';
import { SaveIcon } from '@ui/icons/SaveIcon';
import styles from './TournamentEditPage.module.css';

const TABS = [
  { value: 'settings', label: 'Настройки' } as const,
  { value: 'participants', label: 'Участники' } as const,
];

type PageTab = (typeof TABS)['0']['value'];

export type Props = {
  tournament: Tournament;
  pageTab: PageTab;
  onPageTabChange: (page: PageTab) => void;
  onBack: () => void;
  onDelete?: () => void;
  onSave?: (
    tournament: UpdateTournamentRequest,
    opts?: { noRedirect?: boolean },
  ) => Promise<void>;
  participants?: (
    key: string,
    isTeam: boolean,
    clockControl: ClockControlType,
  ) => ReactNode;
  onUploadLogo: (file: FormData) => Promise<void>;
  onUploadRules: (file: FormData) => Promise<void>;
  disabled: boolean;
  enableUnsavedWarning?: boolean;
  _useNavigationBlocker?: typeof useBlocker; // HACK: для сторей
};

export function TournamentEditPage({
  tournament,
  pageTab,
  onPageTabChange,
  participants,
  onBack,
  onDelete,
  onSave,
  onUploadLogo,
  onUploadRules,
  disabled,
  enableUnsavedWarning,
  // для storybook
  _useNavigationBlocker = useBlocker,
}: Props) {
  const isMatch = tournament.system === 'OneOnOne';
  const methods = useForm<TournamentEditFormValues>({
    disabled,
    defaultValues: getDefaultValuesFromTournament(tournament),
  });
  const { error, reset } = useErrorNotification();
  let errStr = error?.name === 'UserError' && error?.message;
  try {
    const parsed = errStr && JSON.parse(errStr);
    errStr = parsed && parsed.message;
    if (!errStr && parsed && parsed.errors) {
      errStr = Object.keys(parsed.errors)
        .map((key) => `${key}: ${parsed.errors[key].join('; ')}`)
        .join('\n');
    }
  } catch (_e: unknown) {
    console.error('Failed to parse error in tournament edit', error);
  }

  const onError = () => {
    //console.log(error);
  };

  const { isSubmitting, dirtyFields } = methods.formState;

  // Предупреждение о несохранённых данных
  // ВАЖНО: formState.isDirty ненадёжен и даёт false positives
  const isDirty = Object.keys(dirtyFields).length > 0;
  const unsavedWarningCondition = enableUnsavedWarning && isDirty && !disabled;
  const unsavedBlock = _useNavigationBlocker({
    condition: unsavedWarningCondition,
  });
  const [ignoredWarning, setIgnoredWarning] = useState(false);

  //

  const handleSubmit = methods.handleSubmit(
    async (tournament: TournamentEditFormValues) => {
      reset();
      const r = await onSave?.(getSubmitValuesFromForm(tournament));

      methods.reset(tournament);

      return r;
    },
    onError,
  );
  const handleSave = methods.handleSubmit(
    async (tournament: TournamentEditFormValues) => {
      reset();
      const r = await onSave?.(getSubmitValuesFromForm(tournament), {
        noRedirect: true,
      });

      methods.reset(tournament);

      return r;
    },
    onError,
  );

  const isTeam = (methods.getValues('mode') || tournament.mode) === 'Team';
  const clockControl = methods.getValues('clockControl');
  const title = methods.watch('title') ?? tournament.title;

  useEffect(() => {
    methods.setValue('logoUrl', tournament.logoUrl);
    methods.setValue('rulesUrl', tournament.rulesUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournament.logoUrl, tournament.rulesUrl]);

  return (
    <>
      <TournamentSubHeader title={title || 'Без названия'} onBack={onBack} />

      {!tournament && <Loader className={styles.loader} centered />}

      {tournament && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit}>
            <div className={styles.root}>
              <PageTabs
                className={styles.tabs}
                tabs={TABS}
                onChange={(t) => {
                  setTimeout(() => setIgnoredWarning(false), 100);
                  onPageTabChange(t);
                }}
                value={pageTab}
              />
              <div className={styles.error}>{errStr}</div>
              <AnimationCrossfade>
                {pageTab !== 'participants' ? (
                  <TournamentSettings
                    className={cx({
                      [styles.submitting]: isSubmitting,
                    })}
                    key="settings"
                    onUploadRules={onUploadRules}
                    onUploadLogo={onUploadLogo}
                  />
                ) : (
                  participants?.('participants', isTeam, clockControl)
                )}
              </AnimationCrossfade>
            </div>

            <BookmarkButtonBar>
              <BookmarkButton
                icon={<SaveIcon />}
                label="Сохранить"
                onClick={handleSubmit}
                disabled={disabled || isSubmitting}
              />
              <BookmarkButton
                icon={<DeleteIcon />}
                label="Удалить"
                clickedStateBottom={
                  <div className={styles.clickable} onClick={onDelete}>
                    Да, удалить
                  </div>
                }
                clickedStateTop={
                  <div>Удалить {isMatch ? 'матч' : 'турнир'}</div>
                }
                disabled={disabled || isSubmitting}
              />
            </BookmarkButtonBar>
          </form>

          {/* Предупреждение о несохранённых изменениях */}
          <AnimatedNode>
            {unsavedBlock.status === 'blocked' && (
              <UnsavedChangesModal
                onProceed={unsavedBlock.proceed}
                onStay={unsavedBlock.reset}
              />
            )}
          </AnimatedNode>

          {/* Предупреждение о переходе на другой таб */}
          <AnimatedNode>
            {unsavedWarningCondition &&
              !ignoredWarning &&
              pageTab === 'participants' && (
                <UnsavedChangesModal
                  showSave
                  hideProceed
                  pending={isSubmitting}
                  labelSay="Игнорировать"
                  onSave={handleSave}
                  onStay={() => setIgnoredWarning(true)}
                >
                  <h1>Сохранить настройки?</h1>
                  <p>
                    Рекомендуется сохранить изменения настроек перед
                    редактированием участников
                  </p>
                </UnsavedChangesModal>
              )}
          </AnimatedNode>
        </FormProvider>
      )}
    </>
  );
}

export function TournamentEditPageSkeleton() {
  return (
    <>
      <TournamentSubHeader title="" />
      <Loader className={styles.loader} centered />
    </>
  );
}
