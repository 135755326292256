import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from '@features/auth/hooks/useAuth';
import { useNextRoundsQuery, useUserSocket } from '@features/shared/api';
import { Subscription } from '@libs/subscription';
import { useMatch, useRouter } from '@tanstack/react-router';

const SESSION_STORAGE_KEY = 'ignored_game_redirects';

/*
    ____,-------------------------------,____
    \   |              Hooks            |   /
    /___|-------------------------------|___\
*/

export type NextGame = {
  clubId: string;
  tournamentId: string;
  gameId: string;
  startTimeMs: number;
};

let globalQueue: Record<string, NextGame> = {};
const globalSub = new Subscription<NextGame[]>();
const globalIgnored = new Set<string>(getCrossTabsIgnores());
const globalIgnoredChannel = new BroadcastChannel('ingoned_conflicting_games');

globalIgnoredChannel.addEventListener('message', (e) => {
  const gameId = e.data as string;
  unqueueGame(gameId);
});

// ------------------------------------------
// Авторедирект

export function useAutoGameRedirect(): void {
  const { isAuthenticated } = useAuth();
  const router = useRouter();
  const [connected, setConnected] = useState(false);

  const _gameRouteMatch =
    useMatch({
      from: '/_authenticated/club/$clubId_/tournament/$tournamentId_/game/$gameId',
      shouldThrow: false,
    })?.params ?? null;
  const refGameRouteMatch = useRef(_gameRouteMatch);
  refGameRouteMatch.current = _gameRouteMatch;

  const _seanceRouteMatch =
    useMatch({
      from: '/_authenticated/club/$clubId_/tournament/$tournamentId_/seance',
      shouldThrow: false,
    })?.params ?? null;
  const refSeanceRouteMatch = useRef(_seanceRouteMatch);
  refSeanceRouteMatch.current = _seanceRouteMatch;

  const rounds = useNextRoundsQuery({
    enabled: isAuthenticated,
    // пока сокет мёртв — полагаемся на поллинг
    refetchInterval: connected ? undefined : 30 * 1000,
  });

  // Проверяем активные игры на старте приложения и при реконнекте сокета
  useEffect(() => {
    if (!rounds.data) return;

    globalQueue = {};

    const nextSeances = rounds.data.filter((r) => r.games.length > 1);
    // FIXME: обработать сеансы как следует, сейчас просто редиректим на сеанс
    if (nextSeances.length) {
      const { clubId, tournamentId } = nextSeances[0]!.games[0]!;
      redirectToSeance(router, { clubId, tournamentId });
      return;
    }

    const nextGames = rounds.data
      .filter((r) => r.games.length === 1)
      .flatMap((r) => r.games)
      .filter((g) => g.status !== 'Unknown')
      .map((ready) => ({
        clubId: ready.clubId,
        tournamentId: ready.tournamentId,
        gameId: ready.gameId,
        startTimeMs: ready.startTimestampMs,
      }));

    handleActiveGames(router, refGameRouteMatch.current, nextGames);
  }, [rounds.data, router]);

  // Обрабатываем сокет
  useUserSocket((e) => {
    switch (e.eventType) {
      case 'GameStartsSoon':
      case 'GameStarted': {
        const nextGame = {
          clubId: e.nextGame.clubId,
          tournamentId: e.nextGame.tournamentId,
          gameId: e.nextGame.gameId,
          startTimeMs: e.nextGame.gameStartTimestampMs,
        };

        // FIXME: обработать сеансы корректно (сейчас не даём редиректить с сеанса)
        if (refSeanceRouteMatch.current) return;

        handleActiveGames(router, refGameRouteMatch.current, [nextGame]);
        break;
      }
      case 'SeanceStartsSoon':
      case 'SeanceStarted':
        // FIXME: интегрировать в очередь конфликтов
        if (refSeanceRouteMatch.current) return;
        redirectToSeance(router, {
          clubId: e.clubId,
          tournamentId: e.tournamentId,
        });
        break;
      case 'GameFinished': {
        delete globalQueue[e.gameId];
        globalIgnored.delete(e.gameId);
        globalSub.publish(Object.values(globalQueue));
        break;
      }
      case 'SocketConnected':
        setConnected(true);
        break;
      case 'SocketReconnected':
        rounds.refetch();
        setConnected(true);
        break;
      case 'SocketReconnecting':
        setConnected(false);
        break;
    }
  });
}

// ------------------------------------------
// Очередь редиректов

export function useGameRedirectsQueue() {
  const [queue, setQueue] = useState(() => Object.values(globalQueue));
  const router = useRouter();

  useEffect(() => {
    return globalSub.subscribe(setQueue);
  }, []);

  return {
    queue: queue.length ? queue : null,
    open: useCallback(
      (g: NextGame, currentGameId?: string) => {
        redirectToGame(router, g, { inNewWindow: true, currentGameId });
        unqueueGame(g.gameId);
        // глобально заглушаем предупреждения об игре
        globalIgnoredChannel.postMessage(g.gameId);
      },
      [router],
    ),
    remove: useCallback((g: NextGame) => {
      unqueueGame(g.gameId);
      // глобально заглушаем предупреждения об игре
      globalIgnoredChannel.postMessage(g.gameId);
    }, []),
  };
}

/*
    ____,-------------------------------,____
    \   |            Helpers            |   /
    /___|-------------------------------|___\
*/

function handleActiveGames(
  router: ReturnType<typeof useRouter>,
  routeMatch: { tournamentId: string; gameId: string } | null,
  nextGames: NextGame[],
) {
  const { tournamentId, gameId } = routeMatch ?? {};
  const games = nextGames.filter((g) => g.gameId !== gameId);

  if (!games.length) return;

  const index = !gameId
    ? 0
    : games.findIndex((g) => g.tournamentId === tournamentId);

  const game = index !== -1 ? games.splice(index, 1)[0] : null;

  if (game) {
    redirectToGame(router, game);
    // глобапльно заглушаем предупреждения об игре, но только если пользователь
    // видел, что редирект произошёл
    if (!document.hidden) globalIgnoredChannel.postMessage(game.gameId);
  }

  // публикуем предупреждения для оставшихся игр
  const filtered = games.filter((g) => !globalIgnored.has(g.gameId));
  if (filtered.length) {
    filtered.forEach((g) => {
      globalQueue[g.gameId] = g;
    });
    globalSub.publish(Object.values(globalQueue));
  }
}

function redirectToGame(
  router: ReturnType<typeof useRouter>,
  { clubId, tournamentId, gameId }: NextGame,
  {
    inNewWindow = false,
    currentGameId,
  }: {
    inNewWindow?: boolean;
    currentGameId?: string;
  } = {},
) {
  const args = {
    to: '/club/$clubId/tournament/$tournamentId/game/$gameId',
    params: { clubId, tournamentId, gameId },
  };

  if (inNewWindow) {
    const ignores = new Set(globalIgnored);
    // добавляем текущую игру, на случай если она не попала в игнорируемые
    // (например, редирект произшёл пока вкладка была в фоне)
    if (currentGameId) {
      ignores.add(currentGameId);
    }

    // временно сохраняем игноры в сессию, чтобы новое окно смогло их подхватить
    setCrossTabsIgnores(Array.from(ignores));

    const loc = router.buildLocation(args);
    window.open(loc.href);

    // удаляем сессию текущего окна (sic! каждая вкладка имеет независимую копию
    // sessionStorage)
    removeCrossTabsIgnores();
  } else {
    router.navigate(args);
  }
}

function redirectToSeance(
  router: ReturnType<typeof useRouter>,
  params: { clubId: string; tournamentId: string },
) {
  router.navigate({
    to: '/club/$clubId/tournament/$tournamentId/seance',
    params,
  });
}

function unqueueGame(gameId: string) {
  delete globalQueue[gameId];
  globalIgnored.add(gameId);
  globalSub.publish(Object.values(globalQueue));
}

/*
    ____,-------------------------------,____
    \   |          Cross Tabs           |   /
    /___|-------------------------------|___\
*/

function getCrossTabsIgnores(): string[] | null {
  const data = sessionStorage.getItem(SESSION_STORAGE_KEY);
  if (data) return JSON.parse(data);
  return null;
}

function setCrossTabsIgnores(ignores: string[]): void {
  sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(ignores));
}

function removeCrossTabsIgnores() {
  sessionStorage.removeItem(SESSION_STORAGE_KEY);
}
