import {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Device, MediaQueryContext } from './MediaQueryContext';

const MEDIA_QUERIES: Record<Device, string> = {
  desktop: '(min-width: 1024px)',
  tablet: '(max-width: 1023px) and (min-width: 500px)',
  mobile: '(max-width: 499px)',
};

export function MediaQueryProvider({
  children,
  mediaQueries = MEDIA_QUERIES,
}: {
  children: ReactNode;
  /** ВАЖНО: media queries должны быть взаимоисключающими */
  mediaQueries?: Record<Device, string>;
}) {
  const medias = useMemo<Array<{ type: Device; query: MediaQueryList }>>(
    () =>
      Object.keys(mediaQueries).map((key) => {
        const device = key as Device;
        return {
          type: device as Device,
          query: matchMedia(mediaQueries[device]),
        };
      }),
    [mediaQueries],
  );

  const matchDevice = useCallback(
    () => medias.find((m) => m.query.matches)?.type ?? 'desktop',
    [medias],
  );

  const [device, setDevice] = useState<Device>(matchDevice);

  // отслеживание изменений
  useLayoutEffect(() => {
    // обновляем только если есть на что
    const update = (e: MediaQueryListEvent) => {
      if (e.matches) {
        setDevice(matchDevice());
      }
    };

    medias.forEach((m) => {
      m.query.addEventListener('change', update);
    });

    return () => {
      medias.forEach((m) => {
        m.query.removeEventListener('change', update);
      });
    };
  }, [matchDevice, medias]);

  // применение изменений
  useLayoutEffect(() => {
    document.body.classList.remove(...Object.keys(MEDIA_QUERIES));
    document.body.classList.add(device);
  }, [device]);

  return (
    <MediaQueryContext.Provider value={device}>
      {children}
    </MediaQueryContext.Provider>
  );
}
