import { SeancePageWidget } from '@features/game/pages/SeancePage';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_authenticated/club/$clubId_/tournament/$tournamentId_/seance',
)({
  component: Page,
});

function Page() {
  const { clubId, tournamentId } = Route.useParams();
  return (
    <SeancePageWidget
      key={tournamentId}
      clubId={clubId}
      tournamentId={tournamentId}
    />
  );
}
