import { cx } from '@libs/classnames';
import { useIsRendered } from '@libs/hooks/useIsRendered';
import { AnimatedNode } from '@ui/components/Animation';
import { TablePreview, TablePreviewProps } from '../TablePreview';
import { TablePreviewSkeleton } from '../TablePreview/TablePreview';
import styles from './SeanceQueue.module.css';

export type Props = {
  className?: string;
  games?: TablePreviewProps['game'][];
  selectedGameId?: string;
  seancerUserId: string;
  timePressureOpponentSec: number;
  timePressureSeancerSec: number;
  onSelectGame?: (tableId: string) => void;
};

export function SeanceQueue({
  className,
  games,
  selectedGameId,
  seancerUserId,
  timePressureOpponentSec,
  timePressureSeancerSec,
  onSelectGame,
}: Props) {
  const refMounted = useIsRendered();
  return (
    <div className={cx(styles.queue, className)}>
      {games?.map((g) => (
        <AnimatedNode
          key={g.id}
          initial={refMounted.current}
          animation="opacity"
        >
          <TablePreview
            key={g.id}
            game={g}
            selected={g.id === selectedGameId}
            orientation={g.black.id === seancerUserId ? 'black' : 'white'}
            timePressureOpponentSec={timePressureOpponentSec}
            timePressureSeancerSec={timePressureSeancerSec}
            onClick={onSelectGame}
          />
        </AnimatedNode>
      ))}
    </div>
  );
}

export function SeanceQueueSkeleton({
  className,
  count = 1,
}: {
  className?: string;
  count?: number;
}) {
  return (
    <div className={cx(styles.queue, className)}>
      {new Array(count).fill(null).map((_, i) => (
        <TablePreviewSkeleton key={i} />
      ))}
    </div>
  );
}
