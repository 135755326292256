import { cx } from '@libs/classnames';
import { ReactNode } from '@tanstack/react-router';
import styles from './GoldenModal.module.css';

type Props = {
  className?: string;
  children: ReactNode;
  horizontal?: boolean;
  slim?: boolean;
};

export function GoldenModal({ className, children, horizontal, slim }: Props) {
  return (
    <section
      className={cx(styles.modal, className, {
        [styles.is_vertical]: !horizontal,
        [styles.is_horizontal]: horizontal,
        [styles.is_slim]: slim,
      })}
    >
      {children}
    </section>
  );
}
