import { ReactNode, useCallback, useMemo, useState } from 'react';
import { ITranslateContext, Lang, TranslateContext } from './TranslateContext';
import { EN } from './en';
import { FR } from './fr';
import { RU } from './ru';

const STORAGE_KEY = 'lang';
const RESOURCES = {
  ru: RU,
  en: EN,
  fr: FR,
};

export function TranslateProvider({ children }: { children: ReactNode }) {
  const [lang, setLang] = useState(
    () => (localStorage.getItem(STORAGE_KEY) ?? 'ru') as Lang,
  );

  const onChange = useCallback((l: Lang) => {
    localStorage.setItem('lang', l);
    setLang(l);
  }, []);

  const value = useMemo<ITranslateContext>(() => {
    const res = RESOURCES[lang];
    return { lang, change: onChange, t: (k) => res[k] ?? RU[k] };
  }, [lang, onChange]);

  return (
    <TranslateContext.Provider value={value}>
      {children}
    </TranslateContext.Provider>
  );
}
