import { useState } from 'react';
import { SocketInternalEvents } from '@libs/socket';
import { Subscription } from '@libs/subscription';
import { GameSocketEvents, useGameSocket } from '../api';

type IGameSubscription = {
  subscribe: (
    cb: (e: GameSocketEvents | SocketInternalEvents) => void,
  ) => () => void;
};

export function useGameSubscription({
  gameId,
  customSocketUrl,
  enabled = true,
}: {
  gameId: string;
  customSocketUrl?: string;
  enabled?: boolean;
}): IGameSubscription {
  const [socketSubscription] = useState(
    () => new Subscription<GameSocketEvents | SocketInternalEvents>(),
  );
  useGameSocket({
    gameId,
    customSocketUrl,
    enabled,
    cb: (e) => {
      socketSubscription.publish(e);
    },
  });

  return socketSubscription;
}
