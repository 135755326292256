import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import { useMediaQuery } from '@ui/components/MediaQueryProvider';
import { PageTitle } from '@ui/components/PageTitle';
import { TextView } from '@ui/components/TextView';
import { SlimArrowButton } from 'ui/components/SlimArrowButton';
import styles from './TournamentSubHeader.module.css';

export type Props = {
  className?: string;
  title: ReactNode;
  description?: ReactNode;
  info?: ReactNode;
  logo?: ReactNode;
  onBack?: () => void;
};
export function TournamentSubHeader({
  className,
  title,
  description,
  info,
  logo,
  onBack,
}: Props) {
  const isMobile = useMediaQuery() !== 'desktop';
  return (
    <div className={cx(className, styles.subHeader)}>
      <div className={cx(styles.title, { [styles.wideTitle]: !info })}>
        <PageTitle>
          {onBack && <SlimArrowButton direction="left" onClick={onBack} />}
          {title}
        </PageTitle>
        {description && <TextView>{description}</TextView>}
      </div>
      {info && <div className={styles.info}>{info}</div>}
      {!isMobile && <div className={styles.clubLogo}>{logo}</div>}
    </div>
  );
}
