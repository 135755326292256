import { ChessBoard, ChessBoardProps } from 'exports/ChessBoard';
import {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuth } from '@features/auth/hooks/useAuth';
import { useResignMutation, useRoundSocket } from '@features/game/api';
import { components } from '@features/game/api/generated';
import { useDrawOffer } from '@features/game/hooks/useDrawOffer';
import { useGameServer } from '@features/game/hooks/useGameServer';
import { useScoresheet } from '@features/game/hooks/useScoresheet';
import { useSeanceGameSubscription } from '@features/game/hooks/useSeanceGameSubscription';
import { MatchCountdownWidget } from '@features/game/ui/MatchCountdown';
import { NetworkProblems } from '@features/game/ui/NetworkProblems/NetworkProblems';
import { useGameAuthQuery, useNextRoundsQuery } from '@features/shared/api';
import { useTournamentCardQuery } from '@features/tournament/api/hooks';
import { useUserSettingsMenu } from '@features/user/hooks/useUserSettingsMenu';
import { UserWidget } from '@features/user/ui/User';
import { sortBy } from '@libs/array';
import { usePrev } from '@libs/hooks/usePrev';
import { useNavigate } from '@tanstack/react-router';
import { AnimatedNode } from '@ui/components/Animation';
import { SeancePage, SeancePageSkeleton } from './SeancePage';
import { ResultModal } from './components/ResultModal';
import { SeanceQueue } from './components/SeanceQueue';
import { TableFooter } from './components/TableFooter';
import { TableHeader } from './components/TableHeader';
import { TableScoresheet } from './components/TableScoresheet';

/*
    ____,-------------------------------,____
    \   |            Widget             |   /
    /___|-------------------------------|___\
*/

const TIME_PRESSURE_OPPONENT_SEC = 10;
const TIME_PRESSURE_SEANCER_SEC = 2 * 60;
const TIME_BEFORE_AUTOSWITCH_MS = 0;
const TIME_SHOW_FRACTIONS = 10;

export function SeancePageWidget({
  clubId,
  tournamentId,
}: {
  clubId: string;
  tournamentId: string;
}) {
  const [registry, setRegistry] = useState<
    Record<string, components['schemas']['GameDto']>
  >({});

  const { data: tournament } = useTournamentCardQuery(clubId, tournamentId, {
    staleTime: Infinity,
  });
  const [isSeanceFinished, setIsSeanceFinished] = useState(false);

  const { user } = useAuth();
  const playerId = user?.userId;
  const seancerUserId = tournament?.seanceSettings?.seancer?.userId;
  const allowSeancePage = Boolean(
    !tournament ||
      (tournament.system === 'Seance' &&
        tournament.rounds.length &&
        seancerUserId &&
        user?.userId === seancerUserId),
  );
  const accessKey = useAccessKey(tournamentId);

  // Игры
  //
  const { gameCurrent, gamesAll, gamesInQueue, onSelectGame } = useGames(
    seancerUserId,
    registry,
  );
  const gameId = gameCurrent?.id;
  const gamePrev = usePrev(gameCurrent);

  // Результат
  //
  const results = useMemo(() => {
    if (!isSeanceFinished) return undefined;
    let seancer = 0;
    let opponents = 0;

    gamesAll.forEach((g) => {
      switch (g.result) {
        case 'Draw':
          seancer += 0.5;
          opponents += 0.5;
          break;
        case 'BlackWin':
          seancer += g.black.id === seancerUserId ? 1 : 0;
          opponents += g.black.id === seancerUserId ? 0 : 1;
          break;
        case 'WhiteWin':
          seancer += g.white.id === seancerUserId ? 1 : 0;
          opponents += g.white.id === seancerUserId ? 0 : 1;
          break;
      }
    });

    return { seancer, opponents };
  }, [gamesAll, isSeanceFinished, seancerUserId]);

  // Scoresheet
  //
  const {
    props: scoresheetProps,
    propsPatch: scoresheetBoardPatch,
    playersTimeMs: scoresheetPlayersTime,
    isLastMove: scoresheetIsLastMove,
    reset: resetScoresheet,
  } = useScoresheet(gameCurrent, undefined);
  const [showScoresheet, setShowScoresheet] = useState(false);
  const onToggleNotation = useCallback(() => {
    resetScoresheet();
    setShowScoresheet((o) => !o);
  }, [resetScoresheet]);
  const customPlayersTimeMs =
    showScoresheet &&
    (!scoresheetIsLastMove || gameCurrent?.status === 'Finished')
      ? scoresheetPlayersTime
      : undefined;

  // Сокет
  //
  useRoundSocket({
    tournamentId,
    roundNumber: 1,
    cb: (e, { games, isFinished }) => {
      switch (e.eventType) {
        case 'RoundGamesState':
          setRegistry({ ...games });
          setIsSeanceFinished(isFinished);
          break;
        case 'GameChanged':
        case 'Surrender':
        case 'DrawRequest':
        case 'DrawAccept':
        case 'DrawDecline':
          setRegistry({ ...games });
          setIsSeanceFinished(isFinished);
          break;
      }
    },
    enabled: allowSeancePage,
  });

  // Обработка ошибок
  //
  const [error, setError] = useState<ReactNode>(null);
  const onError = useCallback((error: Error) => {
    setError(
      <>
        <b>Ошибка</b>. При выполнении запроса что-то пошло не так. Повторите
        попытку позже
      </>,
    );
    console.error(error);
  }, []);
  const onClearError = useCallback(() => setError(null), []);

  // Ничья
  //
  const {
    showDrawOffer,
    submittingDrawOffer,
    onDrawAccept,
    onDrawDecline,
    onDrawOffer,
  } = useDrawOffer({
    game: gameCurrent,
    playerId: seancerUserId,
    onError,
  });

  // Сдаться
  //
  const resignMutation = useResignMutation();
  const handleResign = useCallback(() => {
    resignMutation.mutate(gameId!, { onError });
  }, [gameId, onError, resignMutation]);

  // Выход
  //
  const navigate = useNavigate();
  const handleLeave = useCallback(() => {
    navigate({
      to: '/club/$clubId/tournament/$tournamentId',
      params: { clubId, tournamentId },
    });
  }, [clubId, navigate, tournamentId]);

  // Редирект для "неправильных" пользователей
  //
  useEffect(() => {
    if (!allowSeancePage) handleLeave();
  }, [handleLeave, allowSeancePage]);

  if (!gameCurrent || !seancerUserId) return <SeancePageSkeleton />;

  return (
    <SeancePage
      avatar={<UserWidget />}
      header={
        <TableHeader
          game={gameCurrent}
          seancerUserId={seancerUserId}
          timePressureSec={TIME_PRESSURE_OPPONENT_SEC}
          fractionsFromSec={TIME_SHOW_FRACTIONS}
          showDrawOffer={showDrawOffer}
          customPlayersTimeMs={customPlayersTimeMs}
          onDrawAccept={onDrawAccept}
          onDrawDecline={onDrawDecline}
        />
      }
      board={
        <AnimatedNode
          key={gameCurrent.id}
          animation="change-table"
          // анимируем только при переключении (а не при первой загрузке)
          initial={!!gamePrev}
        >
          <SeancerBoard
            key={gameCurrent.id}
            tournamentId={tournamentId}
            gameId={gameCurrent.id}
            playerId={playerId}
            accessKey={accessKey}
            scoresheetPatch={showScoresheet ? scoresheetBoardPatch : undefined}
            scoresheetIsLastMove={scoresheetIsLastMove}
            scoresheetSelectedMove={scoresheetProps.selected}
            onError={() => {}}
          />
        </AnimatedNode>
      }
      footer={
        <TableFooter
          game={gameCurrent}
          seancerUserId={seancerUserId}
          timePressureSec={TIME_PRESSURE_SEANCER_SEC}
          fractionsFromSec={TIME_SHOW_FRACTIONS}
          notationIsOpen={showScoresheet}
          submittingDraw={submittingDrawOffer}
          submittingResign={resignMutation.isPending}
          customPlayersTimeMs={customPlayersTimeMs}
          error={error}
          onNotation={onToggleNotation}
          onLeave={handleLeave}
          onDraw={onDrawOffer}
          onResign={handleResign}
          onClearError={onClearError}
        />
      }
      showScoresheet={showScoresheet}
      scoresheet={
        <AnimatedNode animation="opacity" initial>
          <TableScoresheet props={scoresheetProps} />
        </AnimatedNode>
      }
      queue={
        gamesInQueue.length ? (
          <SeanceQueue
            seancerUserId={seancerUserId}
            games={gamesInQueue}
            selectedGameId={gameCurrent.id}
            timePressureOpponentSec={TIME_PRESSURE_OPPONENT_SEC}
            timePressureSeancerSec={TIME_PRESSURE_SEANCER_SEC}
            onSelectGame={onSelectGame}
          />
        ) : null
      }
      tables={
        <SeanceQueue
          seancerUserId={seancerUserId}
          games={gamesAll}
          selectedGameId={gameCurrent.id}
          timePressureOpponentSec={TIME_PRESSURE_OPPONENT_SEC}
          timePressureSeancerSec={TIME_PRESSURE_SEANCER_SEC}
          onSelectGame={isSeanceFinished ? onSelectGame : undefined}
        />
      }
      results={results}
    />
  );
}

/*
    ____,-------------------------------,____
    \   |           Запчасти            |   /
    /___|-------------------------------|___\
*/

const SeancerBoard = memo(function SeancerBoard({
  tournamentId,
  gameId,
  playerId,
  accessKey,
  scoresheetPatch,
  scoresheetIsLastMove,
  scoresheetSelectedMove,
  onError,
}: {
  tournamentId: string;
  gameId: string;
  playerId?: string;
  accessKey?: string;
  scoresheetPatch?: Partial<ChessBoardProps>;
  scoresheetIsLastMove?: boolean;
  scoresheetSelectedMove?: components['schemas']['MoveDto'];
  onError: (err: Error) => void;
}) {
  const subscription = useSeanceGameSubscription({ tournamentId, gameId });
  const settingsMenuProps = useUserSettingsMenu();

  const { game, propsBoard, networkPromlems } = useGameServer({
    gameId,
    playerId,
    accessKey,
    autoPromotion: settingsMenuProps.autoQueen ? 'q' : undefined,
    disablePremove: true,
    subscription,
    onError,
  });

  const modal = (() => {
    if (networkPromlems) return <NetworkProblems />;
    switch (game?.result) {
      case 'Draw':
      case 'BlackWin':
      case 'WhiteWin':
        if (scoresheetPatch && !scoresheetIsLastMove) {
          // Не показываем модалку в истории ходов
          return;
        }
        return <ResultModal result={game.result} />;
    }
    if (networkPromlems) return <NetworkProblems />;
    if (game?.result === 'NotStarted') {
      return <MatchCountdownWidget game={game} />;
    }
  })();

  // Анимации и нотация (доска не должна анимироваться при переключении нотации
  // и при закрытии панели)
  const prevScoresheetPatch = usePrev(scoresheetPatch);
  const isScoresheetReset = prevScoresheetPatch && !scoresheetPatch;
  const noAnimation = Boolean(
    isScoresheetReset ||
      // HACK: портировано из useScoresheet, чтобы не изобретать как прокинуть
      // props борды обратно в нотацию
      (scoresheetSelectedMove &&
        (scoresheetIsLastMove
          ? scoresheetSelectedMove.from === propsBoard.selectedFrom
          : true)),
  );

  return (
    <ChessBoard
      {...propsBoard}
      {...scoresheetPatch}
      noAnimation={noAnimation}
      modal={modal}
    />
  );
});

/*
    ____,-------------------------------,____
    \   |             Хуки              |   /
    /___|-------------------------------|___\
*/

function useAccessKey(tournamentId: string): string | undefined {
  // TODO: удалить, когда перейдём на общий токен
  const nextRounds = useNextRoundsQuery();
  const authGame = nextRounds.data?.find((r) => r.tournamentId === tournamentId)
    ?.games[0];
  const { data } = useGameAuthQuery(authGame?.gameId ?? '', {
    enabled: !!authGame,
  });

  return data?.gameAccessToken;
}

function useGames(
  seancerUserId: string | undefined,
  registry: Record<string, components['schemas']['GameDto']>,
) {
  const gamesAll = useMemo(() => {
    const gs = Object.values(registry);
    sortBy(gs, (g) => g.boardNumber ?? 0);
    return gs;
  }, [registry]);
  const gamesInQueue = useMemo(() => {
    const r = gamesAll.filter((g) => {
      // в очередь попадают только ходы сеансера и предложения ничьих от оппонентов
      const isTurn = isSeancerTurn(seancerUserId, g);
      const hasOffer = hasOpponentDrawOffer(seancerUserId, g);
      return (
        (isTurn || hasOffer) &&
        (g.status === 'NotStarted' || g.status === 'InProgress')
      );
    });
    //
    sortBy(r, (g) => {
      // сортируем по времени хода или предложения ничьей, если ходов нет —
      // оставляем сортировку по столу (см gamesAll)
      const hasOffer = hasOpponentDrawOffer(seancerUserId, g);
      const moveTimestamp = g.moves[g.moves.length - 1]?.timestampMs ?? 0;
      return hasOffer ? Math.min(g.timestampMs, moveTimestamp) : moveTimestamp;
    });
    //
    return r;
  }, [gamesAll, seancerUserId]);

  const [gameId, setGameId] = useState(gamesInQueue[0]?.id);
  const gameCurrent = gameId ? registry[gameId] : undefined;
  const isCurrentGameInQueue = !!gamesInQueue.find((g) => g.id === gameId);

  const gameNext =
    gamesInQueue[0] ??
    gameCurrent ??
    // либо первый стол, либо игра с последней активностью
    gamesAll.reduce((max, g) => {
      if (!max) return g;
      if (!g.moves.length) return max;
      return max.timestampMs < g.timestampMs ? g : max;
    }, gamesAll[0]);

  // Переключение с задержкой
  const nextId = gameNext?.id;
  const nextStatus = gameNext?.status;
  useEffect(() => {
    const to = setTimeout(() => {
      if (!nextId) return;
      setGameId((id) => (nextStatus === 'Finished' && id ? id : nextId));
    }, TIME_BEFORE_AUTOSWITCH_MS);
    return () => clearTimeout(to);
  }, [
    nextId,
    nextStatus,
    // ВАЖНО: без этой зависимости ходы вне очереди (при ручном переключении
    // досок) не будут исчезать с главного стола
    isCurrentGameInQueue,
  ]);

  const onSelectGame = useCallback((id: string) => {
    setGameId(id);
  }, []);

  return {
    gameCurrent,
    gamesInQueue,
    gamesAll,
    onSelectGame,
  };
}

/*
    ____,-------------------------------,____
    \   |            Утилиты            |   /
    /___|-------------------------------|___\
*/

function isSeancerTurn(
  seancerId: string | undefined,
  g: components['schemas']['GameDto'],
): boolean {
  const isWhite = g.white.id === seancerId;
  const isWhiteMove = g.nextMoveBy === 'White';
  return isWhite ? isWhiteMove : !isWhiteMove;
}

function hasOpponentDrawOffer(
  playerId: string | undefined,
  g: components['schemas']['GameDto'],
): boolean {
  if (!g.activeDrawRequest) return false;
  const isWhite = g.white.id === playerId;
  const isWhiteOffer = g.activeDrawRequest.triggeredBySide === 'White';
  return isWhite ? !isWhiteOffer : isWhiteOffer;
}
