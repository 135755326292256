import { cx } from '@libs/classnames';
import { Timer } from '@ui/components/Timer';
import { useTranslate } from '@ui/components/TranslateProvider';
import styles from './MatchCountdown.module.css';

export type Props = {
  className?: string;
  seconds: number;
};

export function MatchCountdown({ className, seconds }: Props) {
  const { t } = useTranslate();
  return (
    <section className={cx(styles.matchCountdown, className)}>
      <h2 className={styles.header}>
        {t('features/game/ui/MatchCountdown/Start in')}
      </h2>
      <Timer className={styles.timer} seconds={seconds} />
    </section>
  );
}
