import { useEffect, useRef, useState } from 'react';
import { PieceNameLower, SquareName } from '@libs/chess/types';
import { SocketInternalEvents } from '@libs/socket';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  components,
  GameSocket,
  GET,
  POST,
  GameSocketEvents,
  RoundSocketEvents,
  RoundSocket,
} from './clients';

/*
    ____,-------------------------------,____
    \   |             REST              |   /
    /___|-------------------------------|___\
*/

export function useGameQuery(id: string, { enabled } = { enabled: true }) {
  return useQuery({
    enabled,
    refetchOnWindowFocus: false,
    queryKey: getGameQueryKey(id),
    queryFn: async () => {
      return (
        await GET('/api/v1/games/{gameId}', {
          params: { path: { gameId: id } },
        })
      ).data!;
    },
  });
}

export function getGameQueryKey(id: string) {
  return ['/api/v1/games/{gameId}', id];
}

export function useGameMoveMutation() {
  return useMutation({
    mutationFn: async ({
      id,
      move,
    }: {
      id: string;
      move: { from: SquareName; to: SquareName; promoteTo?: PieceNameLower };
    }) => {
      return (
        await POST('/api/v1/games/{gameId}/move', {
          params: { path: { gameId: id } },
          body: move,
        })
      ).data!;
    },
  });
}

export function useOfferDrawMutation() {
  return useMutation({
    mutationFn: async (gameId: string) => {
      return (
        await POST('/api/v1/games/{gameId}/request-draw', {
          params: { path: { gameId } },
        })
      ).data!;
    },
  });
}

export function useAcceptDrawMutation() {
  return useMutation({
    mutationFn: async (gameId: string) => {
      return (
        await POST('/api/v1/games/{gameId}/accept-draw', {
          params: { path: { gameId } },
        })
      ).data!;
    },
  });
}

export function useDeclineDrawMutation() {
  return useMutation({
    mutationFn: async (gameId: string) => {
      return (
        await POST('/api/v1/games/{gameId}/decline-draw', {
          params: { path: { gameId } },
        })
      ).data!;
    },
  });
}

export function useResignMutation() {
  return useMutation({
    mutationFn: async (gameId: string) => {
      return (
        await POST('/api/v1/games/{gameId}/surrender', {
          params: { path: { gameId } },
        })
      ).data!;
    },
  });
}

export function useNewGameMutation() {
  return useMutation({
    mutationFn: async () => {
      return (await POST('/api/v1/test/games')).data!;
    },
  });
}

export function useGameAccessKeyQuery(
  gameId: string,
  playerId: string,
  { enabled } = { enabled: true },
) {
  return useQuery({
    enabled,
    refetchOnWindowFocus: false,
    queryKey: ['/admin/game-access-tokens', gameId, playerId],
    queryFn: async () => {
      return (
        await POST('/api/v1/admin/game-access-tokens', {
          headers: { key: 'test' },
          body: { gameId, playerId },
        })
      ).data!;
    },
  });
}

/*
    ____,-------------------------------,____
    \   |             Socket            |   /
    /___|-------------------------------|___\
*/

// ------------------------------------------
// Game

export function useGameSocket({
  gameId,
  customSocketUrl,
  enabled = true,
  cb,
}: {
  gameId: string;
  customSocketUrl?: string;
  enabled?: boolean;
  cb: (e: GameSocketEvents | SocketInternalEvents) => void;
}) {
  const refCb = useRef(cb);
  refCb.current = cb;

  const createSocket = () => {
    if (!enabled) return null;

    const s = new GameSocket(gameId, customSocketUrl);
    s.subscribe((e) => {
      refCb.current(e);

      // отключить сокет, если игра закончена
      if (
        e.eventType === 'GameChanged' &&
        e.currentState.status === 'Finished'
      ) {
        s.discard();
      }
    });

    return s;
  };

  // FIXME: избавиться от синхронного setState
  const [socket, setSocket] = useState(createSocket);
  if (enabled && (!socket || socket?.gameId !== gameId)) {
    socket?.discard();
    setSocket(createSocket());
  } else if (!enabled && socket) {
    socket.discard();
    setSocket(null);
  }

  useEffect(
    () => () => {
      if (socket) socket.discard();
    },
    [socket],
  );
}

// ------------------------------------------
// Round

type RoundWsContext = {
  games?: Record<string, components['schemas']['GameDto']>;
  isFinished: boolean;
};

const globalRoundWs: Record<string, TournamentGamesSotre> = {};

export function useRoundSocket({
  tournamentId,
  roundNumber,
  cb,
  customSocketUrl,
  enabled = true,
}: {
  tournamentId: string;
  roundNumber: number;
  cb: (
    e: RoundSocketEvents | SocketInternalEvents,
    ctx: RoundWsContext,
  ) => void;
  customSocketUrl?: string;
  enabled?: boolean;
}) {
  const queryClient = useQueryClient();

  const ref = useRef(cb);
  ref.current = cb;

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const key = `${tournamentId}-|-${roundNumber}-|-${customSocketUrl}`;

    if (!globalRoundWs[key]) {
      globalRoundWs[key] = new TournamentGamesSotre(
        new RoundSocket(tournamentId, roundNumber, customSocketUrl),
        queryClient,
      );
    }

    const store = globalRoundWs[key];
    const unsubscribe = store.ws.subscribe((e) => {
      const ctx = { games: store.games, isFinished: !store.isLive };
      ref.current(e, ctx);
    });

    // отправляем снэпшот
    const snapshot = store.snapshot;
    if (snapshot) {
      const ctx = { games: store.games, isFinished: !store.isLive };
      ref.current(snapshot, ctx);
    }

    return () => {
      unsubscribe();
      if (store.subscribers === 0) {
        store.discard();
        delete globalRoundWs[key];
      }
    };
  }, [tournamentId, roundNumber, enabled, customSocketUrl, queryClient]);
}

// Стор нужен, чтобы хранить и обновлять snapshot
class TournamentGamesSotre {
  games: Record<string, components['schemas']['GameDto']> | undefined;
  snapshot: components['schemas']['RoundGamesState'] | undefined;
  isLive: boolean = true;
  ws: RoundSocket;
  #ql: QueryClient;

  constructor(ws: RoundSocket, ql: QueryClient) {
    this.ws = ws;
    this.#ql = ql;
    ws.subscribe((e) => {
      switch (e.eventType) {
        case 'RoundGamesState':
          this.games = {};
          e.data.forEach((g) => {
            this.games![g.id] = g;
            this.#ql.setQueryData(getGameQueryKey(g.id), g);
          });
          this.#update();
          break;
        case 'GameChanged':
        case 'DrawRequest':
        case 'DrawAccept':
        case 'DrawDecline':
        case 'Surrender':
          if (this.games) {
            this.games[e.gameId] = e.currentState;
            this.#ql.setQueryData(getGameQueryKey(e.gameId), e.currentState);
          }
          this.#update();
          break;
      }

      if (!this.ws.discarded && !this.isLive) {
        // даём обработать другие подписки
        requestAnimationFrame(() => this.discard());
      }
    });
  }

  #update(): components['schemas']['RoundGamesState'] | undefined {
    if (!this.games) return;

    this.snapshot = {
      timestampMs: Date.now(),
      eventType: 'RoundGamesState',
      data: Object.values(this.games),
      roundNumber: this.ws.roundNumber.toString(),
      tournamentId: this.ws.tournamentId,
    };
    this.isLive = !!this.snapshot.data.find((g) => g.status !== 'Finished');
  }

  get subscribers(): number {
    return Math.max(this.ws.subscribers - 1, 0);
  }

  discard() {
    this.ws.discard();
  }
}
