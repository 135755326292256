import { useCallback, useRef, useState } from 'react';
import {
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { cx } from '@libs/classnames';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Input } from '@ui/components/Input';
import { ThemeButton } from '@ui/components/ThemeButton';
import { ThemeLink } from '@ui/components/ThemeLink';
import styles from './TournamentEditFormInputs.module.css';

export function ContactsInput() {
  const [newContactName, setNewContactName] = useState('');
  const [newContactLink, setNewContactLink] = useState('');
  const [newContactLabel, setNewContactLabel] = useState('');
  const nameRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

  const {
    control,
    formState: { disabled },
  } = useFormContext<TournamentEditFormValues>();
  const { fields, append, remove } = useFieldArray<TournamentEditFormValues>({
    control,
    name: 'contacts',
  });

  const onAdd = useCallback(() => {
    if (!newContactName || !newContactLabel) return;

    append({
      name: newContactName,
      label: newContactLabel,
      uri: newContactLink,
    });
    setNewContactLink('');
    setNewContactName('');
    setNewContactLabel('');
    nameRef.current?.focus();
  }, [newContactName, newContactLabel, newContactLink, append]);

  return (
    <FormRow>
      <FormLabel>Контакты</FormLabel>
      <FormInput className={styles.contactValues}>
        {/* FIXME: HACK: пришлось добавить каст fields, иначе выводтся неправильный тип внезапно
        связанный с сеансёром и его клубом */}
        {(
          fields as FieldArrayWithId<
            TournamentEditFormValues,
            'contacts',
            'id'
          >[]
        ).map((field, i) => (
          <div key={field.id} className={styles.contactValue}>
            <div className={cx(styles.contactName, styles.stretch)}>
              {field.name}
            </div>
            <ThemeLink className={styles.stretch} inNewTab href={field.uri}>
              {field.label}
            </ThemeLink>
            <ThemeButton
              className={styles.contactButton}
              onClick={() => remove(i)}
              disabled={disabled}
            >
              Удалить
            </ThemeButton>
          </div>
        ))}

        <div className={styles.newContactForm}>
          <Input
            inputRef={nameRef}
            className={styles.stretch}
            invisible
            value={newContactName}
            onChange={setNewContactName}
            onEnter={onAdd}
            placeholder="Администратор"
            disabled={disabled}
          />
          <Input
            className={styles.stretch}
            invisible
            value={newContactLabel}
            onChange={setNewContactLabel}
            onEnter={onAdd}
            placeholder="Игорь Долвич"
            disabled={disabled}
          />
          <Input
            className={styles.stretch}
            invisible
            value={newContactLink}
            onChange={setNewContactLink}
            onEnter={onAdd}
            placeholder="http://t.me/test"
            disabled={disabled}
          />
          <ThemeButton
            className={styles.contactButton}
            onClick={onAdd}
            disabled={disabled || !newContactName || !newContactLabel}
          >
            Добавить
          </ThemeButton>
        </div>
      </FormInput>
    </FormRow>
  );
}
