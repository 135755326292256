import { OBJECT_EMPTY } from '@libs/constants';
import { BoardPositions, SquareName } from './types';

export const FEN_STARTING_POSITION =
  'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

export const FEN_EXAMPLE_1 =
  'rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR b KQkq e3 0 1';

export const FEN_EXAMPLE_2 =
  'rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR w KQkq c6 0 2';

export const FEN_EXAMPLE_3 =
  'rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq - 1 2';

export const FEN_EXAMPLE_END = '8/8/3N4/8/7p/7Q/6Pk/5K2 b - - 1 49';

// prettier-ignore
export const SQUARES: SquareName[] = [
  'a8', 'b8', 'c8', 'd8', 'e8', 'f8', 'g8', 'h8',
  'a7', 'b7', 'c7', 'd7', 'e7', 'f7', 'g7', 'h7',
  'a6', 'b6', 'c6', 'd6', 'e6', 'f6', 'g6', 'h6',
  'a5', 'b5', 'c5', 'd5', 'e5', 'f5', 'g5', 'h5',
  'a4', 'b4', 'c4', 'd4', 'e4', 'f4', 'g4', 'h4',
  'a3', 'b3', 'c3', 'd3', 'e3', 'f3', 'g3', 'h3',
  'a2', 'b2', 'c2', 'd2', 'e2', 'f2', 'g2', 'h2',
  'a1', 'b1', 'c1', 'd1', 'e1', 'f1', 'g1', 'h1',
] as const;
export const SQUARES_REVERSE: SquareName[] = SQUARES.slice().reverse();

export const RANKS_WHITE = ['8', '7', '6', '5', '4', '3', '2', '1'];
export const RANKS_BLACK = RANKS_WHITE.slice().reverse();
export const FILES_WHITE = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
export const FILES_BLACK = FILES_WHITE.slice().reverse();

export const EMPTY_POSITIONS: BoardPositions = OBJECT_EMPTY;

export const SQUARE_WIDTH = 100 / 8;
