import createClient from 'openapi-fetch';
import { AuthService } from '@features/auth/services/AuthService';
import { TimeSyncService } from '@features/shared/services/TimeSyncService';
import { SignalRSocket } from '@libs/socket';
import type { paths, components } from './generated';

export { components, paths };

const BASE_URL = __RUNTIME.clubApi;

/*
    ____,-------------------------------,____
    \   |          Http Client          |   /
    /___|-------------------------------|___\
*/

const api = createClient<paths>({
  baseUrl: BASE_URL,
});

export class NotFoundError extends Error {
  name = 'NotFoundError';
}
export class NoAccessError extends Error {
  name = 'NoAccessError';
}
export class UserError extends Error {
  name = 'UserError';
}
export class ServerError extends Error {
  name = 'ServerError';
}

api.use({
  async onRequest({ request }) {
    // SEE: https://openapi-ts.dev/openapi-fetch/middleware-auth
    request.headers.set(
      'Authorization',
      `Bearer ${AuthService.instance.accessToken}`,
    );
    return request;
  },

  async onResponse({ response }) {
    if (response.status === 401) {
      AuthService.instance.deleteTokens();
      return;
    }
    const body = await response.clone().text();
    if (response.status === 404) {
      throw new NotFoundError(body);
    }
    if (response.status === 403) {
      throw new NoAccessError(body);
    }
    if (response.status === 400) {
      throw new UserError(body);
    }

    if (response.status >= 500) {
      throw new ServerError(body);
    }
  },
});

const { GET, POST, DELETE } = api;
export { GET, POST, DELETE };

/*
    ____,-------------------------------,____
    \   |         Socket Clients        |   /
    /___|-------------------------------|___\
*/

// ------------------------------------------
// User Socket

const USER_SOCKET_URL = `${BASE_URL}/hubs/user`;

export type UserSocketEvents =
  // только свои
  | components['schemas']['GameStartsSoon']
  | components['schemas']['GameStarted']
  | components['schemas']['GameFinished']
  | components['schemas']['NoPair']
  | components['schemas']['SeanceStartsSoon']
  | components['schemas']['SeanceStarted']
  // все
  | components['schemas']['TournamentStartsSoon']
  | components['schemas']['RoundDrafted']
  | components['schemas']['RoundStarted']
  | components['schemas']['RoundFinished']
  | components['schemas']['TournamentPublished']
  | components['schemas']['TournamentUpdated']
  | components['schemas']['TournamentFinished'];

export class UserSocket extends SignalRSocket<UserSocketEvents> {
  constructor(public accessToken: string) {
    const EVENT_TYPES: Record<UserSocketEvents['eventType'], null> = {
      GameStartsSoon: null,
      GameStarted: null,
      GameFinished: null,
      NoPair: null,
      SeanceStartsSoon: null,
      SeanceStarted: null,
      //
      TournamentStartsSoon: null,
      RoundDrafted: null,
      RoundStarted: null,
      RoundFinished: null,
      TournamentPublished: null,
      TournamentUpdated: null,
      TournamentFinished: null,
    };

    super(
      `${USER_SOCKET_URL}?access_token=${accessToken}`,
      Object.keys(EVENT_TYPES),
      (e) => {
        TimeSyncService.instance.reportSocketTs(e.timestampMs);
      },
    );
  }
}
