import { useCallback } from 'react';
import {
  GameMovementPlayer,
  GameMovementRow,
} from '@features/shared/api/typings';
import { GameResultsBadge } from '@features/tournament/ui/components/GameResultBadge';
import { cx } from '@libs/classnames';
import { HorizontalScroll } from '@ui/components/HorizontalScroll';
import { Table, TableCell, TableRow } from '@ui/components/Table';
import styles from './RoundMovement.module.css';

type Props = {
  className?: string;
  isPending?: boolean;
  rows: GameMovementRow[];
  total?: {
    player1: GameMovementPlayer;
    player2: GameMovementPlayer;
  };
  onGameClick?: (gameId: string) => void;
};

export function RoundMovement({
  className,
  isPending,
  rows,
  total,
  onGameClick,
}: Props) {
  return (
    <HorizontalScroll>
      <Table
        className={cx(styles.root, className, {
          [styles.isPending]: isPending,
        })}
      >
        <TableRow header smallText>
          <TableCell>Стол</TableCell>
          <TableCell colSpan={/*2*/ 1}>Игрок</TableCell>
          <TableCell className={styles.resultsHeader}>Результат</TableCell>
          <TableCell left colSpan={/*2*/ 1}>
            Игрок
          </TableCell>
        </TableRow>

        {rows.map((r) => (
          <RoundMovementRow
            key={r.boardNo + r.player1.userId + r.player2.userId}
            row={r}
            onGameClick={onGameClick}
          />
        ))}

        {total && <TotalRow total={total} />}
      </Table>
    </HorizontalScroll>
  );
}

type RoundMovementRowProps = {
  row: GameMovementRow;
  onGameClick?: (gameId: string) => void;
};

function RoundMovementRow({ row, onGameClick }: RoundMovementRowProps) {
  const onRowClick = useCallback(() => {
    onGameClick &&
      row.player1.game.gameId &&
      onGameClick(row.player1.game.gameId);
  }, [row.player1.game.gameId, onGameClick]);

  return (
    <TableRow onClick={onRowClick} clickable hoverable>
      <TableCell className={styles.no}>{row.boardNo}</TableCell>
      {/*<TableCell className={styles.playerTitle}>{row.player1.title}</TableCell>*/}
      <TableCell className={styles.playerName}>
        <div className={styles.playerNameContainer}>
          {row.player1.lastName} {row.player1.firstName}
        </div>
      </TableCell>
      <TableCell className={styles.results}>
        <GameResultsBadge
          className={styles.result}
          gameId={row.player1.game.gameId}
          result={row.player1.game.resultType}
          side={row.player1.game.side}
          score={row.player1.game.score}
          dotCentered
        />
        <GameResultsBadge
          className={styles.result}
          gameId={row.player2.game.gameId}
          result={row.player2.game.resultType}
          side={row.player2.game.side}
          score={row.player2.game.score}
          dotCentered
          reversed
        />
      </TableCell>
      <TableCell left className={styles.playerName}>
        <div className={styles.playerNameContainer}>
          {row.player2.lastName} {row.player2.firstName}
        </div>
      </TableCell>
      {/*<TableCell className={styles.playerTitle}>{row.player2.title}</TableCell>*/}
    </TableRow>
  );
}

type TotalRowProps = {
  total: {
    player1: GameMovementPlayer;
    player2: GameMovementPlayer;
  };
};

function TotalRow({ total }: TotalRowProps) {
  return (
    <TableRow className={styles.total} hoverable>
      <TableCell className={styles.no}>Итого</TableCell>
      {/*<TableCell className={styles.playerTitle}></TableCell>*/}
      <TableCell className={styles.playerName}></TableCell>
      <TableCell className={styles.results}>
        <div className={styles.totalScore}>{total.player1.game.score}</div>
        <div className={styles.totalScore}>{total.player2.game.score}</div>
      </TableCell>
      <TableCell left className={styles.playerName}></TableCell>
      {/*<TableCell className={styles.playerTitle}>{row.player2.title}</TableCell>*/}
    </TableRow>
  );
}
