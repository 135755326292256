import { Controller, useFormContext } from 'react-hook-form';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Tabs } from '@ui/components/Tabs';

const TABS = [
  { value: 'White', label: 'Всегда белые' },
  { value: 'Black', label: 'Всегда черные' },
  { value: 'Both', label: 'Чередование' },
];

export function SeancerSideInput() {
  const { control } = useFormContext<TournamentEditFormValues>();
  return (
    <FormRow>
      <FormLabel>Фигуры сеансера</FormLabel>
      <FormInput>
        <Controller
          name="seanceSettings.seancerSide"
          control={control}
          render={({ field: { value, onChange, disabled } }) => (
            <Tabs
              tabs={TABS}
              onChange={onChange}
              value={value}
              disabled={disabled}
            />
          )}
        />
      </FormInput>
    </FormRow>
  );
}
