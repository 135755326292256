import { useState } from 'react';
import { cx } from '@libs/classnames';
import styles from './Pagination.module.css';

/*
    ____,-------------------------------,____
    \   |          Компонент            |   /
    /___|-------------------------------|___\
*/

type Props = {
  className?: string;
  pageTotal: number;
  pageNum: number;
  maxItems?: number;

  arrows?: 'none' | 'paging' | 'scrolling';
  quickJump?: 'none' | 'both' | 'start' | 'end';

  onPage?: (pageNum: number) => void;
};

export function Pagination({
  className,
  pageTotal,
  pageNum,
  maxItems: _maxItems = pageTotal,
  arrows = 'none',
  quickJump: _quickJump = 'both',
  onPage,
}: Props) {
  const isPaging = arrows === 'paging';
  const isScrolling = arrows === 'scrolling' && _maxItems < pageTotal;
  const isNoArrows = arrows === 'none' || (!isPaging && !isScrolling);

  //

  // Для пейджинга всегда показываем стрелки, для скроллинга только если есть
  // что скроллить
  const maxItems =
    isPaging || (isScrolling && _maxItems < pageTotal)
      ? _maxItems - 2
      : _maxItems;

  //

  const allowQJ = _quickJump !== 'none' && !isScrolling && maxItems >= 5;
  const allowQJStart =
    allowQJ && (_quickJump === 'both' || _quickJump === 'start');
  const allowQJEnd = allowQJ && (_quickJump === 'both' || _quickJump === 'end');

  //

  const [scrollOffset, setScrollOffset] = useState<number | undefined>();
  const shift = (() => {
    if (maxItems === pageTotal) return 0;

    const hasScroll = isScrolling && scrollOffset !== undefined;
    const offset = Math.max(
      0,
      hasScroll ? scrollOffset : pageNum - Math.ceil(maxItems / 2),
    );
    const maxOffset = Math.max(0, pageTotal - maxItems);

    return Math.min(offset, maxOffset);
  })();

  //

  const pages = new Array(Math.min(maxItems, pageTotal))
    .fill(null)
    .map((_, i) => 1 + i + shift);

  //

  return (
    <div className={cx(styles.pagination, className)}>
      {!isNoArrows && (
        <ArrowButton
          dir="back"
          disabled={isPaging ? pageNum === 1 : pages[0] === 1}
          onClick={() => {
            isScrolling
              ? setScrollOffset((pages[0] ?? 2) - 2)
              : onPage?.(pageNum - 1);
          }}
        />
      )}
      {pages.map((p, i) => {
        const page = (() => {
          if (allowQJStart && i === 0) return 1;
          if (allowQJEnd && i === maxItems - 1) return pageTotal;
          return p;
        })();
        //
        const skip =
          (allowQJStart && i === 1 && p !== 2) ||
          (allowQJEnd &&
            i === Math.min(maxItems, pageTotal) - 2 &&
            p !== pageTotal - 1);

        if (skip)
          return (
            <div key={`skip-${i}`} className={styles.dots}>
              …
            </div>
          );

        return (
          <div
            // HACK: ключ `${page}-${i}` используется, чтобы отключить
            // transitions для страниц между "..."
            key={`${page}-${i}`}
            className={cx(styles.page, {
              [styles.selected]: page === pageNum,
              [styles.smaller]: page >= 1000,
              [styles.tiny]: page >= 10000,
            })}
            onClick={() => onPage?.(page)}
          >
            {page}
          </div>
        );
      })}
      {!isNoArrows && (
        <ArrowButton
          dir="forward"
          disabled={
            isPaging
              ? pageNum === pageTotal
              : pages[pages.length - 1] === pageTotal
          }
          onClick={() => {
            isScrolling ? setScrollOffset(pages[0]) : onPage?.(pageNum + 1);
          }}
        />
      )}
    </div>
  );
}

/*
    ____,-------------------------------,____
    \   |           Запчасти            |   /
    /___|-------------------------------|___\
*/

function ArrowButton({
  dir,
  disabled,
  onClick,
}: {
  dir: 'back' | 'forward';
  disabled: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={cx(styles.arrow, {
        [styles.disabled]: disabled,
        [styles.flipped]: dir === 'back',
      })}
      onClick={onClick}
    >
      <ArrowIcon />
    </div>
  );
}

function ArrowIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 13 11">
      <path
        fill="currentColor"
        d="m6.97 11-.876-.864 3.603-3.602H.367v-1.25h9.33l-3.603-3.59.875-.876L12.06 5.91 6.97 11Z"
      />
    </svg>
  );
}
