import { useFormContext, useWatch } from 'react-hook-form';
import { ClockControlInput } from '@features/tournament/ui/components/ClockControlInput/ClockControlInput';
import { AdditionalScoreInput } from '@features/tournament/ui/components/TournamentSettings/inputs/AdditonalScoreInput';
import { RoundRobinRoundsCount } from '@features/tournament/ui/components/TournamentSettings/inputs/RoundRobinRoundsCount';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';

export function RoundRobinSettings() {
  const {
    control,
    setValue,
    formState: { disabled },
  } = useFormContext<TournamentEditFormValues>();

  const [system, clockControl, clockControlSettings] = useWatch({
    name: ['system', 'clockControl', 'clockControlSettingsPlayer1'],
    control,
  });

  if (system !== 'RoundRobin') return null;

  return (
    <>
      <RoundRobinRoundsCount />

      <ClockControlInput
        perPlayer={false}
        label="Контроль времени"
        value={{
          clockControl,
          clockControlSettings,
        }}
        onChange={({ clockControl, clockControlSettings }) => {
          setValue('clockControl', clockControl);
          setValue('clockControlSettingsPlayer1', clockControlSettings);
          setValue('clockControlSettingsPlayer2', clockControlSettings);
        }}
        disabled={disabled}
      />

      <AdditionalScoreInput />
    </>
  );
}
