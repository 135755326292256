import { CSSProperties, forwardRef, ReactNode, Ref } from 'react';
import { cx } from '@libs/classnames';
import styles from './Table.module.css';

export type TableProps = {
  style?: CSSProperties;
  className?: string;
  children: ReactNode;
};
export function Table({ style, className, children }: TableProps) {
  return (
    <table style={style} className={cx(className, styles.table)}>
      <tbody>{children}</tbody>
    </table>
  );
}

export type TableRowProps = {
  className?: string;
  header?: boolean;
  subHeader?: boolean;
  group?: boolean;
  active?: boolean;
  selected?: boolean;
  clickable?: boolean;
  hoverable?: boolean;
  smallText?: boolean;
  onClick?: () => void;
  children: ReactNode;
};
export const TableRow = forwardRef(function TableRow(
  {
    className,
    active,
    group,
    header,
    subHeader,
    selected,
    clickable,
    hoverable,
    smallText,
    onClick,
    children,
  }: TableRowProps,
  ref: Ref<HTMLTableRowElement>,
) {
  return (
    <tr
      ref={ref}
      className={cx(className, styles.tableRow, {
        [styles.header]: header,
        [styles.subHeader]: subHeader,
        [styles.group]: group,
        [styles.active]: active,
        [styles.selected]: selected,
        [styles.clickable]: clickable,
        [styles.hoverable]: hoverable,
        [styles.smallText]: smallText,
      })}
      onClick={onClick}
    >
      {children}
    </tr>
  );
});

export type TableCellProps = {
  style?: CSSProperties;
  className?: string;
  colSpan?: number;
  right?: boolean;
  left?: boolean;
  centered?: boolean;
  slim?: boolean;
  leftGap?: boolean;
  onClick?: () => void;
  smallText?: boolean;
  children?: ReactNode;
};
export const TableCell = forwardRef(function TableCell(
  {
    style,
    right,
    left,
    centered,
    slim,
    leftGap,
    colSpan,
    className,
    onClick,
    smallText,
    children,
  }: TableCellProps,
  ref: Ref<HTMLTableCellElement>,
) {
  return (
    <td
      ref={ref}
      style={style}
      colSpan={colSpan}
      onClick={onClick}
      className={cx(className, styles.tableCell, {
        [styles.right]: right,
        [styles.left]: left,
        [styles.centered]: centered,
        [styles.clickable]: !!onClick,
        [styles.slim]: slim,
        [styles.leftGap]: leftGap,
        [styles.smallText]: smallText,
      })}
    >
      {children}
    </td>
  );
});
