import { components } from '@features/shared/api/club/generated';

export function isTournamentStarted(
  status: components['schemas']['TournamentStatus'],
) {
  return !['Draft', 'NotStarted'].includes(status);
}

export function getPresentRounds(
  allRounds?: components['schemas']['RoundDto'][],
) {
  return allRounds
    ?.filter((r) => r.status === 'InProgress' || r.status === 'Finished')
    .sort((r1, r2) => r1.roundNumber - r2.roundNumber);
}

export function getActiveRound(allRounds: components['schemas']['RoundDto'][]) {
  return (
    allRounds.find((r) => r.status === 'InProgress')?.roundNumber ||
    [...allRounds].reverse().find((r) => r.status === 'Finished')
      ?.roundNumber ||
    allRounds[allRounds.length - 1]?.roundNumber ||
    1
  );
}
