import { Controller, useFormContext } from 'react-hook-form';
import { TournamentSystem } from '@features/shared/api/typings';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Tabs } from '@ui/components/Tabs';

const gameSystemTabs: {
  value: TournamentSystem;
  label: string;
  disabled?: boolean;
}[] = [
  { value: 'RoundRobin', label: 'Круговая' },
  { value: 'Swiss', label: 'Швейцарская', disabled: true },
  {
    value: 'Knockout',
    label: 'Нокаут (олимпийская)',
  },
];

export function GameSystemInput() {
  const { control } = useFormContext<TournamentEditFormValues>();

  return (
    <FormRow>
      <FormLabel>Система проведения</FormLabel>
      <FormInput>
        <Controller
          name="system"
          control={control}
          render={({ field: { value, onChange, disabled } }) => (
            <Tabs
              tabs={gameSystemTabs}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />
      </FormInput>
    </FormRow>
  );
}
