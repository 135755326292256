import styles from './GameInfo.module.css';

export type Props = {
  title: string;
  specs: string[];
};

export function GameInfo({ title, specs }: Props) {
  return (
    <section className={styles.root}>
      <h1 className={styles.title} title={title} translate="yes">
        {title}
      </h1>
      <p className={styles.specs}>{specs.join(' · ')}</p>
    </section>
  );
}
