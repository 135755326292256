import { ResultsTableWidget } from 'features/tournament/ui/components/ResultsTable';
import { useTournamentCardQuery } from '@features/tournament/api/hooks';
import styles from '@features/tournament/pages/TournamentCardPage/TournamentCardPage.module.css';
import { RoundMovementWidget } from '@features/tournament/ui/components/RoundMovement/RoundMovementWidget';
import { PageSubTitle } from '@ui/components/PageSubTitle';
import { Tabs } from '@ui/components/Tabs';

const RESULTS_TABS = [
  { value: 'table' as const, label: 'Турнирная таблица' },
  { value: 'rounds' as const, label: 'Движение по турам' },
];

type Props = {
  clubId: string;
  tournamentId: string;
  resultsTab: 'table' | 'rounds';
  onResultsTabChange: (resultsTab: 'table' | 'rounds') => void;
};

/**
 * Виджет выбирает, показывать таблицу или движение по раундам в результатах
 */
export function TournamentResultsWidget({
  clubId,
  tournamentId,
  resultsTab,
  onResultsTabChange,
}: Props) {
  const { data: tournament } = useTournamentCardQuery(clubId, tournamentId, {
    staleTime: Infinity,
  });

  const isSeance = tournament?.system === 'Seance';
  const isTournament = tournament?.system !== 'OneOnOne' && !isSeance;

  const table = (
    <ResultsTableWidget clubId={clubId} tournamentId={tournamentId} />
  );
  const rounds = (
    <RoundMovementWidget clubId={clubId} tournamentId={tournamentId} />
  );

  if (isSeance) {
    return (
      <div className={styles.results}>
        <div className={styles.participantsTitle}>
          <PageSubTitle>Результаты</PageSubTitle>
        </div>

        {rounds}
      </div>
    );
  }

  return (
    <div className={styles.results}>
      <div className={styles.participantsTitle}>
        <PageSubTitle>Участники</PageSubTitle>
        {isTournament && (
          <Tabs
            tabClassName={styles.participantsTab}
            tabs={RESULTS_TABS}
            onChange={onResultsTabChange}
            value={resultsTab}
          />
        )}
      </div>

      {resultsTab === 'table' && table}
      {resultsTab === 'rounds' && rounds}
    </div>
  );
}
