import { useEffect, useRef } from 'react';
import { useAuth } from '@features/auth/hooks/useAuth';
import { AuthService } from '@features/auth/services/AuthService';
import { SocketInternalEvents } from '@libs/socket';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useErrorNotification } from '@ui/components/ErrorsNotificationProvider';
import { components, GET, POST, UserSocket, UserSocketEvents } from './clients';

/*
    ____,-------------------------------,____
    \   |             REST              |   /
    /___|-------------------------------|___\
*/

export function useLoginMutation() {
  const ql = useQueryClient();
  const { report } = useErrorNotification();
  return useMutation({
    mutationFn: async ({
      login,
      password,
    }: {
      login: string;
      password: string;
    }) => {
      return (
        await POST('/api/v1/auth/login', {
          body: {
            username: login,
            password,
          },
        })
      ).data!;
    },
    onSuccess({ accessToken, user }) {
      onLoginSuccess(ql, accessToken, user);
    },
    onError: report,
  });
}

export function useCrossLoginMutation() {
  const ql = useQueryClient();
  const { report } = useErrorNotification();
  return useMutation({
    mutationFn: async ({ token }: { token: string }) => {
      return (
        await POST('/api/v1/auth/cross-login', {
          params: { query: { token } },
        })
      ).data!;
    },
    onSuccess({ accessToken, user }) {
      onLoginSuccess(ql, accessToken, user);
    },
    onError: report,
  });
}

function onLoginSuccess(
  ql: QueryClient,
  accessToken: string,
  user: components['schemas']['UserDto'],
) {
  // Инвалидация кэша при логине. При разлогине кэш инвалидирует AuthProvider
  ql.invalidateQueries();

  updateCurrentUserQuery(ql, user);
  AuthService.instance.saveTokens(accessToken, user);
}

export function useCurrentUserQuery({ enabled } = { enabled: true }) {
  return useQuery({
    enabled,
    gcTime: Infinity,
    staleTime: 1000,
    queryKey: ['/api/v1/user/me'],
    queryFn: async () => {
      const user = (await GET('/api/v1/user/me')).data!;
      const { accessToken } = AuthService.instance;
      if (accessToken) {
        AuthService.instance.saveTokens(accessToken, user);
      }
      return user;
    },
  });
}

function updateCurrentUserQuery(
  client: QueryClient,
  data: components['schemas']['UserDto'],
) {
  client.setQueryData(['/api/v1/user/me'], data);
}

export function useNextGamesQuery({
  enabled,
  refetchInterval,
}: { enabled?: boolean; refetchInterval?: number } = {}) {
  return useQuery({
    enabled,
    refetchInterval,
    queryKey: ['/api/v1/user/me/games', AuthService.instance.user?.userId],
    queryFn: async () => {
      return (await GET('/api/v1/user/me/games')).data!;
    },
  });
}

export function useNextRoundsQuery({
  enabled,
  refetchInterval,
}: { enabled?: boolean; refetchInterval?: number } = {}) {
  return useQuery({
    enabled,
    refetchInterval,
    queryKey: [
      '/api/v1/user/me/next-rounds',
      AuthService.instance.user?.userId,
    ],
    queryFn: async () => {
      return (await GET('/api/v1/user/me/next-rounds')).data!;
    },
  });
}

export function useGameAuthQuery(
  gameId: string,
  { enabled } = { enabled: true },
) {
  return useQuery({
    enabled,
    queryKey: [
      '/api/v1/user/me/games/{gameId}/actions/access',
      gameId,
      AuthService.instance.user?.userId,
    ],
    queryFn: async () => {
      return (
        await GET('/api/v1/user/me/games/{gameId}/actions/access', {
          params: {
            path: { gameId },
          },
        })
      ).data!;
    },
  });
}

export function useTournamentGameQuery(
  id: string,
  { enabled } = { enabled: true },
) {
  return useQuery({
    enabled,
    refetchOnWindowFocus: false,
    queryKey: ['/api/v1/public/games/{gameId}', id],
    queryFn: async () => {
      return (
        await GET('/api/v1/public/games/{gameId}', {
          params: { path: { gameId: id } },
        })
      ).data!;
    },
  });
}

export function useTournamentGamesQuery(
  clubId: string,
  tournamentId: string,
  roundNumber: number,
  { enabled } = { enabled: true },
) {
  return useQuery({
    enabled,
    refetchOnWindowFocus: false,
    queryKey: [
      '/api/v1/user/clubs/{clubId}/tournaments/{tournamentId}/rounds/{roundNumber}/games',
      clubId,
      tournamentId,
      roundNumber,
    ],
    queryFn: async () => {
      return (
        await GET(
          '/api/v1/user/clubs/{clubId}/tournaments/{tournamentId}/rounds/{roundNumber}/games',
          {
            params: {
              path: {
                clubId,
                tournamentId,
                roundNumber,
              },
            },
          },
        )
      ).data!;
    },
  });
}

/*
    ____,-------------------------------,____
    \   |            Sockets            |   /
    /___|-------------------------------|___\
*/

// ------------------------------------------
// User

let globalUserSocket: UserSocket | null = null;

export function useUserSocket(
  cb: (e: UserSocketEvents | SocketInternalEvents) => void,
) {
  const { user } = useAuth();
  const ref = useRef(cb);
  ref.current = cb;

  useEffect(() => {
    const accessToken = AuthService.instance.accessToken;

    if (!accessToken) return;

    if (!globalUserSocket || globalUserSocket.accessToken !== accessToken) {
      globalUserSocket?.discard();
      globalUserSocket = new UserSocket(accessToken);
    }

    const unsubscribe = globalUserSocket.subscribe((e) => ref.current(e));

    return () => {
      unsubscribe();
      if (globalUserSocket?.subscribers === 0 && !globalUserSocket.discarded) {
        globalUserSocket?.discard();
        globalUserSocket = null;
      }
    };
  }, [user?.userId]);
}
