import { components } from '@features/game/api/generated';
import { TableTimer } from '@features/game/ui/TableTimer';
import { cx } from '@libs/classnames';
import { AnimatedNode } from '@ui/components/Animation';
import { BoardColorCircle } from '@ui/components/BoardColorCircle';
import { useTranslate } from '@ui/components/TranslateProvider';
import styles from './PlayerPanelAlt.module.css';

/*
    ____,-------------------------------,____
    \   |          Компонент            |   /
    /___|-------------------------------|___\
*/

type IPlayer = {
  id: string;
  firstName: string;
  lastName: string;
  rating?: number | null;
  ratingChange?: number | null;
};
type IGame = {
  id: string;
  white: IPlayer;
  black: IPlayer;
  boardNumber: number | null;
  clock: {
    timestampMs: number;
    blackLeftMs: number;
    whiteLeftMs: number;
  };
  nextMoveBy: 'Black' | 'White';
  status: components['schemas']['GameStatus'];
  result: components['schemas']['GameResult'];
};

export type Props = {
  position?: 'top' | 'bottom';
  color: 'black' | 'white';
  game: IGame;
  ratingGain?: number;
  //-
  customPlayersTimeMs?: { white?: number; black?: number };
  timePressureSec: number;
  fractionsFromSec?: number;
  //-
  networkProblem?: boolean;
};

export function PlayerPanelAltDesktop({
  position = 'top',
  color,
  game,
  ratingGain,
  customPlayersTimeMs,
  timePressureSec = 10,
  fractionsFromSec = 10,
  networkProblem,
}: Props) {
  const isWhite = color === 'white';
  const isWhiteMove = game.nextMoveBy === 'White';
  const isInProgress = game.status === 'InProgress';
  const player = isWhite ? game.white : game.black;
  const { timestampMs, blackLeftMs, whiteLeftMs } = game.clock;
  const customTimeMs = customPlayersTimeMs?.[isWhite ? 'white' : 'black'];

  const name = `${player.lastName ?? ''} ${player.firstName ?? ''}`;
  const isDraw = game.result === 'Draw';
  const isWin = isWhite
    ? game.result === 'WhiteWin'
    : game.result === 'BlackWin';
  const isDefeat = !isWhite
    ? game.result === 'WhiteWin'
    : game.result === 'BlackWin';

  const { t } = useTranslate();

  return (
    <section
      className={cx(styles.player_panel, {
        [styles.is_top]: position === 'top',
        [styles.is_bottom]: position === 'bottom',
        [styles.is_network]: networkProblem,
        [styles.is_win]: isWin,
      })}
    >
      <div className={styles.section_result}>
        <AnimatedNode>
          {isWin &&
            t('features/game/pages/GamePage/DrawOffer/PlayerPanelAlt/Win')}
        </AnimatedNode>
      </div>
      <div className={styles.section_counter}>
        <div className={styles.section_counter__counter}>
          <TableTimer
            className={styles.section_counter__counter__timer}
            preset="big"
            leftMs={customTimeMs || (isWhite ? whiteLeftMs : blackLeftMs)}
            timestampMs={timestampMs}
            paused={
              customTimeMs === undefined && isInProgress
                ? isWhite
                  ? !isWhiteMove
                  : isWhiteMove
                : true
            }
            timePressureSec={timePressureSec}
            fractionsFromSec={fractionsFromSec}
            timeSync
          />
          <AnimatedNode className={styles.network_problem}>
            {networkProblem && 'Проблемы с сетью'}
          </AnimatedNode>
        </div>
        <div className={styles.section_counter__rating_gain}>
          <AnimatedNode>{formatDelta(ratingGain)}</AnimatedNode>
        </div>
        <AnimatedNode className={styles.section_counter__result}>
          {isDraw && <GameScore result="draw" />}
          {isWin && <GameScore result="win" />}
          {isDefeat && <GameScore result="defeat" />}
        </AnimatedNode>
      </div>
      <div className={styles.section_player}>
        <div className={styles.section_player__name}>
          <BoardColorCircle color={color} />
          {name}
        </div>
        <div className={styles.section_player__rating}>
          {player.rating}{' '}
          <AnimatedNode tag="span">
            {formatDelta(player.ratingChange)}
          </AnimatedNode>
        </div>
      </div>
    </section>
  );
}

/*
    ____,-------------------------------,____
    \   |            Запчасти           |   /
    /___|-------------------------------|___\
*/

function GameScore({ result }: { result: 'win' | 'defeat' | 'draw' }) {
  switch (result) {
    case 'defeat':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="27"
          fill="none"
        >
          <path
            fill="var(--theme-text-active)"
            d="M12.981.008c1.656 0 2.952.588 3.888 1.764.936 1.176 1.404 3.048 1.404 5.616 0 1.776-.228 3.576-.684 5.4a24.991 24.991 0 0 1-1.944 5.184 21.703 21.703 0 0 1-2.88 4.392c-1.08 1.272-2.256 2.28-3.528 3.024-1.272.744-2.592 1.116-3.96 1.116-1.584 0-2.844-.6-3.78-1.8-.912-1.2-1.368-3.072-1.368-5.616 0-1.776.228-3.564.684-5.364A25.241 25.241 0 0 1 2.721 8.54c.84-1.632 1.8-3.084 2.88-4.356 1.08-1.296 2.244-2.316 3.492-3.06C10.365.38 11.661.008 12.981.008Zm-.108.648c-1.008 0-1.98.432-2.916 1.296-.912.84-1.776 1.98-2.592 3.42-.792 1.416-1.488 3.012-2.088 4.788a40.417 40.417 0 0 0-1.368 5.4c-.336 1.848-.504 3.6-.504 5.256 0 1.68.192 2.94.576 3.78.408.84.924 1.26 1.548 1.26 1.008 0 1.968-.42 2.88-1.26.936-.864 1.8-2.004 2.592-3.42.816-1.44 1.512-3.036 2.088-4.788a38.452 38.452 0 0 0 1.404-5.436c.336-1.848.504-3.6.504-5.256 0-1.68-.204-2.94-.612-3.78-.384-.84-.888-1.26-1.512-1.26Z"
          />
        </svg>
      );
    case 'win':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="26"
          fill="none"
        >
          <path
            fill="var(--theme-golden-active)"
            d="M11.456.008 4.436 26H.584L6.632 3.464c-.696.36-1.452.708-2.268 1.044-.792.312-1.812.66-3.06 1.044l.108-.756c1.512-.456 2.832-.948 3.96-1.476a28.583 28.583 0 0 0 3.024-1.656 31.928 31.928 0 0 0 2.34-1.656h.72Z"
          />
        </svg>
      );
    case 'draw':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 14 15"
          width="24"
          height="26"
          fill="none"
        >
          <path
            fill="var(--theme-golden-active)"
            d="m13.8.816.24.208L.714 14.88l-.24-.208L13.8.816ZM7.354 1.952 5.737 7.008h-.944l1.296-4.016c-.267.16-.555.315-.864.464a7.446 7.446 0 0 1-1.152.4l.08-.304c.512-.16.939-.325 1.28-.496.352-.181.656-.368.912-.56.256-.192.49-.373.704-.544h.304Zm2.32 6.976c.373 0 .677.07.912.208.234.139.352.373.352.704 0 .341-.117.667-.352.976a5.34 5.34 0 0 1-.88.896c-.352.277-.71.539-1.072.784a9.84 9.84 0 0 0-.912.656c.181 0 .395.027.64.08.245.043.49.09.736.144.256.043.47.064.64.064.213 0 .39-.048.528-.144a.49.49 0 0 0 .224-.416.673.673 0 0 0-.064-.304l.272-.112c.053.075.09.155.112.24a.768.768 0 0 1 .048.272c0 .299-.123.555-.368.768-.246.213-.603.32-1.072.32-.33 0-.63-.043-.896-.128a4.068 4.068 0 0 0-1.008-.16 2.17 2.17 0 0 0-.672.032c-.17.043-.363.107-.576.192l.064-.288c.192-.128.437-.288.736-.48.31-.192.63-.4.96-.624.341-.235.661-.485.96-.752.299-.267.539-.539.72-.816a1.57 1.57 0 0 0 .288-.88c0-.245-.075-.421-.224-.528-.15-.107-.363-.16-.64-.16s-.565.064-.864.192c-.288.117-.57.33-.848.64l-.224-.176c.245-.31.581-.587 1.008-.832.427-.245.917-.368 1.472-.368Z"
          />
        </svg>
      );
  }
}

/*
    ____,-------------------------------,____
    \   |            Утилиты            |   /
    /___|-------------------------------|___\
*/

function formatDelta(d?: number | null): string | null {
  if (!d) return null;
  return d < 0 ? d.toString() : `+${d}`;
}
