/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as IndexImport } from './routes/index'
import { Route as GameIndexImport } from './routes/game/index'
import { Route as GameGameIdImport } from './routes/game/$gameId'
import { Route as AuthenticatedClubIndexImport } from './routes/_authenticated.club/index'
import { Route as AuthenticatedClubClubIdImport } from './routes/_authenticated.club/$clubId'
import { Route as AuthenticatedClubClubIdSettingsImport } from './routes/_authenticated.club/$clubId_.settings'
import { Route as AuthenticatedClubClubIdTournamentIndexImport } from './routes/_authenticated.club/$clubId_.tournament/index'
import { Route as AuthenticatedClubClubIdTournamentTournamentIdImport } from './routes/_authenticated.club/$clubId_.tournament/$tournamentId'
import { Route as AuthenticatedClubClubIdTournamentTournamentIdSeanceImport } from './routes/_authenticated.club/$clubId_.tournament/$tournamentId_.seance'
import { Route as AuthenticatedClubClubIdTournamentTournamentIdEditImport } from './routes/_authenticated.club/$clubId_.tournament/$tournamentId_.edit'
import { Route as AuthenticatedClubClubIdTournamentTournamentIdGameGameIdImport } from './routes/_authenticated.club/$clubId_.tournament/$tournamentId_.game.$gameId'

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const GameIndexRoute = GameIndexImport.update({
  id: '/game/',
  path: '/game/',
  getParentRoute: () => rootRoute,
} as any)

const GameGameIdRoute = GameGameIdImport.update({
  id: '/game/$gameId',
  path: '/game/$gameId',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedClubIndexRoute = AuthenticatedClubIndexImport.update({
  id: '/club/',
  path: '/club/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedClubClubIdRoute = AuthenticatedClubClubIdImport.update({
  id: '/club/$clubId',
  path: '/club/$clubId',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedClubClubIdSettingsRoute =
  AuthenticatedClubClubIdSettingsImport.update({
    id: '/club/$clubId_/settings',
    path: '/club/$clubId/settings',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedClubClubIdTournamentIndexRoute =
  AuthenticatedClubClubIdTournamentIndexImport.update({
    id: '/club/$clubId_/tournament/',
    path: '/club/$clubId/tournament/',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedClubClubIdTournamentTournamentIdRoute =
  AuthenticatedClubClubIdTournamentTournamentIdImport.update({
    id: '/club/$clubId_/tournament/$tournamentId',
    path: '/club/$clubId/tournament/$tournamentId',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedClubClubIdTournamentTournamentIdSeanceRoute =
  AuthenticatedClubClubIdTournamentTournamentIdSeanceImport.update({
    id: '/club/$clubId_/tournament/$tournamentId_/seance',
    path: '/club/$clubId/tournament/$tournamentId/seance',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedClubClubIdTournamentTournamentIdEditRoute =
  AuthenticatedClubClubIdTournamentTournamentIdEditImport.update({
    id: '/club/$clubId_/tournament/$tournamentId_/edit',
    path: '/club/$clubId/tournament/$tournamentId/edit',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedClubClubIdTournamentTournamentIdGameGameIdRoute =
  AuthenticatedClubClubIdTournamentTournamentIdGameGameIdImport.update({
    id: '/club/$clubId_/tournament/$tournamentId_/game/$gameId',
    path: '/club/$clubId/tournament/$tournamentId/game/$gameId',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/game/$gameId': {
      id: '/game/$gameId'
      path: '/game/$gameId'
      fullPath: '/game/$gameId'
      preLoaderRoute: typeof GameGameIdImport
      parentRoute: typeof rootRoute
    }
    '/game/': {
      id: '/game/'
      path: '/game'
      fullPath: '/game'
      preLoaderRoute: typeof GameIndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/club/$clubId': {
      id: '/_authenticated/club/$clubId'
      path: '/club/$clubId'
      fullPath: '/club/$clubId'
      preLoaderRoute: typeof AuthenticatedClubClubIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/club/': {
      id: '/_authenticated/club/'
      path: '/club'
      fullPath: '/club'
      preLoaderRoute: typeof AuthenticatedClubIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/club/$clubId_/settings': {
      id: '/_authenticated/club/$clubId_/settings'
      path: '/club/$clubId/settings'
      fullPath: '/club/$clubId/settings'
      preLoaderRoute: typeof AuthenticatedClubClubIdSettingsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/club/$clubId_/tournament/$tournamentId': {
      id: '/_authenticated/club/$clubId_/tournament/$tournamentId'
      path: '/club/$clubId/tournament/$tournamentId'
      fullPath: '/club/$clubId/tournament/$tournamentId'
      preLoaderRoute: typeof AuthenticatedClubClubIdTournamentTournamentIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/club/$clubId_/tournament/': {
      id: '/_authenticated/club/$clubId_/tournament/'
      path: '/club/$clubId/tournament'
      fullPath: '/club/$clubId/tournament'
      preLoaderRoute: typeof AuthenticatedClubClubIdTournamentIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/club/$clubId_/tournament/$tournamentId_/edit': {
      id: '/_authenticated/club/$clubId_/tournament/$tournamentId_/edit'
      path: '/club/$clubId/tournament/$tournamentId/edit'
      fullPath: '/club/$clubId/tournament/$tournamentId/edit'
      preLoaderRoute: typeof AuthenticatedClubClubIdTournamentTournamentIdEditImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/club/$clubId_/tournament/$tournamentId_/seance': {
      id: '/_authenticated/club/$clubId_/tournament/$tournamentId_/seance'
      path: '/club/$clubId/tournament/$tournamentId/seance'
      fullPath: '/club/$clubId/tournament/$tournamentId/seance'
      preLoaderRoute: typeof AuthenticatedClubClubIdTournamentTournamentIdSeanceImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/club/$clubId_/tournament/$tournamentId_/game/$gameId': {
      id: '/_authenticated/club/$clubId_/tournament/$tournamentId_/game/$gameId'
      path: '/club/$clubId/tournament/$tournamentId/game/$gameId'
      fullPath: '/club/$clubId/tournament/$tournamentId/game/$gameId'
      preLoaderRoute: typeof AuthenticatedClubClubIdTournamentTournamentIdGameGameIdImport
      parentRoute: typeof AuthenticatedImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedRouteChildren {
  AuthenticatedClubClubIdRoute: typeof AuthenticatedClubClubIdRoute
  AuthenticatedClubIndexRoute: typeof AuthenticatedClubIndexRoute
  AuthenticatedClubClubIdSettingsRoute: typeof AuthenticatedClubClubIdSettingsRoute
  AuthenticatedClubClubIdTournamentTournamentIdRoute: typeof AuthenticatedClubClubIdTournamentTournamentIdRoute
  AuthenticatedClubClubIdTournamentIndexRoute: typeof AuthenticatedClubClubIdTournamentIndexRoute
  AuthenticatedClubClubIdTournamentTournamentIdEditRoute: typeof AuthenticatedClubClubIdTournamentTournamentIdEditRoute
  AuthenticatedClubClubIdTournamentTournamentIdSeanceRoute: typeof AuthenticatedClubClubIdTournamentTournamentIdSeanceRoute
  AuthenticatedClubClubIdTournamentTournamentIdGameGameIdRoute: typeof AuthenticatedClubClubIdTournamentTournamentIdGameGameIdRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedClubClubIdRoute: AuthenticatedClubClubIdRoute,
  AuthenticatedClubIndexRoute: AuthenticatedClubIndexRoute,
  AuthenticatedClubClubIdSettingsRoute: AuthenticatedClubClubIdSettingsRoute,
  AuthenticatedClubClubIdTournamentTournamentIdRoute:
    AuthenticatedClubClubIdTournamentTournamentIdRoute,
  AuthenticatedClubClubIdTournamentIndexRoute:
    AuthenticatedClubClubIdTournamentIndexRoute,
  AuthenticatedClubClubIdTournamentTournamentIdEditRoute:
    AuthenticatedClubClubIdTournamentTournamentIdEditRoute,
  AuthenticatedClubClubIdTournamentTournamentIdSeanceRoute:
    AuthenticatedClubClubIdTournamentTournamentIdSeanceRoute,
  AuthenticatedClubClubIdTournamentTournamentIdGameGameIdRoute:
    AuthenticatedClubClubIdTournamentTournamentIdGameGameIdRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/login': typeof LoginRoute
  '/game/$gameId': typeof GameGameIdRoute
  '/game': typeof GameIndexRoute
  '/club/$clubId': typeof AuthenticatedClubClubIdRoute
  '/club': typeof AuthenticatedClubIndexRoute
  '/club/$clubId/settings': typeof AuthenticatedClubClubIdSettingsRoute
  '/club/$clubId/tournament/$tournamentId': typeof AuthenticatedClubClubIdTournamentTournamentIdRoute
  '/club/$clubId/tournament': typeof AuthenticatedClubClubIdTournamentIndexRoute
  '/club/$clubId/tournament/$tournamentId/edit': typeof AuthenticatedClubClubIdTournamentTournamentIdEditRoute
  '/club/$clubId/tournament/$tournamentId/seance': typeof AuthenticatedClubClubIdTournamentTournamentIdSeanceRoute
  '/club/$clubId/tournament/$tournamentId/game/$gameId': typeof AuthenticatedClubClubIdTournamentTournamentIdGameGameIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/login': typeof LoginRoute
  '/game/$gameId': typeof GameGameIdRoute
  '/game': typeof GameIndexRoute
  '/club/$clubId': typeof AuthenticatedClubClubIdRoute
  '/club': typeof AuthenticatedClubIndexRoute
  '/club/$clubId/settings': typeof AuthenticatedClubClubIdSettingsRoute
  '/club/$clubId/tournament/$tournamentId': typeof AuthenticatedClubClubIdTournamentTournamentIdRoute
  '/club/$clubId/tournament': typeof AuthenticatedClubClubIdTournamentIndexRoute
  '/club/$clubId/tournament/$tournamentId/edit': typeof AuthenticatedClubClubIdTournamentTournamentIdEditRoute
  '/club/$clubId/tournament/$tournamentId/seance': typeof AuthenticatedClubClubIdTournamentTournamentIdSeanceRoute
  '/club/$clubId/tournament/$tournamentId/game/$gameId': typeof AuthenticatedClubClubIdTournamentTournamentIdGameGameIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/login': typeof LoginRoute
  '/game/$gameId': typeof GameGameIdRoute
  '/game/': typeof GameIndexRoute
  '/_authenticated/club/$clubId': typeof AuthenticatedClubClubIdRoute
  '/_authenticated/club/': typeof AuthenticatedClubIndexRoute
  '/_authenticated/club/$clubId_/settings': typeof AuthenticatedClubClubIdSettingsRoute
  '/_authenticated/club/$clubId_/tournament/$tournamentId': typeof AuthenticatedClubClubIdTournamentTournamentIdRoute
  '/_authenticated/club/$clubId_/tournament/': typeof AuthenticatedClubClubIdTournamentIndexRoute
  '/_authenticated/club/$clubId_/tournament/$tournamentId_/edit': typeof AuthenticatedClubClubIdTournamentTournamentIdEditRoute
  '/_authenticated/club/$clubId_/tournament/$tournamentId_/seance': typeof AuthenticatedClubClubIdTournamentTournamentIdSeanceRoute
  '/_authenticated/club/$clubId_/tournament/$tournamentId_/game/$gameId': typeof AuthenticatedClubClubIdTournamentTournamentIdGameGameIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/login'
    | '/game/$gameId'
    | '/game'
    | '/club/$clubId'
    | '/club'
    | '/club/$clubId/settings'
    | '/club/$clubId/tournament/$tournamentId'
    | '/club/$clubId/tournament'
    | '/club/$clubId/tournament/$tournamentId/edit'
    | '/club/$clubId/tournament/$tournamentId/seance'
    | '/club/$clubId/tournament/$tournamentId/game/$gameId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/login'
    | '/game/$gameId'
    | '/game'
    | '/club/$clubId'
    | '/club'
    | '/club/$clubId/settings'
    | '/club/$clubId/tournament/$tournamentId'
    | '/club/$clubId/tournament'
    | '/club/$clubId/tournament/$tournamentId/edit'
    | '/club/$clubId/tournament/$tournamentId/seance'
    | '/club/$clubId/tournament/$tournamentId/game/$gameId'
  id:
    | '__root__'
    | '/'
    | '/_authenticated'
    | '/login'
    | '/game/$gameId'
    | '/game/'
    | '/_authenticated/club/$clubId'
    | '/_authenticated/club/'
    | '/_authenticated/club/$clubId_/settings'
    | '/_authenticated/club/$clubId_/tournament/$tournamentId'
    | '/_authenticated/club/$clubId_/tournament/'
    | '/_authenticated/club/$clubId_/tournament/$tournamentId_/edit'
    | '/_authenticated/club/$clubId_/tournament/$tournamentId_/seance'
    | '/_authenticated/club/$clubId_/tournament/$tournamentId_/game/$gameId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  LoginRoute: typeof LoginRoute
  GameGameIdRoute: typeof GameGameIdRoute
  GameIndexRoute: typeof GameIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  LoginRoute: LoginRoute,
  GameGameIdRoute: GameGameIdRoute,
  GameIndexRoute: GameIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_authenticated",
        "/login",
        "/game/$gameId",
        "/game/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/club/$clubId",
        "/_authenticated/club/",
        "/_authenticated/club/$clubId_/settings",
        "/_authenticated/club/$clubId_/tournament/$tournamentId",
        "/_authenticated/club/$clubId_/tournament/",
        "/_authenticated/club/$clubId_/tournament/$tournamentId_/edit",
        "/_authenticated/club/$clubId_/tournament/$tournamentId_/seance",
        "/_authenticated/club/$clubId_/tournament/$tournamentId_/game/$gameId"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/game/$gameId": {
      "filePath": "game/$gameId.tsx"
    },
    "/game/": {
      "filePath": "game/index.tsx"
    },
    "/_authenticated/club/$clubId": {
      "filePath": "_authenticated.club/$clubId.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/club/": {
      "filePath": "_authenticated.club/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/club/$clubId_/settings": {
      "filePath": "_authenticated.club/$clubId_.settings.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/club/$clubId_/tournament/$tournamentId": {
      "filePath": "_authenticated.club/$clubId_.tournament/$tournamentId.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/club/$clubId_/tournament/": {
      "filePath": "_authenticated.club/$clubId_.tournament/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/club/$clubId_/tournament/$tournamentId_/edit": {
      "filePath": "_authenticated.club/$clubId_.tournament/$tournamentId_.edit.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/club/$clubId_/tournament/$tournamentId_/seance": {
      "filePath": "_authenticated.club/$clubId_.tournament/$tournamentId_.seance.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/club/$clubId_/tournament/$tournamentId_/game/$gameId": {
      "filePath": "_authenticated.club/$clubId_.tournament/$tournamentId_.game.$gameId.tsx",
      "parent": "/_authenticated"
    }
  }
}
ROUTE_MANIFEST_END */
