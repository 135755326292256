import { ChessBoardProps } from 'exports/ChessBoard';
import { components } from '@features/game/api';
import {
  PlayerPanel,
  PlayerPanelProps,
} from '@features/game/pages/GamePage/components/PlayerPanel';
import { PlayerPanelAltDesktop } from './PlayerPanelAlt';

export type Props = {
  color: 'black' | 'white';
  orientation: PlayerPanelProps['orientation'];
  turn: 'black' | 'white' | null;
  mobile?: PlayerPanelProps['mobile'];
  timePressureSec?: number;
  game: components['schemas']['GameDto'];
  winner: ChessBoardProps['winner'];
  scoresheetTimeMs?: { white?: number; black?: number };
};

export function PlayerPanelWidget({
  color,
  orientation,
  turn,
  mobile,
  timePressureSec = 10,
  game,
  winner,
  scoresheetTimeMs,
}: Props) {
  // Новая версия для Desk

  if (!mobile)
    return (
      <PlayerPanelAltDesktop
        game={game}
        timePressureSec={timePressureSec}
        fractionsFromSec={timePressureSec}
        customPlayersTimeMs={scoresheetTimeMs}
        position={orientation}
        color={color}
      />
    );

  // Старая версия для Mobile

  const { timeMs, name, rating, ratingUpdate, result, timestamp } =
    getPlayerData(color, game, winner);

  const active = turn === color;
  const scoresheetPlayerTime =
    game.status !== 'InProgress' && scoresheetTimeMs !== undefined
      ? scoresheetTimeMs[color]
      : undefined;
  const paused = scoresheetPlayerTime !== undefined || !active || !!winner;

  return (
    <PlayerPanel
      orientation={orientation}
      mobile={mobile}
      //-
      name={name}
      color={color}
      //-
      leftMs={scoresheetPlayerTime ?? timeMs}
      timestampMs={timestamp}
      paused={paused}
      timePressureSec={timePressureSec}
      fractionsFromSec={timePressureSec}
      //-
      networkProblem={undefined}
      //-
      rating={rating}
      ratingGain={undefined}
      ratingUpdate={ratingUpdate}
      //-
      result={result}
    />
  );
}

function getPlayerData(
  color: 'black' | 'white' = 'white',
  game: components['schemas']['GameDto'],
  winner: ChessBoardProps['winner'],
): {
  timestamp: number;
  timeMs: number;
  name: PlayerPanelProps['name'];
  rating: PlayerPanelProps['rating'];
  result: PlayerPanelProps['result'];
  ratingUpdate: PlayerPanelProps['ratingUpdate'];
} {
  const result = winner
    ? winner === 'draw'
      ? 'draw'
      : winner === color
        ? 'win'
        : 'defeat'
    : undefined;

  const { firstName, lastName, rating, ratingChange } = game[color];
  const timeMs =
    color === 'black' ? game.clock.blackLeftMs : game.clock.whiteLeftMs;

  return {
    name: `${lastName} ${firstName}`,
    rating: rating ?? undefined,
    ratingUpdate: ratingChange,
    timeMs,
    result,
    timestamp: game.clock.timestampMs,
  };
}
