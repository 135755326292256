import { components } from '@features/game/api';
import { Button } from '@ui/components/Button';
import {
  ConfirmationButton,
  useConfimationButton,
} from '@ui/components/ConfirmationButton';
import { useTranslate } from '@ui/components/TranslateProvider';

/*
    ____,-------------------------------,____
    \   |          Компоненты           |   /
    /___|-------------------------------|___\
*/

type Props = {
  className?: string;
  onClick?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  disabled?: boolean;
};

export function ButtonCancelGame({ className, onClick }: Props) {
  const { t } = useTranslate();
  return (
    <ConfirmationButton
      className={className}
      icon={<IconX />}
      warning={t('features/game/ui/GameButtons/Are you sure')}
      confirm={t('features/game/ui/GameButtons/Confirm')}
      {...useConfimationButton(onClick)}
    >
      {t('features/game/ui/GameButtons/Cancel match')}
    </ConfirmationButton>
  );
}

export function ButtonDraw({ className, onClick, disabled }: Props) {
  const { t } = useTranslate();
  return (
    <ConfirmationButton
      className={className}
      icon={<IconDraw />}
      warning={t('features/game/ui/GameButtons/Are you sure')}
      confirm={t('features/game/ui/GameButtons/Confirm')}
      disabled={disabled}
      {...useConfimationButton(onClick)}
    >
      {t('features/game/ui/GameButtons/Draw')}
    </ConfirmationButton>
  );
}

export function ButtonResign({ className, onClick, disabled }: Props) {
  const { t } = useTranslate();
  return (
    <ConfirmationButton
      className={className}
      icon={<IconFlag />}
      warning={t('features/game/ui/GameButtons/Are you sure')}
      confirm={t('features/game/ui/GameButtons/Confirm')}
      disabled={disabled}
      {...useConfimationButton(onClick)}
    >
      {t('features/game/ui/GameButtons/Resign')}
    </ConfirmationButton>
  );
}

export function ButtonLeave({
  className,
  system,
  onClick,
}: Props & { system?: components['schemas']['TournamentSystem'] }) {
  const { t } = useTranslate();
  return (
    <Button className={className} onClick={onClick}>
      {(() => {
        switch (system) {
          case 'OneOnOne':
            return t('features/game/ui/GameButtons/Leave (to match)');
          case 'RoundRobin':
          case 'Knockout':
          case 'Swiss':
          case undefined:
            return t('features/game/ui/GameButtons/Leave (to tournament)');
          case 'Seance':
          default:
            return t('features/game/ui/GameButtons/Leave (default)');
        }
      })()}
    </Button>
  );
}

export function ButtonNotation({
  className,
  onClick,
  disabled,
  active,
}: Props & { active?: boolean }) {
  const { t } = useTranslate();
  return (
    <Button
      className={className}
      style={
        active
          ? {
              background: 'var(--theme-golden-active)',
              color: 'var(--theme-text-inverted)',
            }
          : undefined
      }
      icon={<IconList />}
      disabled={disabled}
      onClick={onClick}
    >
      {t('features/game/ui/GameButtons/Notation')}
    </Button>
  );
}

/*
    ____,-------------------------------,____
    \   |             Иконки            |   /
    /___|-------------------------------|___\
*/

function IconX() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m.97 12.47 12-12 1.06 1.06-12 12-1.06-1.06Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m14.03 12.47-12-12L.97 1.53l12 12 1.06-1.06Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function IconDraw() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.87.227v6.546H2.485V1.54h-.039l-1.499.94V1.252L2.568.227H3.87Zm5.483 13.546v-.997l2.33-2.158a8.66 8.66 0 0 0 .498-.517 1.95 1.95 0 0 0 .31-.451c.07-.15.106-.31.106-.483a.909.909 0 0 0-.131-.495.86.86 0 0 0-.358-.323 1.122 1.122 0 0 0-.515-.115c-.2 0-.375.04-.524.122a.837.837 0 0 0-.345.348 1.129 1.129 0 0 0-.122.54H9.29c0-.428.097-.8.29-1.115a1.94 1.94 0 0 1 .816-.732 2.69 2.69 0 0 1 1.208-.26c.469 0 .877.084 1.224.25.35.164.621.392.815.684.194.292.29.627.29 1.004 0 .247-.048.49-.146.732-.096.24-.268.508-.515.802a11.77 11.77 0 0 1-1.045 1.051l-.956.937v.045h2.749v1.13H9.353ZM12.99.273l-12 12 1.06 1.06 12-12-1.06-1.06Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function IconFlag() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m15.182 7.306-10.316.765 1.984 5.56H5.138L.818.672l11.84-.303 2.524 6.938ZM4.377 6.604l8.718-.647-1.477-4.062-8.736.224 1.495 4.485Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function IconList() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" fill="none">
      <path
        fill="currentColor"
        d="M2.12 0H.514l.605 2H2.75L2.12 0Zm11.612 0H3.127l.605 2 10.754-.404L13.732 0ZM2.119 5H.515l.605 2H2.75L2.12 5Zm11.613 0H3.127l.605 2 10.754-.404L13.732 5ZM.515 10h1.604l.632 2H1.12l-.605-2Zm2.612 0h10.605l.754 1.596L3.732 12l-.605-2Z"
      />
    </svg>
  );
}
