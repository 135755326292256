import { EMPTY_POSITIONS, SQUARES } from '@libs/chess/constants';
import { BoardPositions, PieceCode, SquareName } from '@libs/chess/types';

export type BoardPositionsIds = {
  squares: Partial<Record<SquareName, string>>;
  ids: Partial<Record<string, SquareName>>;
  promotion?: string;
};

export function trackIds(
  prevBoard: BoardPositions | null,
  nextBoard: BoardPositions | null,
  prevIds: BoardPositionsIds = createIds(nextBoard ?? EMPTY_POSITIONS),
): BoardPositionsIds {
  if (!prevBoard || !nextBoard || prevBoard === nextBoard) return prevIds;

  const leftout: Array<{ square: SquareName; piece: PieceCode }> = [];
  const entered: Array<{ square: SquareName; piece: PieceCode }> = [];
  const changed: Array<{
    square: SquareName;
    pieceFrom: PieceCode;
    pieceTo: PieceCode;
  }> = [];

  SQUARES.forEach((square) => {
    const before = prevBoard[square];
    const after = nextBoard[square];

    if (before === after) return;

    if (before && after && before !== after) {
      // changed
      changed.push({ square, pieceFrom: before, pieceTo: after });
    } else if (before) {
      // leftout
      leftout.push({ square, piece: before });
    } else if (after) {
      // entered
      entered.push({ square, piece: after });
    }
  });

  const nextIds: BoardPositionsIds = structuredClone(prevIds);
  delete nextIds.promotion;

  /*
    Варианты ходов:
    - движение
    - взятие
    - взятие на проходе
    - превращение
    - превращение со взятием
    - рокировка (любая)
  */

  if (leftout.length === 1) {
    // движение, превращение, взятие, превращение со взятием
    const lo = leftout[0]!;

    if (!changed.length && entered.length === 1) {
      // движение и (возможно) превращение
      const en = entered[0]!;

      handleMove(lo.square, en.square, prevIds, nextIds);

      if (lo.piece !== en.piece) {
        // превращение
        handlePromotion(lo.square, prevIds, nextIds);
      }

      return nextIds;
    }

    if (changed.length === 1 && !entered.length) {
      // взятие и (возможно) с превращением
      const ch = changed[0]!;

      handleMove(lo.square, ch.square, prevIds, nextIds);
      handleTaken(ch.square, prevIds, nextIds);

      if (lo.piece !== ch.pieceTo) {
        // с превращением
        handlePromotion(lo.square, prevIds, nextIds);
      }

      return nextIds;
    }
  } else if (leftout.length === 2 && !changed.length) {
    // взятие на проходе, рокировка
    const lo1 = leftout[0]!;
    const lo2 = leftout[1]!;

    if (entered.length === 1) {
      // взятие на проходе
      const en = entered[0]!;
      const from = lo1.piece === en.piece ? lo1 : lo2;
      const taken = from === lo1 ? lo2 : lo1;

      handleMove(from.square, en.square, prevIds, nextIds);
      handleTaken(taken.square, prevIds, nextIds);

      return nextIds;
    }

    if (entered.length === 2) {
      // рокировка
      const en1 = lo1.piece === entered[0]!.piece ? entered[0]! : entered[1]!;
      const en2 = en1 === entered[0] ? entered[1]! : entered[0]!;

      handleMove(lo1.square, en1.square, prevIds, nextIds);
      handleMove(lo2.square, en2.square, prevIds, nextIds);

      return nextIds;
    }
  }

  // ерунда, а не ход
  return createIds(nextBoard);
}

function createIds(pos: BoardPositions): BoardPositionsIds {
  const ids: BoardPositionsIds['ids'] = {};
  const prefix = (Math.random() * 1000).toFixed(0);

  const squares = (Object.keys(pos) as SquareName[]).reduce(
    (sink, s, i) => {
      const id = `${prefix}-${i}`;
      sink[s] = id;
      ids[id] = s;
      return sink;
    },
    {} as BoardPositionsIds['squares'],
  );

  return { squares, ids };
}

function handleMove(
  from: SquareName,
  to: SquareName,
  prev: BoardPositionsIds,
  next: BoardPositionsIds,
) {
  const id = prev.squares[from]!;
  next.ids[id] = to;
  next.squares[to] = id;
}

function handleTaken(
  square: SquareName,
  prev: BoardPositionsIds,
  next: BoardPositionsIds,
) {
  const id = prev.squares[square]!;
  delete next.ids[id];
  if (next.squares[square] === id) {
    next.squares[square] = undefined;
  }
}

function handlePromotion(
  from: SquareName,
  prev: BoardPositionsIds,
  next: BoardPositionsIds,
) {
  const id = prev.squares[from]!;
  next.promotion = id;
}
