import { ChessBoardProps } from 'exports/ChessBoard';
import { useCallback, useMemo, useState } from 'react';
import { SquareName } from '@libs/chess/types';
import { useImmediateEffect } from '@libs/hooks/useImmediateEffect';
import { LangResources, useTranslate } from '@ui/components/TranslateProvider';
import { components } from '../api';

const TEXTS = {
  draw: 'features/game/hooks/useScoresheet/Draw',
  whiteWin: 'features/game/hooks/useScoresheet/White Win',
  blackWin: 'features/game/hooks/useScoresheet/Black Win',
  whiteOffer: 'features/game/hooks/useScoresheet/White offer draw',
  blackOffer: 'features/game/hooks/useScoresheet/Black offer draw',
} satisfies Record<string, keyof LangResources>;

export type MappedProps = {
  moves: components['schemas']['MoveDto'][];
  onNavigate: (next: components['schemas']['MoveDto']) => void;
  selected?: components['schemas']['MoveDto'];
  result?: string;
};

export type Value = {
  props: MappedProps;
  propsPatch?: Partial<ChessBoardProps>;
  playersTimeMs?: { white?: number; black?: number };
  isLastMove?: boolean;
  reset: () => void;
};

export function useScoresheet(
  game?: components['schemas']['GameDto'],
  props?: ChessBoardProps,
): Value {
  const { t } = useTranslate();
  const [selected, setSelected] = useState<components['schemas']['MoveDto']>();
  const reset = useCallback(() => {
    setSelected(undefined);
  }, []);
  const moves = useMemo(() => game?.moves ?? [], [game]);

  const onNavigate = useCallback((next: components['schemas']['MoveDto']) => {
    setSelected(next);
  }, []);

  // сбросить выбор если случился новый ход в игре
  useImmediateEffect(() => {
    if (selected) {
      setSelected(undefined);
    }
  }, [game?.id, game?.moves.length, game?.result === 'InProgress']);

  const isLastMove =
    selected && selected === game?.moves[game?.moves.length - 1];
  const propsPatch: Partial<ChessBoardProps> | undefined = selected
    ? isLastMove
      ? {
          // выключить анимацию при переключении истории,
          // но включить как только пользователь начнёт перетаскивать фигуру
          noAnimation: props ? props.selectedFrom === selected.from : true,
        }
      : {
          position: selected.fenAfter,
          selectedFrom: selected.from as SquareName,
          selectedTo: selected.to as SquareName,
          noAnimation: true,
          interactive: 'none',
          onSquarePointerDown: undefined,
          winner: undefined,
        }
    : undefined;

  const playersTimeMs = selected
    ? {
        white: selected.clockAfter?.whiteLeftMs,
        black: selected.clockAfter?.blackLeftMs,
      }
    : undefined;

  return {
    props: {
      moves,
      onNavigate,
      selected,
      result:
        mapServerResult(game?.result, t) ??
        mapClientResult(props?.winner, t) ??
        mapServerDrawOffer(game?.activeDrawRequest, t) ??
        undefined,
    },
    propsPatch,
    playersTimeMs,
    isLastMove,
    reset,
  };
}

function mapServerResult(
  result: components['schemas']['GameResult'] | undefined,
  t: ReturnType<typeof useTranslate>['t'],
): string | null {
  switch (result) {
    case 'InProgress':
    case 'NotStarted':
    case 'Unknown':
    case undefined:
      return null;
    case 'BlackWin':
      return t(TEXTS.blackWin);
    case 'WhiteWin':
      return t(TEXTS.whiteWin);
    case 'Draw':
      return t(TEXTS.draw);
  }
}

function mapClientResult(
  result: ChessBoardProps['winner'],
  t: ReturnType<typeof useTranslate>['t'],
): string | null {
  switch (result) {
    case undefined:
      return null;
    case 'black':
      return t(TEXTS.blackWin);
    case 'white':
      return t(TEXTS.whiteWin);
    case 'draw':
      return t(TEXTS.draw);
  }
}

function mapServerDrawOffer(
  request: components['schemas']['ActiveDrawRequestDto'] | undefined,
  t: ReturnType<typeof useTranslate>['t'],
): string | null {
  if (!request) return null;

  return request.triggeredBySide === 'White'
    ? t(TEXTS.whiteOffer)
    : t(TEXTS.blackOffer);
}
