import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { useAuth } from '@features/auth/hooks/useAuth';
import { useClubRoles } from '@features/auth/hooks/useClubRoles';
import { ClockControlType } from '@features/shared/api/typings';
import {
  useActiveTournamentParticipantsQuery,
  useTournamentCardQuery,
} from '@features/tournament/api/hooks';
import { groupBy, sortBy } from '@libs/array';
import { TournamentParticipantsProps } from '.';
import { TournamentParticipants } from './TournamentParticipants';

type Props = {
  clubId: string;
  tournamentId: string;
};

type Sort =
  | 'Alphabet'
  | 'RatingBlitz'
  | 'RatingRapid'
  | 'RatingClassic'
  | 'RegistrationDate';

const SORT_RANK_MAP: { [k in ClockControlType]: Sort } = {
  Blitz: 'RatingBlitz',
  Rapid: 'RatingRapid',
  Classic: 'RatingClassic',
};

type WidgetRef = { refetch: () => void };

export const TournamentParticipantsWidget = forwardRef<WidgetRef, Props>(
  function TournamentParticipantsWidget({ clubId, tournamentId }, ref) {
    const [tab, setTab] =
      useState<TournamentParticipantsProps['tab']>('position');

    const { isManager } = useClubRoles(clubId);
    const { user } = useAuth();

    const { data: tournament } = useTournamentCardQuery(clubId, tournamentId, {
      staleTime: Infinity,
    });

    const sort: Sort = (() => {
      switch (tab) {
        case 'Alphabet':
        case 'RegistrationDate':
          return tab;
        case 'position':
          return 'RegistrationDate';
        case 'rank':
          return SORT_RANK_MAP[tournament?.clockControl || 'Classic'];
      }
    })();
    const participantsQuery = useActiveTournamentParticipantsQuery(
      clubId,
      tournamentId,
      sort,
      isManager,
    );

    useImperativeHandle(ref, () => ({ refetch: participantsQuery.refetch }), [
      participantsQuery.refetch,
    ]);

    // FIXME: попросить от бэка явную ручку с игроками в нужном порядке
    const participants = useMemo(() => {
      if (tab === 'position' && tournament?.teams && participantsQuery.data) {
        const gs = groupBy(participantsQuery.data, (p) => p.teamId || '');
        Object.entries(gs).forEach(([teamId, ps]) => {
          sortBy(
            ps,
            (p) => (teamId ? p.numberInTeam : p.numberInTournament) ?? Infinity,
          );
        });
        // sic: порядок команд не важен, поэтому просто склеиваем все группы
        return Object.values(gs).flatMap((ps) => ps);
      }
      return participantsQuery.data;
    }, [participantsQuery.data, tab, tournament?.teams]);

    return (
      <TournamentParticipants
        isPending={participantsQuery.isPending}
        participants={participants}
        tab={tab}
        onTabChange={setTab}
        highlightedUserId={user?.userId}
        teams={tournament?.teams}
        isManager={isManager}
        clockControl={tournament?.clockControl}
      />
    );
  },
);
