import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { FileUploadInput } from '@ui/components/FileUploadInput';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';

export function RulesFileInput({
  onUpload,
}: {
  onUpload: (file: FormData) => Promise<void>;
}) {
  const { control } = useFormContext<TournamentEditFormValues>();
  const system = useWatch<TournamentEditFormValues>({
    name: 'system',
    control,
  });
  const isMatch = system === 'OneOnOne' || system === 'Seance';
  return (
    <FormRow>
      <FormLabel hint="не обязательно">
        Положение о {isMatch ? 'матче' : 'турнире'}
      </FormLabel>
      <FormInput>
        <Controller
          name="rulesUrl"
          control={control}
          render={({ field: { value, disabled } }) => (
            <FileUploadInput
              description="PDF, 20 МБ"
              value={value}
              onChange={onUpload}
              disabled={disabled}
            />
          )}
        />
      </FormInput>
    </FormRow>
  );
}
