import { useState } from 'react';
import { SocketInternalEvents } from '@libs/socket';
import { Subscription } from '@libs/subscription';
import { GameSocketEvents, useRoundSocket } from '../api';

type IGameSubscription = {
  subscribe: (
    cb: (e: GameSocketEvents | SocketInternalEvents) => void,
  ) => () => void;
};

export function useSeanceGameSubscription({
  tournamentId,
  roundNumber = 1,
  gameId,
  customSocketUrl,
  enabled = true,
}: {
  tournamentId: string;
  roundNumber?: number;
  gameId: string;
  customSocketUrl?: string;
  enabled?: boolean;
}): IGameSubscription {
  const [socketSubscription] = useState(
    () => new Subscription<GameSocketEvents | SocketInternalEvents>(),
  );

  useRoundSocket({
    tournamentId,
    roundNumber,
    customSocketUrl,
    enabled,
    cb(e, { games }) {
      switch (e.eventType) {
        case 'RoundGamesState':
          {
            const game = games?.[gameId];
            if (game) {
              socketSubscription.publish({
                eventType: 'GameChanged',
                currentState: game,
                gameId,
                timestampMs: e.timestampMs,
              });
            }
          }
          break;
        case 'GameChanged':
        case 'DrawRequest':
        case 'DrawAccept':
        case 'DrawDecline':
        case 'Surrender':
          if (e.gameId === gameId) {
            socketSubscription.publish(e);
          }
          break;
        default:
          socketSubscription.publish(e);
          break;
      }
    },
  });

  return socketSubscription;
}
