import { useCallback } from 'react';
import { useNavigate } from '@tanstack/react-router';

export function useGoToGame(clubId: string, tournamentId: string) {
  const navigate = useNavigate();
  return useCallback(
    (gameId: string) => {
      navigate({
        to: '/club/$clubId/tournament/$tournamentId/game/$gameId',
        params: { clubId, tournamentId, gameId },
      });
    },
    [navigate, clubId, tournamentId],
  );
}
