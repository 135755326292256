export function groupBy<T>(
  arr: T[],
  keyFn: (i: T) => string,
): Record<string, T[]> {
  return arr.reduce(
    (groups, item) => {
      (groups[keyFn(item)] ||= []).push(item);
      return groups;
    },
    {} as Record<string, T[]>,
  );
}

export function sortBy<T>(arr: T[], fieldFn: (i: T) => string | number): void {
  arr.sort((a, b) => {
    const valA = fieldFn(a);
    const valB = fieldFn(b);
    if (valA > valB) return 1;
    if (valA < valB) return -1;
    return 0;
  });
}

export function toRecord<T>(
  arr: T[],
  keyFn: (i: T) => string,
): Record<string, T> {
  return arr.reduce(
    (groups, item) => {
      groups[keyFn(item)] = item;
      return groups;
    },
    {} as Record<string, T>,
  );
}
