import { RU } from './ru';

export const EN: typeof RU = {
  //

  'features/game/ui/GameButtons/Are you sure': 'Are you sure?',
  'features/game/ui/GameButtons/Confirm': 'Confirm',
  'features/game/ui/GameButtons/Cancel match': 'Cancel match',
  'features/game/ui/GameButtons/Draw': 'Draw',
  'features/game/ui/GameButtons/Resign': 'Resign',
  'features/game/ui/GameButtons/Leave (to match)': 'Close game',
  'features/game/ui/GameButtons/Leave (to tournament)': 'Close game',
  'features/game/ui/GameButtons/Leave (default)': 'Close game',
  'features/game/ui/GameButtons/Notation': 'Moves',

  //

  'features/game/pages/GamePage/QuickGame': 'Quick Match',
  'features/game/pages/GamePage/Round No': 'Round',
  'features/game/pages/GamePage/Time Control Blitz': 'Blitz',
  'features/game/pages/GamePage/Time Control Rapid': 'Rapid',
  'features/game/pages/GamePage/Time Control Classic': 'Classical',
  'features/game/pages/GamePage/Error Header': 'Error',
  'features/game/pages/GamePage/Error Text':
    'Something went wrong while processing your request. Please try again later',

  //

  'features/game/ui/MatchCountdown/Start in': 'Game starts in',

  //

  'features/game/ui/NetworkProblems/Header': 'Lost connection with server',
  'features/game/ui/NetworkProblems/Text': 'Reconnecting',

  //

  'features/game/pages/GamePage/DrawOffer/Text': 'Your opponent offered a draw',
  'features/game/pages/GamePage/DrawOffer/Decline': 'Decline',
  'features/game/pages/GamePage/DrawOffer/Accept': 'Accept',

  //

  'features/game/pages/GamePage/DrawOffer/PlayerPanelAlt/Win': 'Win',

  //

  'features/game/hooks/useScoresheet/Draw': 'Draw',
  'features/game/hooks/useScoresheet/White Win': 'White wins',
  'features/game/hooks/useScoresheet/Black Win': 'Black wins',
  'features/game/hooks/useScoresheet/White offer draw': 'White offered a draw',
  'features/game/hooks/useScoresheet/Black offer draw': 'Black offered a draw',

  //

  'features/user/ui/UserSettingsMenu/Auto-promotion ON':
    'Enable auto-queen promotion',
  'features/user/ui/UserSettingsMenu/Auto-promotion OFF':
    'Disable auto-queen promotion',
  'features/user/ui/UserSettingsMenu/Dark theme ON': 'Dark theme',
  'features/user/ui/UserSettingsMenu/Dark theme OFF': 'Lign theme',

  //

  'features/user/ui/User/Log in': 'Log in',
  'features/user/ui/User/Log out': 'Log out',
};
