import {
  components,
  DELETE,
  GET,
  POST,
} from '@features/shared/api/club/clients';
import {
  MemberDto,
  ParticipantDto,
  ParticipantStatus,
  UpdateTournamentRequest,
} from '@features/shared/api/typings';
import { downloadBlobAsFile } from '@libs/file';
import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useErrorNotification } from '@ui/components/ErrorsNotificationProvider';

export function useTournamentCardQuery(
  clubId: string,
  tournamentId: string,
  { enabled = true, staleTime }: { enabled?: boolean; staleTime?: number } = {},
) {
  return useQuery({
    enabled,
    staleTime,
    queryKey: [
      // ВАЖНО: синхронизировать с updateTournamentCardQuery
      '/api/v1/user/clubs/{clubId}/tournaments/{tournamentId}',
      clubId,
      tournamentId,
    ],
    queryFn: async () =>
      (
        await GET('/api/v1/user/clubs/{clubId}/tournaments/{tournamentId}', {
          params: {
            path: {
              clubId,
              tournamentId,
            },
          },
        })
      ).data!,
  });
}

export function updateTournamentCardQuery(
  ql: QueryClient,
  clubId: string,
  tournamentId: string,
  data: components['schemas']['TournamentDto'],
) {
  const key = [
    '/api/v1/user/clubs/{clubId}/tournaments/{tournamentId}',
    clubId,
    tournamentId,
  ];
  const prev = ql.getQueryData(key);
  ql.setQueryData(key, prev ? { ...prev, ...data } : data);
}

export function useActiveTournamentParticipantsQuery(
  clubId: string,
  tournamentId: string,
  sortBy:
    | 'Alphabet'
    | 'RatingBlitz'
    | 'RatingRapid'
    | 'RatingClassic'
    | 'RegistrationDate',
  isManager?: boolean,
) {
  const url = isManager
    ? '/api/v1/manager/clubs/{clubId}/tournaments/{tournamentId}/participants'
    : '/api/v1/user/clubs/{clubId}/tournaments/{tournamentId}/participants';

  return useQuery({
    queryKey: [url, clubId, tournamentId, sortBy],
    queryFn: async () =>
      (
        await GET(url, {
          params: {
            path: {
              clubId,
              tournamentId,
            },
            query: {
              status: 'Participating',
              sortBy,
              limit: 1000,
            },
          },
        })
      ).data?.result as ParticipantDto[],
  });
}

function getTournamentParticipantsKey(
  clubId: string,
  tournamentId: string,
  status: 'Invited' | 'Participating' | 'Exited' | 'Banned',
) {
  return [
    '/api/v1/manager/clubs/{clubId}/tournaments/{tournamentId}/participants',
    clubId,
    tournamentId,
    status,
  ];
}

export function useTournamentParticipantsQuery(
  clubId: string,
  tournamentId: string,
  status: 'Invited' | 'Participating' | 'Exited' | 'Banned',
) {
  return useQuery({
    queryKey: getTournamentParticipantsKey(clubId, tournamentId, status),
    queryFn: async () =>
      (
        await GET(
          '/api/v1/manager/clubs/{clubId}/tournaments/{tournamentId}/participants',
          {
            params: {
              path: {
                clubId,
                tournamentId,
              },
              query: {
                status,
                limit: 1000,
              },
            },
          },
        )
      ).data?.result,
  });
}

export function useClubMembersForTournamentModalQuery(
  clubId: string,
  tournamentId: string,
  options: {
    sortBy: 'Alphabet' | 'RatingBlitz' | 'RatingRapid' | 'RatingClassic';
    status?: 'JoinRequest' | 'Active' | 'Deleted' | 'Invited';
    query?: string;
  },
) {
  const { sortBy, status, query } = options;

  const result = useInfiniteQuery({
    queryKey: [
      '/api/v1/manager/clubs/{clubId}/members/{tournamentId}/participants',
      clubId,
      tournamentId,
      sortBy,
      status,
      query,
    ],
    getNextPageParam: (lastPage: { nextCursor?: number | null }) =>
      lastPage.nextCursor,
    initialPageParam: 0,
    queryFn: async ({ pageParam }) =>
      (
        await GET(
          '/api/v1/manager/clubs/{clubId}/members/{tournamentId}/participants',
          {
            params: {
              path: {
                clubId,
                tournamentId,
              },
              query: {
                query,
                sortBy,
                status,
                limit: 30,
                cursor: pageParam ? Number(pageParam) : 0,
              },
            },
          },
        )
      ).data!,
  });

  return {
    ...result,
    data: result?.data?.pages?.reduce(
      (res: (MemberDto & { participationStatus: ParticipantStatus })[], p) =>
        res.concat(
          p.result as (MemberDto & {
            participationStatus: ParticipantStatus;
          })[],
        ),
      [],
    ),
  };
}

export function useTournamentResultsQuery(
  clubId: string,
  tournamentId: string,
  enabled?: boolean,
) {
  return useQuery({
    enabled: !!enabled,
    queryKey: ['tournamentResults', clubId, tournamentId],
    queryFn: async () =>
      (
        await GET(
          '/api/v1/user/clubs/{clubId}/tournaments/{tournamentId}/results',
          {
            params: {
              path: {
                clubId,
                tournamentId,
              },
            },
          },
        )
      ).data!,
  });
}

export function useTournamentResultsGamesQuery(
  clubId: string,
  tournamentId: string,
  roundNumber: number,
  enabled: boolean = true,
) {
  return useQuery({
    enabled,
    queryKey: ['tournamentResultsGames', clubId, tournamentId, roundNumber],
    queryFn: async () =>
      (
        await GET(
          '/api/v1/user/clubs/{clubId}/tournaments/{tournamentId}/results/rounds/{roundNumber}',
          {
            params: {
              path: {
                clubId,
                tournamentId,
                roundNumber: roundNumber as number,
              },
            },
          },
        )
      ).data!,
  });
}

export function useTournamentResultXlsQuery(
  clubId: string,
  tournamentId: string,
) {
  return useQuery({
    enabled: false,
    queryKey: ['tournamentResultsXls', clubId, tournamentId],
    queryFn: async () => {
      const result = await GET(
        `/api/v1/user/clubs/{clubId}/tournaments/{tournamentId}/results/excel`,
        {
          params: { path: { clubId, tournamentId } },
          parseAs: 'blob',
        },
      );

      if (result.response.ok && result.data) {
        downloadBlobAsFile('results.xlsx', result.data as Blob);
      } else {
        throw new Error(result.error);
      }
      return result;
    },
  });
}

export function useTournamentUploadRulesMutation(
  clubId: string,
  tournamentId: string,
) {
  const { report } = useErrorNotification();
  return useMutation({
    mutationKey: ['tournamentUploadRules', clubId, tournamentId],
    mutationFn: async (file: FormData) => {
      const result = await POST(
        `/api/v1/manager/clubs/{clubId}/tournaments/{tournamentId}/rules`,
        {
          params: { path: { clubId, tournamentId } },
          // TODO fix openapi generation
          body: file as unknown as never,
        },
      );
      if (!result.response.ok) {
        throw result.error;
      }
      return result;
    },
    onError: report,
  });
}

export function useTournamentUploadLogoMutation(
  clubId: string,
  tournamentId: string,
) {
  const { report } = useErrorNotification();
  return useMutation({
    mutationKey: ['tournamentUploadLogo', clubId, tournamentId],
    mutationFn: async (file: FormData) => {
      const result = await POST(
        `/api/v1/manager/clubs/{clubId}/tournaments/{tournamentId}/logo`,
        {
          params: { path: { clubId, tournamentId } },
          // TODO fix openapi generation
          body: file as unknown as never,
        },
      );
      if (!result.response.ok) {
        throw result.error;
      }
      return result;
    },
    onError: report,
  });
}

export function useParticipantActionTournamentMutation(
  action: 'accept' | 'join' | 'leave' | 'reject',
  clubId: string,
  tournamentId: string,
) {
  const { report } = useErrorNotification();
  return useMutation({
    mutationFn: async () => {
      return (
        await POST(
          `/api/v1/user/clubs/{clubId}/tournaments/{tournamentId}/participants/actions/${action}`,
          {
            params: { path: { clubId, tournamentId } },
          },
        )
      ).data!;
    },
    onError: report,
  });
}

export function useCreateTournamentMutation() {
  const { report } = useErrorNotification();
  return useMutation({
    mutationFn: async ({ clubId }: { clubId: string }) => {
      return (
        await POST('/api/v1/manager/clubs/{clubId}/tournaments', {
          params: { path: { clubId } },
        })
      ).data!;
    },
    onError: report,
  });
}

export function useCreateMatchMutation() {
  return useMutation({
    mutationFn: async ({ clubId }: { clubId: string }) => {
      return (
        await POST('/api/v1/manager/clubs/{clubId}/tournaments/one-on-one', {
          params: { path: { clubId } },
        })
      ).data!;
    },
  });
}

export function useUpdateTournamentMutation() {
  const ql = useQueryClient();
  const { report } = useErrorNotification();
  return useMutation({
    mutationFn: async ({
      clubId,
      tournament,
    }: {
      clubId: string;
      tournament: UpdateTournamentRequest & { tournamentId: string };
    }) => {
      const data = (
        await POST(
          '/api/v1/manager/clubs/{clubId}/tournaments/{tournamentId}',
          {
            params: {
              path: {
                clubId,
                tournamentId: tournament.tournamentId,
              },
            },
            body: tournament,
          },
        )
      ).data!;

      updateTournamentCardQuery(ql, clubId, tournament.tournamentId, data);

      return data;
    },
    onError: report,
  });
}

export function useDeleteTournamentMutation() {
  const { report } = useErrorNotification();
  return useMutation({
    mutationFn: async ({
      clubId,
      tournamentId,
    }: {
      clubId: string;
      tournamentId: string;
    }) => {
      await DELETE(
        '/api/v1/manager/clubs/{clubId}/tournaments/{tournamentId}',
        {
          params: {
            path: {
              clubId,
              tournamentId,
            },
          },
        },
      );
      return true;
    },
    onError: report,
  });
}

export function usePublishTournamentMutation() {
  const { report } = useErrorNotification();
  return useMutation({
    mutationFn: async ({
      clubId,
      tournamentId,
    }: {
      clubId: string;
      tournamentId: string;
    }) => {
      return (
        await POST(
          '/api/v1/manager/clubs/{clubId}/tournaments/{tournamentId}/actions/publish',
          {
            params: {
              path: {
                clubId,
                tournamentId,
              },
            },
          },
        )
      ).data!;
    },
    onError: report,
  });
}

export function useTournamentParticipantStatusMutation(
  clubId: string,
  tournamentId: string,
  action: 'add' | 'remove' | 'invite',
) {
  const { report } = useErrorNotification();
  const url =
    action === 'remove'
      ? '/api/v1/manager/clubs/{clubId}/tournaments/{tournamentId}/participants/{userId}/actions/delete'
      : action === 'invite'
        ? '/api/v1/manager/clubs/{clubId}/tournaments/{tournamentId}/participants/{userId}/actions/invite'
        : '/api/v1/manager/clubs/{clubId}/tournaments/{tournamentId}/participants/{userId}/actions/add';

  return useMutation({
    mutationFn: async ({ userId }: { userId: string }) => {
      return (
        await POST(url, {
          params: {
            path: {
              clubId,
              tournamentId,
              userId,
            },
          },
        })
      ).data!;
    },
    onError: report,
  });
}

export function useAddToTournamentMutation(
  clubId: string,
  tournamentId: string,
) {
  return useTournamentParticipantStatusMutation(clubId, tournamentId, 'add');
}

export function useInviteToTournamentMutation(
  clubId: string,
  tournamentId: string,
) {
  return useTournamentParticipantStatusMutation(clubId, tournamentId, 'invite');
}

export function useRemoveFromTournamentMutation(
  clubId: string,
  tournamentId: string,
) {
  return useTournamentParticipantStatusMutation(clubId, tournamentId, 'remove');
}

export function useCreateTournamentTeamMutation(
  clubId: string,
  tournamentId: string,
) {
  const { report } = useErrorNotification();
  return useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      return (
        await POST(
          '/api/v1/manager/clubs/{clubId}/tournaments/{tournamentId}/teams',
          {
            params: {
              path: {
                clubId,
                tournamentId,
              },
            },
            body: {
              name,
            },
          },
        )
      ).data!;
    },
    onError: report,
  });
}

export function useRemoveTournamentTeamMutation(
  clubId: string,
  tournamentId: string,
) {
  const { report } = useErrorNotification();
  return useMutation({
    mutationFn: async ({ teamId }: { teamId: string }) => {
      return (
        await DELETE(
          '/api/v1/manager/clubs/{clubId}/tournaments/{tournamentId}/teams/{teamId}',
          {
            params: {
              path: {
                clubId,
                tournamentId,
                teamId,
              },
            },
          },
        )
      ).data!;
    },
    onError: report,
  });
}

export function useRearrangeParticipantsMutation(
  clubId: string,
  tournamentId: string,
) {
  const ql = useQueryClient();
  const { report } = useErrorNotification();
  return useMutation({
    mutationFn: async ({
      items,
    }: {
      items: components['schemas']['RearrangeParticipantsRequestItem'][];
    }) => {
      const data = (
        await POST(
          '/api/v1/manager/clubs/{clubId}/tournaments/{tournamentId}/participants/rearrange',
          {
            params: { path: { clubId, tournamentId } },
            body: { items },
          },
        )
      ).data!;

      ql.setQueryData(
        getTournamentParticipantsKey(clubId, tournamentId, 'Participating'),
        data,
      );

      return data;
    },
    onError: report,
  });
}
