import { useCallback } from 'react';
import { useClubRoles } from '@features/auth/hooks/useClubRoles';
import { useClubEventsQuery } from '@features/club/api/hooks';
import { ClubEventList } from '@features/club/ui/ClubEventList';
import { useUserSocket } from '@features/shared/api';
import { useNavigate } from '@tanstack/react-router';

export type Props = {
  className?: string;
  clubId: string;
  tab: 'upcoming' | 'finished';
  onTabChange: (tab: 'upcoming' | 'finished') => void;
};

export function ClubEventListWidget({
  className,
  clubId,
  tab,
  onTabChange,
}: Props) {
  const navigate = useNavigate();
  const onTournament = useCallback(
    (tournamentId: string) => {
      navigate({
        to: '/club/$clubId/tournament/$tournamentId',
        params: { clubId, tournamentId },
      });
    },
    [navigate, clubId],
  );

  const { isManager } = useClubRoles(clubId);

  const { data, refetch, isPending } = useClubEventsQuery(
    clubId,
    tab,
    isManager,
  );

  useUserSocket((e) => {
    switch (e.eventType) {
      case 'TournamentPublished':
      case 'TournamentUpdated':
        refetch();
        break;
    }
  });

  // TournamentUpdated
  return (
    <ClubEventList
      className={className}
      tab={tab}
      loading={isPending}
      events={data}
      onTab={onTabChange}
      onTournament={onTournament}
    />
  );
}
