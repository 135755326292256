import { Controller, useFormContext } from 'react-hook-form';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Tabs } from '@ui/components/Tabs';

const TABS = [
  { value: 'OneOnOne', label: 'Матч' },
  { value: 'Seance', label: 'Одновременная игра' },
];

export function MatchTypeInput() {
  const { control } = useFormContext<TournamentEditFormValues>();
  return (
    <FormRow>
      <FormLabel>Матч или сеанс</FormLabel>
      <FormInput>
        <Controller
          name="system"
          control={control}
          render={({ field: { value, onChange, disabled } }) => (
            <Tabs
              tabs={TABS}
              onChange={onChange}
              value={value}
              disabled={disabled}
            />
          )}
        />
      </FormInput>
    </FormRow>
  );
}
